import React from 'react'
import { Button } from 'semantic-ui-react'

import { ONBOARDING } from '@/reducers/tasks/tasks.constants'
import { Pill } from '@/components/pills/Pill'

export const TaskOnboardingIntroduction = ({ inboxType, setStep }) => {
  return (
    <>
      <div className="modal-icon-wrapper">
        <div className="top-icon">{ONBOARDING[inboxType].icon}</div>
      </div>
      <Pill xs success>
        New
      </Pill>
      <header>
        <h2>{ONBOARDING[inboxType].title}</h2>
      </header>
      <p className="muted-text">{ONBOARDING[inboxType].description}</p>
      <Button secondary onClick={() => setStep(2)}>
        Get Started
      </Button>
    </>
  )
}
