import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button, Checkbox } from 'semantic-ui-react'
import { IconChartBar, IconDownload, IconColumns } from '@tabler/icons-react'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { groupByOptions } from '@/utils/constants'

import WidgetContext from './widget.context'
import Select from '../forms/Select'
import { parseToCsv } from '../helpers/parseToCsv'
import { exportCsv } from '../helpers/exportToCsv'

const WidgetControls = ({
  allowMinimization,
  label,
  subtitle,
  subtitlePercent,
  showGroupBy,
  showPercent,
  showViewToggle,
  showCsvDownload,
  customControls,
  alternateUrlCsvClickHandler,
  customDisplayName,
  showIgnoreWeekends,
  displayBackground,
}) => {
  const {
    isMinimized,
    isPercent,
    groupByValue,
    isTableView,
    ignoreWeekends,
    csvData,
    handleMinimizationToggle,
    handlePercentToggle,
    handleChangeGroupBy,
    handleChangeView,
    handleChangeIgnoreWeekends,
  } = useContext(WidgetContext)
  const { filters } = useSelector((state) => state.analytics)

  let actualSubtitle = subtitle
  if (showPercent) {
    actualSubtitle = isPercent ? subtitlePercent : subtitle
  }

  const downloadData = (response) => {
    if (!isEmpty(response.data)) {
      const csvString = parseToCsv(response)

      exportCsv(
        csvString,
        response.filters || filters,
        response.fileName || label,
        customDisplayName
      )
    }
  }

  return (
    <header
      data-testid="widget-controls"
      className={classNames('widget__header', { blank: !displayBackground })}
    >
      <div>
        <div className="widget__title">
          <h2>{label}</h2>
          {allowMinimization && (
            <Button basic size="tiny" compact onClick={handleMinimizationToggle}>
              {isMinimized ? 'Show' : 'Hide'}
            </Button>
          )}
        </div>
        {subtitle && !isMinimized && (
          <p data-testid="subtitle" className="widget-subtitle">
            {actualSubtitle}
          </p>
        )}
      </div>
      {!isMinimized && (
        <div className="widget__controls">
          {showIgnoreWeekends && (
            <div data-testid="ignoreWeekends">
              <Checkbox
                data-testid="ignore-weekends-toggle"
                label="Ignore Weekends"
                name="ignoreWeekends"
                checked={ignoreWeekends}
                onChange={handleChangeIgnoreWeekends}
              />
            </div>
          )}
          {showPercent && (
            <div data-testid="percent">
              <Checkbox
                data-testid="percent-toggle"
                label="Show Percentage"
                name="isPercent"
                checked={isPercent}
                onChange={handlePercentToggle}
              />
            </div>
          )}
          {showGroupBy && (
            <div data-testid="group-by-select">
              <Select
                options={groupByOptions}
                value={groupByValue}
                onChange={handleChangeGroupBy}
                isClearable={false}
              />
            </div>
          )}
          {showCsvDownload && (
            <div data-testid="csv-download">
              <Button
                data-testid="csv-download-button"
                secondary
                onClick={async () => {
                  if (alternateUrlCsvClickHandler) {
                    const data = await alternateUrlCsvClickHandler()
                    downloadData(data)
                  } else {
                    downloadData(csvData)
                  }
                }}
                className="view-icon"
              >
                <IconDownload />
              </Button>
            </div>
          )}
          {showViewToggle && (
            <div data-testid="view-toggle">
              <Button secondary onClick={handleChangeView} className="view-icon">
                {isTableView ? (
                  <span data-testid="chart">
                    <IconChartBar />
                  </span>
                ) : (
                  <span data-testid="table">
                    <IconColumns />
                  </span>
                )}
              </Button>
            </div>
          )}
          {customControls}
        </div>
      )}
    </header>
  )
}

export default WidgetControls
