import React, { cloneElement, useState } from 'react'
import { Icon } from 'semantic-ui-react'

const MultiStepForm = ({ modalClose, steps, navigatable = false }) => {
  const [activeStep, setActiveStep] = useState(0)

  const slidePanel = (nextStep, stepsLength) =>
    setActiveStep(((nextStep % stepsLength) + stepsLength) % stepsLength)

  const nextPanel = () => slidePanel(activeStep + 1, steps.length)
  const prevPanel = () => slidePanel(activeStep - 1, steps.length)

  const currentSlide = cloneElement(steps[activeStep], {
    modalClose,
    nextPanel,
  })

  return (
    <div data-testid="multi-step-form">
      <div className="ui grid">
        <div
          className="left floated one wide column"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {activeStep !== 0 && (
            <Icon
              name="chevron left"
              size="huge"
              onClick={prevPanel}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
        <div className="fourteen wide column">{currentSlide}</div>
        <div
          className="right floated right aligned one wide column"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {activeStep !== steps.length - 1 && navigatable && (
            <Icon
              name="chevron right"
              size="huge"
              onClick={nextPanel}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MultiStepForm
