import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { TransferList } from '@/components/interactions/TransferList'

export const TaskOnboardingAgentSelect = ({ handleSubmitOnboarding }) => {
  const [selectedAgents, setSelectedAgents] = useState([])
  const { data, loading } = useSelector((state) => state.tasks)

  return (
    <>
      <header>
        <h2>Choose Your Agents</h2>
        <p>Choose which agents appear in your inbox. You can update this list anytime.</p>
      </header>
      <TransferList
        label="Agent"
        allOptions={data.agents}
        selectedOptions={selectedAgents}
        loading={loading.agents}
        handleSelect={setSelectedAgents}
      />
      <div className="mt medium-gap flex-end">
        <Button
          data-testid="skip-for-now-button"
          secondary
          onClick={() => handleSubmitOnboarding()}
        >
          Skip For Now
        </Button>
        <Button
          data-testid="complete-onboarding-button"
          primary
          onClick={() => handleSubmitOnboarding(selectedAgents)}
        >
          Finish Setup
        </Button>
      </div>
    </>
  )
}
