import React from 'react'
import classNames from 'classnames'

import './NoData.scss'

const NoData = ({ text, headerText, position, muted, padded, children }) => {
  return (
    <div
      className={classNames('no-data-container', {
        'left-aligned': position === 'left',
        muted,
        padded,
      })}
    >
      <div>
        <h3>{headerText || 'Nothing to display'}</h3>
        {!children && <p>{text || 'No data meets the filter criteria'}</p>}
        {children}
      </div>
    </div>
  )
}

export default NoData
