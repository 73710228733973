import React from 'react'
import classNames from 'classnames'

import { BaltoGPTIcon } from '../icons/BaltoGPTIcon'

import './BaltoGPTButton.scss'

export const BaltoGPTButton = ({ handleToggleChatVisible, requiresAdditionalMargin }) => {
  return (
    <button
      type="button"
      className={classNames('balto-gpt-button', { 'additional-margin': requiresAdditionalMargin })}
      onClick={handleToggleChatVisible}
      data-testid="balto-gpt-button"
    >
      <BaltoGPTIcon />
    </button>
  )
}
