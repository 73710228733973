import { sortBy } from 'lodash'
import { toast } from 'react-toastify'
import queryString from 'query-string'

import { fetchingAPI, apiService } from '@/api'
import { fetchAgentsWithCallStatus } from '@/reducers/commandCenter/commandCenter.actions'
import { getTagsWithCategories } from '@/utils/helpers'
import {
  COPILOT_FILTER_TYPE,
  MY_TEAM_FILTER_TYPE,
} from '@/reducers/savedFilters/savedFilters.constants'

import * as redux from './savedFilters.redux'
import { formatSavedFilters } from './savedFilters.helpers'
import { closeModal } from '../ui/ui.redux'

/*
  This is extracted into a helper function so action creators waiting
  on savedFilters to load can retrieve them from state.
*/
export const fetchSavedFiltersHelper = async (dispatch, filterType) => {
  dispatch(redux.setLoading({ [filterType]: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.web}/api/reporting/saved_filters?filter_type=${filterType}`,
      'GET',
      dispatch
    )
    const filters = sortBy(response.saved_filters, [(filter) => !filter.is_default, 'name'])

    dispatch(redux.setFilterList({ [filterType]: filters }))
  } catch (err) {
    toast.error('Failed to retrieve saved filters')
  } finally {
    dispatch(redux.setLoading({ [filterType]: false }))

    // Leaky abstraction spaghetti, but it works for now
    // Otherwise would have to pass a callback into every action
    if (filterType === MY_TEAM_FILTER_TYPE) {
      dispatch(fetchAgentsWithCallStatus())
    }
  }
}

export const fetchSavedFilters = (filterType) => async (dispatch) => {
  await fetchSavedFiltersHelper(dispatch, filterType)
}

export const createSavedFilter = (filter, filterType) => async (dispatch, getState) => {
  const { currentUser } = getState()
  const { organizationid: currentUserOrgId } = currentUser
  const filterToSave = formatSavedFilters(filter, currentUserOrgId, filterType)

  try {
    await fetchingAPI(
      `${apiService.web}/api/reporting/saved_filters`,
      'POST',
      dispatch,
      JSON.stringify(filterToSave)
    )

    toast.success('Your filter has been saved successfully')
    dispatch(closeModal())
    dispatch(fetchSavedFilters(filterType))
  } catch {
    toast.error('Failed to save filters')
  }
}

export const updateSavedFilter = (uuid, filter, filterType) => async (dispatch, getState) => {
  const { currentUser } = getState()
  const { organizationid: currentUserOrgId } = currentUser
  const filterToSave = formatSavedFilters(filter, currentUserOrgId, filterType)

  try {
    await fetchingAPI(
      `${apiService.web}/api/reporting/saved_filters/${uuid}`,
      'PUT',
      dispatch,
      JSON.stringify(filterToSave)
    )

    toast.success(`Your filter has been saved successfully`)
    dispatch(closeModal())
    dispatch(fetchSavedFilters(filterType))
  } catch (err) {
    toast.error('Failed to update filter')
  }
}

export const deleteSavedFilter = (filterId, filterType) => async (dispatch) => {
  dispatch(redux.setLoading({ [filterType]: true }))

  try {
    await fetchingAPI(
      `${apiService.web}/api/reporting/saved_filters/${filterId}`,
      'DELETE',
      dispatch
    )

    toast.success('Your filter was deleted')
    dispatch(fetchSavedFilters(filterType))
  } catch (err) {
    toast.error('Failed to delete saved filter')
  } finally {
    dispatch(redux.setLoading({ [filterType]: false }))
  }
}

export const fetchTagsForFilters = (organizationId) => async (dispatch) => {
  dispatch(redux.setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)

    dispatch(redux.setData({ tags: tagsOptionsWithCategories }))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(redux.setLoading({ tags: false }))
  }
}

export const fetchAgentsForFilters = (organizationId) => async (dispatch) => {
  dispatch(redux.setLoading({ agents: true }))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )

    users.sort((a, b) => a.last_name.localeCompare(b.last_name))
    const agentOptions = users.map((agent) => ({
      value: agent.id,
      label: `${agent.first_name} ${agent.last_name}`,
    }))

    dispatch(redux.setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(redux.setLoading({ agents: false }))
  }
}

export const fetchPlaybooksForFilters = (organizationId) => async (dispatch) => {
  dispatch(redux.setLoading({ playbooks: true }))

  try {
    const configProperties = queryString.stringify({ requested_properties: 'id,name,cid' })
    const playbooks = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/configs?${configProperties}`,
      'GET',
      dispatch
    )
    const playbookOptions = playbooks
      .map((playbook) => ({ value: playbook.cid, label: playbook.name }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(redux.setData({ playbooks: playbookOptions }))
  } catch (err) {
    toast.error('Failed to fetch playbooks')
  } finally {
    dispatch(redux.setLoading({ playbooks: false }))
  }
}

export const fetchCategoriesForFilters =
  (organizationId, section, playbooks = []) =>
  async (dispatch) => {
    dispatch(redux.setLoading({ [`${section}Categories`]: true }))

    try {
      const filters = queryString.stringify({
        organization_id: organizationId,
        playbooks: playbooks.map((playbook) => playbook.value),
      })
      const categories = await fetchingAPI(
        `${apiService.web}/api/configs/${section}_categories?${filters}`,
        'GET',
        dispatch
      )
      const categoryOptions = categories[`${section}_categories`]
        .sort((a, b) => a.localeCompare(b))
        .map((cat) => ({ value: cat, label: cat }))

      dispatch(redux.setData({ [`${section}Categories`]: categoryOptions }))
    } catch (err) {
      toast.error('Failed to fetch categories')
    } finally {
      dispatch(redux.setLoading({ [`${section}Categories`]: false }))
    }
  }
export const fetchScorecardForFilters =
  (organizationId, scorecardType = null) =>
  async (dispatch) => {
    dispatch(redux.setLoading({ scorecards: true }))
    const params = new URLSearchParams()
    params.append('requested_organization_id', organizationId)
    if (scorecardType) {
      params.append('scorecard_type', scorecardType)
    }

    const url = `${apiService.scorecard}/scoring/scorecards/current?${params.toString()}`

    try {
      const apiScorecardConfigs = await fetchingAPI(url, 'GET', dispatch)
      const sortedScorecardConfigs = apiScorecardConfigs
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((scorecard) => ({
          value: scorecard.id,
          label: scorecard.name,
          sid: scorecard.sid,
        }))

      dispatch(redux.setData({ scorecardConfigs: sortedScorecardConfigs }))
    } catch (err) {
      toast.error(`Failed to fetch scorecards for organization: ${organizationId}`)
    } finally {
      dispatch(redux.setLoading({ scorecards: false }))
    }
  }

export const fetchFilterFormData =
  (organizationId, filterFormOptions, selectedFilter, filterType = null) =>
  async (dispatch) => {
    const playbooks = selectedFilter?.filters?.playbooks || []

    if (filterFormOptions.includes('agents')) {
      dispatch(fetchAgentsForFilters(organizationId))
    }

    if (filterFormOptions.includes('tags')) {
      dispatch(fetchTagsForFilters(organizationId))
    }

    if (filterFormOptions.includes('playbooks')) {
      dispatch(fetchPlaybooksForFilters(organizationId))
    }

    if (filterFormOptions.includes('deckCategories')) {
      dispatch(fetchCategoriesForFilters(organizationId, 'deck', playbooks))
    }

    if (filterFormOptions.includes('postcallCategories')) {
      dispatch(fetchCategoriesForFilters(organizationId, 'postcall', playbooks))
    }
    if (filterFormOptions.includes('scorecards')) {
      if (filterType === COPILOT_FILTER_TYPE) {
        dispatch(fetchScorecardForFilters(organizationId, 'copilot'))
      } else {
        dispatch(fetchScorecardForFilters(organizationId))
      }
    }
  }
