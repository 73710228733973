export const UNCATEGORIZED_TAGS = 'Uncategorized Tags'
export const SKIP_MODIFIER = 15
// This acts as a buffer so the file is refreshed before it actually expires
// Hopefully avoiding interruptions for the user
export const AUDIO_EXPIRATION_BUFFER = 60

export const winOptions = [
  { value: true, label: 'Only wins' },
  { value: false, label: 'Only non-wins' },
]

export const scoredOptions = [
  { value: 'scored', label: 'Scored' },
  { value: 'partially_scored', label: 'Partially Scored' },
  { value: 'unscored', label: 'Not Scored' },
  { value: 'any', label: 'All Statuses' },
]

export const groupByOptions = [
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
]

export const rankOnOptions = [
  { label: 'Checklist Usage', value: 'checklist_usage' },
  { label: 'Dynamic Prompt Usage', value: 'decklist_usage' },
  { label: 'Win Count', value: 'wins' },
  { label: 'Win Rate', value: 'win_rate' },
  { label: 'QA Score', value: 'qa' },
]

export const timePeriodOptions = [
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'This Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_14_days', label: 'Last 14 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
]

export const minCallDurationOptions = [
  { value: '0', label: '0 minutes or more' },
  { value: '1', label: '1 minute or more' },
  { value: '2', label: '2 minutes or more' },
  { value: '5', label: '5 minutes or more' },
  { value: '10', label: '10 minutes or more' },
  { value: '15', label: '15 minutes or more' },
]

export const RTCDisplayGroupingOptions = [
  { value: 'day', label: 'Day', accessor: 'day' },
  { value: 'manager', label: 'Manager', accessor: 'manager' },
  { value: 'agent', label: 'Agent', accessor: 'agent' },
  { value: 'tag', label: 'Tag', accessor: 'tag' },
  { value: 'call_id', label: 'Call ID', accessor: 'call_id' },
  { value: 'playbook', label: 'Playbook', accessor: 'playbook' },
  { value: 'alert_type', label: 'Alert Type', accessor: 'alert_type' },
  { value: 'alert_name', label: 'Alert Name', accessor: 'alert_name' },
  { value: 'trigger', label: 'Trigger', accessor: 'trigger' },
]

export const notApplicableChartColor = '#DEDFE1'
export const chartColors = [
  '#58A6FF',
  '#FFBA67',
  '#2FB475',
  '#FAACAC',
  '#367ACD',
  '#B06CFF',
  '#03A29C',
  '#697CFF',
  '#F56E6E',
  '#2CE1CE',
  '#FFDB55',
  '#FF6BB1',
]

export const thresholdColors = ['#F78D8D', '#FCCCCC', '#FFDBB0', '#BCEBD2']

export const auditColumns = [
  {
    accessor: 'created_at',
    label: 'Created At',
  },
  {
    accessor: 'event_type',
    label: 'Event Type',
    collapsing: true,
    isSearchable: true,
  },
  {
    accessor: 'what_changed',
    label: 'What Changed',
    collapsing: true,
    isSearchable: true,
  },
  {
    accessor: 'created_by',
    label: 'Created By',
    isSearchable: true,
  },
]

export const fullstoryScript = `
window['_fs_host'] = 'fullstory.com'
window['_fs_script'] = 'edge.fullstory.com/s/fs.js'
window['_fs_org'] = 'o-20Y8Z5-na1'
window['_fs_namespace'] = 'FS'
!(function (m, n, e, t, l, o, g, y) {
  var s,
    f,
    a = (function (h) {
      return (
        !(h in m) ||
        (m.console &&
          m.console.log &&
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),
        !1)
      )
    })(e)
  function p(b) {
    var h,
      d = []
    function j() {
      h &&
        (d.forEach(function (b) {
          var d
          try {
            d = b[h[0]] && b[h[0]](h[1])
          } catch (h) {
            return void (b[3] && b[3](h))
          }
          d && d.then ? d.then(b[2], b[3]) : b[2] && b[2](d)
        }),
        (d.length = 0))
    }
    function r(b) {
      return function (d) {
        h || ((h = [b, d]), j())
      }
    }
    return (
      b(r(0), r(1)),
      {
        then: function (b, h) {
          return p(function (r, i) {
            d.push([b, h, r, i]), j()
          })
        },
      }
    )
  }
  a &&
    ((g = m[e] =
      (function () {
        var b = function (b, d, j, r) {
          function i(i, c) {
            h(b, d, j, i, c, r)
          }
          r = r || 2
          var c,
            u = /Async$/
          return u.test(b)
            ? ((b = b.replace(u, '')), 'function' == typeof Promise ? new Promise(i) : p(i))
            : h(b, d, j, c, c, r)
        }
        function h(h, d, j, r, i, c) {
          return b._api ? b._api(h, d, j, r, i, c) : (b.q && b.q.push([h, d, j, r, i, c]), null)
        }
        return (b.q = []), b
      })()),
    (y = function (b) {
      function h(h) {
        'function' == typeof h[4] && h[4](new Error(b))
      }
      var d = g.q
      if (d) {
        for (var j = 0; j < d.length; j++) h(d[j])
        ;(d.length = 0), (d.push = h)
      }
    }),
    (function () {
      ;((o = n.createElement(t)).async = !0),
        (o.crossOrigin = 'anonymous'),
        (o.src = 'https://' + l),
        (o.onerror = function () {
          y('Error loading ' + l)
        })
      var b = n.getElementsByTagName(t)[0]
      b && b.parentNode ? b.parentNode.insertBefore(o, b) : n.head.appendChild(o)
    })(),
    (function () {
      function b() {}
      function h(b, h, d) {
        g(b, h, d, 1)
      }
      function d(b, d, j) {
        h('setProperties', { type: b, properties: d }, j)
      }
      function j(b, h) {
        d('user', b, h)
      }
      function r(b, h, d) {
        j(
          {
            uid: b,
          },
          d
        ),
          h && j(h, d)
      }
      ;(g.identify = r),
        (g.setUserVars = j),
        (g.identifyAccount = b),
        (g.clearUserCookie = b),
        (g.setVars = d),
        (g.event = function (b, d, j) {
          h(
            'trackEvent',
            {
              name: b,
              properties: d,
            },
            j
          )
        }),
        (g.anonymize = function () {
          r(!1)
        }),
        (g.shutdown = function () {
          h('shutdown')
        }),
        (g.restart = function () {
          h('restart')
        }),
        (g.log = function (b, d) {
          h('log', { level: b, msg: d })
        }),
        (g.consent = function (b) {
          h('setIdentity', { consent: !arguments.length || b })
        })
    })(),
    (s = 'fetch'),
    (f = 'XMLHttpRequest'),
    (g._w = {}),
    (g._w[f] = m[f]),
    (g._w[s] = m[s]),
    m[s] &&
      (m[s] = function () {
        return g._w[s].apply(this, arguments)
      }),
    (g._v = '2.0.0'))
})(window, document, window._fs_namespace, 'script', window._fs_script)
`
