import { massPlaybookUpdateProgressStatus } from '@/views/Playbooks/playbook.helpers'

// Action Types
export const SET_IS_MASS_PLAYBOOK_UPDATE_ENABLED = 'playbooks/setIsMassPlaybookUpdateEnabled'
export const SET_MASS_PLAYBOOK_UPDATE_PROGRESS = 'playbooks/setMassPlaybookUpdateProgress'
export const SET_MASS_PLAYBOOK_UPDATE_MATCHES = 'playbooks/setMassPlaybookUpdateMatches'
export const SET_MASS_PLAYBOOK_UPDATE_FETCHED_ENTITY =
  'playbooks/setMassPlaybookUpdateFetchedEntity'
export const SET_MASS_PLAYBOOK_FETCH_FAILED = 'playbooks/setMassPlaybookFetchFailed'
export const SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION =
  'playbooks/setMassPlaybookUpdateChecklistSelection'
export const SET_MASS_PLAYBOOK_UPDATE_NEW_PLAYBOOK_VERSION =
  'playbooks/setMassPlaybookUpdateNewPlaybookVersion'
export const MASS_PLAYBOOK_UPDATE_RESET = 'playbooks/massPlaybookUpdateReset'
export const SET_PLAYBOOKS_LOADING = 'playbooks/setPlaybooksLoading'
export const SET_PLAYBOOKS = 'playbooks/setPlaybooks'

// Action Creators
export const setLoading = (payload) => ({ type: SET_PLAYBOOKS_LOADING, payload })
export const setIsMassPlaybookUpdateOnOff = (payload) => ({
  type: SET_IS_MASS_PLAYBOOK_UPDATE_ENABLED,
  payload,
})
export const setMassPlaybookUpdateProgress = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_PROGRESS,
  payload,
})
export const setMassPlaybookUpdateMatches = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_MATCHES,
  payload,
})
export const setMassPlaybookUpdateFetchedEntity = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_FETCHED_ENTITY,
  payload,
})
export const setMassPlaybookFetchFailed = () => ({ type: SET_MASS_PLAYBOOK_FETCH_FAILED })
export const setMassPlaybookUpdateChecklistSelection = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION,
  payload,
})
export const setMassPlaybookUpdateNewPlaybookVersion = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_NEW_PLAYBOOK_VERSION,
  payload,
})
export const massPlaybookUpdateReset = () => ({ type: MASS_PLAYBOOK_UPDATE_RESET })
export const setPlaybooks = (payload) => ({ type: SET_PLAYBOOKS, payload })

// State
export const initialState = {
  loading: false,
  massPlaybookUpdate: {
    isEnabled: false,
    fetchedEntity: undefined,
    fetchFailed: false,
    matches: [],
    progress: massPlaybookUpdateProgressStatus.INITIAL,
    selection: [],
    newPlaybookVersion: undefined,
  },
  playbooks: [],
}

// Reducer
const playbooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYBOOKS_LOADING:
      return { ...state, loading: action.payload }
    case SET_IS_MASS_PLAYBOOK_UPDATE_ENABLED:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, isEnabled: action.payload },
      }
    case SET_MASS_PLAYBOOK_UPDATE_PROGRESS:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, progress: action.payload },
      }
    case SET_MASS_PLAYBOOK_UPDATE_MATCHES:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, matches: action.payload },
      }
    case SET_MASS_PLAYBOOK_UPDATE_FETCHED_ENTITY:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, fetchedEntity: action.payload },
      }
    case SET_MASS_PLAYBOOK_FETCH_FAILED:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, fetchFailed: true },
      }
    case SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, selection: action.payload },
      }
    case SET_MASS_PLAYBOOK_UPDATE_NEW_PLAYBOOK_VERSION:
      return {
        ...state,
        massPlaybookUpdate: { ...state.massPlaybookUpdate, newPlaybookVersion: action.payload },
      }
    case MASS_PLAYBOOK_UPDATE_RESET:
      return {
        ...state,
        massPlaybookUpdate: initialState.massPlaybookUpdate,
        playbooks: [],
      }
    case SET_PLAYBOOKS:
      return { ...state, playbooks: action.payload }
    default:
      return state
  }
}

export default playbooksReducer
