import React, { useState } from 'react'

import { FilterPill } from '../FilterPill'
import { FilterTypePopup } from './FilterTypePopup'
import { FilterDropdownOptions } from './FilterDropdownOptions'

export const FilterTypeDropdown = ({
  accessor,
  label,
  options,
  value,
  loading,
  updateSearch,
  showAllOnEmpty = false,
  showAllOnEmptyLabel = null,
  popupProps = {},
  showLabel = true,
  isClearable = false,
  handleRemoveFilter,
}) => {
  const [open, setOpen] = useState(false)
  const optionSets = [{ accessor, options, selectedValue: value }]

  return (
    <FilterTypePopup
      open={open}
      setOpen={setOpen}
      position="bottom left"
      popupProps={{ className: 'filter-pill__popup--medium', ...popupProps }}
      trigger={
        <FilterPill
          showAllOnEmpty={showAllOnEmpty}
          showAllOnEmptyLabel={showAllOnEmptyLabel}
          clickable
          isClearable={isClearable}
          loading={loading}
          label={label}
          value={options.find((option) => option.value === value)?.label}
          showLabel={showLabel}
          handleRemoveFilter={(event) => {
            event.stopPropagation()

            handleRemoveFilter()
            setOpen(false)
          }}
        />
      }
    >
      <FilterDropdownOptions
        optionSets={optionSets}
        handleChange={(accessor, value) => {
          updateSearch(accessor, value)
          setOpen(false)
        }}
        isClearable={isClearable}
      />
    </FilterTypePopup>
  )
}
