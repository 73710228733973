import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty, startCase } from 'lodash'

import {
  COMPLIANCE_TASK_TYPE,
  COACHING_INBOX,
  COMPLIANCE_INBOX,
  READY_FOR_REVIEW,
  COMPLETED,
  IGNORED,
} from '@/reducers/tasks/tasks.constants'
import { Drawer } from '@/components/drawer/Drawer'
import { CopyButton } from '@/components/buttons/CopyButton'

import { TaskDrawer } from '../drawers/TaskDrawer'

export const Task = ({ inboxType, handleUpdateTask, handleCloseTaskDrawer }) => {
  const location = useLocation()
  const { task } = useSelector((state) => state.tasks)
  const taskUrl = `${window.origin}${location.pathname}${location.search}`
  const isArchived = task?.status !== READY_FOR_REVIEW
  const dataTestId = `task-card-${task?.id}`

  const getTaskTitle = (taskType) => {
    if (!taskType) return ''
    if (taskType.startsWith('qa')) {
      return 'QA Task'
    }
    if (taskType === COMPLIANCE_TASK_TYPE) {
      return 'Exception'
    }
    return `${startCase(taskType)} Task`
  }

  return (
    <Drawer
      id={task?.id || 'empty-task'}
      wide
      title={
        <div className="flex-align-center medium-gap" data-testid={`${dataTestId}-drawer-title`}>
          {getTaskTitle(task?.type)}
          <CopyButton content={taskUrl} name="Link" data-testid={`${dataTestId}-copy-link`} />
        </div>
      }
    >
      {isEmpty(task) ? (
        <div>No task available.</div>
      ) : (
        <TaskDrawer
          handleCloseTaskDrawer={handleCloseTaskDrawer}
          commentsEnabled={[COACHING_INBOX, COMPLIANCE_INBOX].includes(inboxType)}
          primaryActionButtonText={
            isArchived
              ? 'Reopen'
              : `Mark as Reviewed ${inboxType === COACHING_INBOX ? 'and Send to Agent' : ''}`
          }
          handlePrimaryAction={(taskId, handleClose) => {
            if (isArchived) {
              handleUpdateTask(taskId, READY_FOR_REVIEW, handleClose)
            } else {
              handleUpdateTask(taskId, COMPLETED, handleClose)
            }
          }}
          secondaryActionButtonText={isArchived ? null : 'Mark as Dismissed'}
          handleSecondaryAction={
            isArchived
              ? null
              : (taskId, handleClose) => {
                  handleUpdateTask(taskId, IGNORED, handleClose)
                }
          }
        />
      )}
    </Drawer>
  )
}
