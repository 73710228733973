import React from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { IconArrowBackUp, IconArrowForwardUp } from '@tabler/icons-react'
import classNames from 'classnames'

import './SkipButton.scss'

export const SkipButton = ({ modifier, isForwardSkip, handleSkip, isDisabled }) => {
  return (
    <Popup
      inverted
      disabled={isDisabled}
      content={
        isForwardSkip ? `Skip Forward ${modifier} Seconds` : `Skip Backwards ${modifier} Seconds`
      }
      trigger={
        <div>
          <Button
            basic
            icon
            type="button"
            aria-label={
              isForwardSkip
                ? `Skip Forward ${modifier} Seconds`
                : `Skip Backwards ${modifier} Seconds`
            }
            className={classNames('skip-button', { disabled: isDisabled })}
            disabled={isDisabled}
            onClick={handleSkip}
          >
            <div className={classNames('skip-arrow', { 'forward-skip-arrow': isForwardSkip })}>
              {isForwardSkip ? <IconArrowForwardUp /> : <IconArrowBackUp />}
            </div>
            <div className={classNames('skip-seconds-text')}>{`${modifier}`}</div>
          </Button>
        </div>
      }
    />
  )
}
