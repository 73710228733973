import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { IconCopyCheck, IconCopy } from '@tabler/icons-react'
import classNames from 'classnames'

import { ConditionalTooltip } from '../ConditionalTooltip'

import './CopyButton.scss'

export const CopyButton = ({
  content,
  name = '',
  showLabel = true,
  showPopup = false,
  popupContent = null,
  tooltipProps = {},
  iconOnly,
  ...props
}) => {
  const [textCopied, setTextCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(content)
    setTextCopied(true)
    setTimeout(() => setTextCopied(false), 1000)
  }

  return (
    <ConditionalTooltip
      condition={showPopup}
      content={popupContent || `Copy "${content}" to Clipboard`}
      tooltipProps={{
        ...tooltipProps,
        offset: iconOnly ? [-10, 0] : [0, 0],
      }}
      triggerProps={{ className: 'inline-flex' }}
    >
      <Button
        data-testid="copy-button"
        secondary
        icon
        compact
        className={classNames('svg-button', { 'copy-button__icon-only': iconOnly })}
        disabled={textCopied}
        onClick={handleCopy}
        {...props}
      >
        {textCopied ? <IconCopyCheck /> : <IconCopy />}
        {showLabel && !iconOnly && (textCopied ? 'Copied!' : `Copy ${name}`)}
      </Button>
    </ConditionalTooltip>
  )
}
