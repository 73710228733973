import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup, Button, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { IconTrash } from '@tabler/icons-react'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import {
  deletePlaybook,
  checkPlaybookForUsers,
  reassignUserPlaybooks,
} from '@/reducers/playbooks/playbooks.actions'
import { openModal, closeModal } from '@/reducers/ui/ui.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'

export const PlaybookDeleteForm = ({ playbookCid, playbookOptions, showButton = true }) => {
  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [usersPreventingDelete, setUsersPreventingDelete] = useState([])
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const modalIsOpen = currentlyOpenModalId === `playbooks/${playbookCid}/deletePlaybook`

  const handleUpdateUserPlaybooks = async (values, { setSubmitting }) => {
    if (!values.playbookCid || isEmpty(usersPreventingDelete)) {
      return
    }

    dispatch(
      reassignUserPlaybooks(
        usersPreventingDelete.map((user) => user.user_id),
        values.playbookCid,
        setSubmitting,
        setUsersPreventingDelete
      )
    )
  }

  const handleDeletePlaybook = () => {
    dispatch(deletePlaybook(playbookCid))
  }

  const handleOpenModal = () => {
    dispatch(openModal(`playbooks/${playbookCid}/deletePlaybook`))
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const getModalContent = () => {
    if (loading || !fetched) {
      return <Loader active>Checking for users...</Loader>
    }

    if (!isEmpty(usersPreventingDelete)) {
      return (
        <div>
          <p>Cannot delete a playbook that is currently assigned to a user.</p>
          <p>Please change the playbooks these users are assigned to and try again.</p>
          <div style={{ marginBottom: '1rem' }}>
            <p className="label">Users</p>
            {usersPreventingDelete.map((user) => (
              <span key={user.user_id} style={{ display: 'block' }}>
                {user.name}
              </span>
            ))}
          </div>
          <AbstractForm
            schema={[
              {
                label: 'Playbook',
                name: 'playbookCid',
                type: 'select',
                options: playbookOptions,
                required: true,
              },
            ]}
            onClose={handleCloseModal}
            onSubmit={handleUpdateUserPlaybooks}
            buttonLabel="Apply Changes"
            isModal
          />
        </div>
      )
    }

    return (
      <>
        <p>
          Are you sure you want to delete this playbook? You will lose all versions. This action is
          not reversible.
        </p>
        <div className="modal-footer">
          <Button type="button" secondary onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            data-testid="delete-playbook-button"
            type="button"
            color="red"
            onClick={handleDeletePlaybook}
          >
            Delete
          </Button>
        </div>
      </>
    )
  }

  useEffect(() => {
    if (modalIsOpen) {
      dispatch(checkPlaybookForUsers(playbookCid, setUsersPreventingDelete, setLoading, setFetched))
    }
  }, [modalIsOpen])

  return (
    <>
      {showButton && (
        <Popup
          inverted
          content="Delete Playbook"
          position="top right"
          trigger={
            <Button
              basic
              compact
              icon
              className="svg-button"
              onClick={handleOpenModal}
              data-testid="delete-playbook"
            >
              <IconTrash />
            </Button>
          }
        />
      )}

      {modalIsOpen && (
        <BasicModal
          data-testid="delete-playbook-modal"
          title="Delete Playbook"
          onClose={handleCloseModal}
          show={modalIsOpen}
          size="small"
        >
          {getModalContent()}
        </BasicModal>
      )}
    </>
  )
}
