import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Checkbox, Segment, Input } from 'semantic-ui-react'
import { apiService } from '@/api'
import store from '@/store'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { getOrganizationOptions } from '@/utils/helpers'
import { useSelector } from 'react-redux'

const ClearTranscriptsAndAudioPage = () => {
  const [audio, setAudio] = useState(false)
  const [audioTranscript, setAudioTranscript] = useState(false)
  const [audioTranscriptSummary, setAudioTranscriptSummary] = useState(false)
  const [orgId, setOrgId] = useState(1)
  const [callIds, setCallIds] = useState('')
  const { organizations } = useSelector((state) => state)
  const state = store.getState()

  const handleSubmit = async () => {
    const requestData = {
      orgId,
      audio,
      audioTranscript,
      audioTranscriptSummary,
      callIds,
    }

    try {
      const { username, organizationid, token } = state.currentUser
      const response = await fetch(
        `${apiService.web}/api/organizations/clear_transcripts_and_audio`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            username,
            organizationid,
            token,
          },
          body: JSON.stringify(requestData),
        }
      )
      if (!response.ok) {
        const result = await response.json()
        toast.error(result.error || 'An error occurred')
      } else {
        toast.success('Successfully deleted data')
        setAudio(false)
        setAudioTranscript(false)
        setAudioTranscriptSummary(false)
        setCallIds('')
      }
    } catch (error) {
      console.error('Error submitting the form:', error)
    }
  }

  const handleOrgSelect = (option, action) => {
    if (action.action === 'clear') {
      setOrgId(null)
    } else {
      setOrgId(option.value)
    }
  }

  return (
    <>
      <header className="page-header" data-testid="integrations-page">
        <h1>Clear Transcripts and Audio</h1>
      </header>

      <Segment className="not-padded">
        <div className="ui segment">
          <OrganizationFilter
            options={getOrganizationOptions(organizations)}
            value={orgId}
            onChange={handleOrgSelect}
          />
        </div>
        <div className="ui segment">
          <h3>Audio</h3>
          <Checkbox
            data-testid="audio"
            toggle
            checked={audio}
            onChange={() => {
              setAudioTranscript(false)
              setAudioTranscriptSummary(false)
              setAudio(!audio)
            }}
          />
        </div>
        <div className="ui segment">
          <h3>Audio + Transcript</h3>
          <Checkbox
            data-testid="audio-transcript"
            toggle
            checked={audioTranscript}
            onChange={() => {
              setAudioTranscriptSummary(false)
              setAudio(false)
              setAudioTranscript(!audioTranscript)
            }}
          />
        </div>
        <div className="ui segment">
          <h3>Audio + Transcript + Summary</h3>
          <Checkbox
            data-testid="audio-transcript-summary"
            toggle
            checked={audioTranscriptSummary}
            onChange={() => {
              setAudio(false)
              setAudioTranscript(false)
              setAudioTranscriptSummary(!audioTranscriptSummary)
            }}
          />
        </div>
        <div className="ui segment">
          <h3>Comma separated call ids</h3>
          <Input
            type="text"
            value={callIds}
            onChange={(e) => setCallIds(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>
        <div className="ui segment">
          <Button onClick={handleSubmit} className="w-full">
            Submit
          </Button>
        </div>
      </Segment>
    </>
  )
}

export default ClearTranscriptsAndAudioPage
