export const CONVERSATION = 'conversation'
export const SILENCE = 'silence'

export const POSTPROCESSED_TRANSCRIPT = 'postprocessed-transcript'
export const REALTIME_TRANSCRIPT = 'realtime-transcript'
export const HYPOTHESIS_TRANSCRIPT = 'hypothesis-transcript'

export const allTranscriptOptions = [
  { value: POSTPROCESSED_TRANSCRIPT, text: 'Post-Processed Transcript' },
  { value: REALTIME_TRANSCRIPT, text: 'Real-Time Transcript' },
  { value: HYPOTHESIS_TRANSCRIPT, text: 'Hypothesis Transcript' },
]
export const transcriptDisplayValues = {
  [POSTPROCESSED_TRANSCRIPT]: { showPostcallTranscript: true, showHypothesis: false },
  [REALTIME_TRANSCRIPT]: { showPostcallTranscript: false, showHypothesis: false },
  [HYPOTHESIS_TRANSCRIPT]: { showPostcallTranscript: false, showHypothesis: true },
}
