import React, { createContext, useRef, useContext, useState } from 'react'
import { isEmpty } from 'lodash'

import {
  allTranscriptOptions,
  transcriptDisplayValues,
  REALTIME_TRANSCRIPT,
  POSTPROCESSED_TRANSCRIPT,
} from '@/components/transcript/transcript.constants'

const TranscriptContext = createContext()

export const TranscriptProvider = ({ transcript = [], postcallTranscript = [], children }) => {
  const hasPostcallTranscript = !isEmpty(postcallTranscript)
  const transcriptBlockRef = useRef(null)
  const transcriptScrollerRef = useRef(null)
  const closestTranscriptIndexRef = useRef(0)

  const [isTranscriptVisible, setIsTranscriptVisible] = useState(true)
  const [allowAutoScroll, setAllowAutoScroll] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [transcriptType, setTranscriptType] = useState(
    hasPostcallTranscript ? POSTPROCESSED_TRANSCRIPT : REALTIME_TRANSCRIPT
  )

  // Determine the  default transcript and transcript options to show a user
  const transcriptSelectorOptions = hasPostcallTranscript
    ? allTranscriptOptions
    : allTranscriptOptions.slice(1)
  const transcriptToDisplay = transcriptDisplayValues[transcriptType].showPostcallTranscript
    ? postcallTranscript
    : transcript
  const showTranscriptHypothesis = transcriptDisplayValues[transcriptType].showHypothesis
  const transcriptBlocksFirstTimes = transcriptToDisplay.map(
    ({ timestamp: silenceTimeStamp, data: [{ timestamp }] = [{ timestamp: 0 }] }) =>
      silenceTimeStamp || timestamp
  )

  const handleToggleTranscriptVisibility = () => {
    setIsTranscriptVisible(!isTranscriptVisible)
  }

  const handleAllowAutoScrollChange = () => {
    setAllowAutoScroll(!allowAutoScroll)
  }

  const handleTranscriptSelectionChange = (event, option) => {
    setTranscriptType(option.value)
  }

  const handleSearchTranscript = (event) => {
    setSearchQuery(event.target.value)
  }

  return (
    <TranscriptContext.Provider
      value={{
        transcript,
        postcallTranscript,
        transcriptToDisplay,
        transcriptSelectorOptions,
        transcriptBlockRef,
        transcriptScrollerRef,
        showTranscriptHypothesis,
        closestTranscriptIndexRef,
        transcriptBlocksFirstTimes,
        transcriptType,
        searchQuery,
        isTranscriptVisible,
        allowAutoScroll,
        handleAllowAutoScrollChange,
        handleTranscriptSelectionChange,
        handleToggleTranscriptVisibility,
        handleSearchTranscript,
      }}
    >
      {children}
    </TranscriptContext.Provider>
  )
}

export const useTranscript = () => {
  return useContext(TranscriptContext)
}
