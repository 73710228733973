import React, { useState } from 'react'
import { IconUserCircle } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { OrgHierarchySelector } from '@/views/OrgHierarchy/OrgHierarchySelector'
import { TableActionsDropdown } from '@/components/tables/TableActionsDropdown'

export const SidebarFooter = ({
  currentUser,
  userOrgHierarchy,
  handleLogout,
  sidebarCollapsed,
}) => {
  const [showHierarchyModal, setShowHierarchyModal] = useState(false)
  const userFullName = `${currentUser.first_name} ${currentUser.last_name}`
  const isHierarchyManager = currentUser.hierarchy_manager

  const handleSwitchOrganization = () => {
    setShowHierarchyModal(true)
  }

  const actions = [{ label: 'Sign Out', onClick: handleLogout, testId: 'sign-out-button' }]

  if (!isEmpty(userOrgHierarchy) && isHierarchyManager) {
    actions.unshift({
      label: 'Switch Organization',
      onClick: handleSwitchOrganization,
      testId: 'org-hierarchy-button',
    })
  }

  return (
    <>
      <footer className="balto-sidebar__footer">
        {!sidebarCollapsed && (
          <div className="flex-align-center medium-gap">
            <IconUserCircle />
            <div className="text-overflow">{userFullName}</div>
          </div>
        )}
        <div>
          <TableActionsDropdown
            actions={actions}
            buttonProps={{ basic: false, secondary: true, compact: true, icon: true }}
          />
        </div>
      </footer>
      <BasicModal
        title="Switch Organization"
        onClose={() => setShowHierarchyModal(false)}
        show={showHierarchyModal}
        size="tiny"
      >
        <OrgHierarchySelector
          currentUserOrgId={currentUser.organizationid}
          userOrgHierarchy={userOrgHierarchy}
        />
      </BasicModal>
    </>
  )
}
