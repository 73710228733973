import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import format from 'date-fns/format'

import { SpinnerLoader } from '@/components/loader/SpinnerLoader'
import { Form, Segment, Button } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import { isEmpty, kebabCase, upperFirst, lowerCase } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { v4 as uuid } from 'uuid'
import { IconBoltFilled } from '@tabler/icons-react'

import { Drawer } from '@/components/drawer/Drawer'
import { fetchDynamicInsights } from '@/reducers/insights/insightEvents.actions'
import {
  formatCallFilters,
  setSavedFilterById,
  clearSavedFilter,
  clearAllFilters,
} from '@/reducers/callSearch/callSearch.actions'
import { setDateRangeFilters } from '@/reducers/callSearch/callSearch.redux'
import { formatPercent } from '@/utils/helpers'
import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { TextField } from '@/components/forms/formik'
import { SavedFilters } from '@/components/savedFilters/SavedFilters'
import { CALL_EXPLORER_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { fetchSavedFilters } from '@/reducers/savedFilters/savedFilters.actions'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { CallSearchFilters } from '../Calls/CallSearchFilters'
import { Evidence } from './components/Evidence'
import { SuggestedQuestions } from '../../components/SuggestedQuestions'
import { dynamicInsightsQuestions } from './constants'

import './DynamicInsights.scss'

export const DynamicInsightsPage = ({ flags }) => {
  const dispatch = useDispatch()
  const { categories, type, stage, fraction_irrelevant, err } = useSelector(
    (state) => state.dynamicInsightEvents
  )
  const {
    filters,
    data: { selectedSavedFilterId },
  } = useSelector((state) => state.callSearch)
  const {
    organizationid: currentUserOrg,
    own_organization_id,
    username,
    token,
    hierarchy_manager,
  } = useSelector((state) => state.currentUser)
  const isChildOrgSelected = hierarchy_manager && currentUserOrg !== own_organization_id
  const [currentQuestion, setCurrentQuestion] = useState('')

  const handleSubmit = ({ question }) => {
    const insightFilters = formatCallFilters(filters)
    insightFilters.question = question

    dispatch(fetchDynamicInsights(insightFilters, username, token, currentUserOrg))
    setCurrentQuestion(question)
  }

  const formatSentenceCase = (sentence) => {
    return upperFirst(lowerCase(sentence))
  }

  const handleSelectSavedFilter = (option) => {
    if (!option) {
      dispatch(clearSavedFilter(false))
    } else {
      const savedFilterId = option.value
      dispatch(setSavedFilterById(savedFilterId, false))
    }
  }

  useEffect(() => {
    const sixtyDaysAgo = moment().subtract(59, 'd').format()
    const today = format(new Date(), "yyyy-MM-dd'T00:00:00'xxx")

    dispatch(clearAllFilters())
    dispatch(setDateRangeFilters({ startDate: sixtyDaysAgo, endDate: today }))
    dispatch(fetchSavedFilters(CALL_EXPLORER_FILTER_TYPE))
  }, [])

  return (
    <div className="insights-container">
      <header className="page-header" data-testid="insights-page">
        <h1 className="inbox-title">
          <div className="icon-container__square" style={{ background: 'var(--gradient-brand' }}>
            <IconBoltFilled />
          </div>
          Balto Insights
        </h1>
        {!isChildOrgSelected && (
          <SavedFilters
            filterType={CALL_EXPLORER_FILTER_TYPE}
            selectedSavedFilterId={selectedSavedFilterId}
            handleSelectSavedFilter={handleSelectSavedFilter}
            filterFormOptions={[
              'name',
              'isDefault',
              'filterPropertiesHeader',
              'dateRange',
              'agents',
              'tags',
              'playbooks',
              'callDuration',
              'maxCallDuration',
              'includeCallsWithoutAudio',
              'isWin',
              'keywordHeader',
              'keywords',
              'keywordOptions.side',
              'keywordOptions.logic',
              'playbookPropertiesHeader',
              'checklist',
              'deck',
              'notifications',
              'postcall',
              'scorecards',
              'scorecardThreshold',
              'scoredStatus',
            ]}
          />
        )}
      </header>
      <CallSearchFilters flags={flags} childOrgSelected={isChildOrgSelected} />
      <Formik enableReinitialize onSubmit={handleSubmit} initialValues={{ question: '' }}>
        {({ values, handleSubmit, ...formikProps }) => (
          <>
            <Form className="filter-options filter-form" onSubmit={handleSubmit}>
              <Field
                label="Ask a question about your conversations"
                className="question-input"
                name="question"
                placeholder="Type a question"
                component={TextField}
                onKeyUp={(event) => formikProps.setFieldValue('question', event.target.value)}
              />
              <div>
                <ConditionalTooltip
                  condition={values.question.length === 0}
                  content="You must enter a question"
                >
                  <Button
                    data-testid="dynamic-insights-submit-button"
                    primary
                    type="submit"
                    disabled={values.question.length === 0}
                  >
                    Submit
                  </Button>
                </ConditionalTooltip>
              </div>
            </Form>
            <SuggestedQuestions
              initialCategory="sales"
              questions={dynamicInsightsQuestions}
              onQuestionClick={(value) => formikProps.setFieldValue('question', value)}
            />
          </>
        )}
      </Formik>

      {type === 'progress' && (
        <Segment>
          <div className="insights-loading-container" data-testid="insights-loading">
            <SpinnerLoader dark />
            <div>
              <div>Balto AI is finding the answer to your question sampling hundreds of calls.</div>
              <strong data-testid="insights-stage">{stage}</strong>
            </div>
          </div>
        </Segment>
      )}
      {type === 'result' && isEmpty(categories) && (
        <Segment>
          <ErrorMessage content={err || 'No calls were found'} data-testid="insights-error" />
        </Segment>
      )}

      {type === 'result' && !isEmpty(categories) && (
        <Segment>
          <div className="insights-grid" data-testId="insights-results">
            <h1 className="title" data-testid="title">
              {formatSentenceCase(currentQuestion)}
            </h1>
            <div className="header">Top areas</div>
            <div className="header">Est frequency</div>
            <div>&nbsp;</div>
            {categories.map((category, index) => {
              return (
                <React.Fragment key={kebabCase(category.name)}>
                  <div>
                    {category.name}
                    <div className="description">{category.description}</div>
                  </div>
                  <div>{formatPercent(category.fraction * 100, 0, true)}</div>
                  <div>
                    <Drawer
                      id={uuid()}
                      wide
                      title={<div>Evidence in conversations</div>}
                      trigger={(handleOpen) => (
                        <Button
                          type="button"
                          secondary
                          onClick={handleOpen}
                          data-testid={`evidence-button-${index}`}
                        >
                          Evidence
                        </Button>
                      )}
                    >
                      <div className="evidence-container">
                        {category.calls.map((call) => {
                          return <Evidence call={call} />
                        })}
                      </div>
                    </Drawer>
                  </div>
                </React.Fragment>
              )
            })}
            <div className="footer">
              {formatPercent(fraction_irrelevant * 100, 0, true)} of conversations are irrelevant to
              this question
            </div>
          </div>
        </Segment>
      )}
    </div>
  )
}

export default withLDConsumer()(DynamicInsightsPage)
