import React from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty, cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import NoData from '@/components/NoData'
import { reorderEntry, reorderAndMoveEntry } from '@/reducers/playbooks/playbook.redux'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'
import { reorder } from '@/utils/helpers'

import { CategoryHeader } from './CategoryHeader'
import {
  categoryTriggerTypes,
  getUpdatedSourceAndDestination,
  accessorSectionMap,
  accessors,
} from '../../playbook.helpers'

import { PlaybookEntry } from '../../components/PlaybookEntry'
import { SectionWarnings } from '../../components/SectionWarnings'

export const CategoryEntryDisplay = () => {
  const dispatch = useDispatch()
  const { section } = useParams()
  const {
    playbook,
    playbook: { current: isLatestPlaybookVersion },
    warnings,
    loading,
    validationError,
  } = useSelector((state) => state.playbook)
  const { edit_config } = useSelector((state) => state.currentUser)
  const accessor = accessorSectionMap[section]
  const categories = playbook.body[accessor]
  const readOnly = !isLatestPlaybookVersion || !edit_config
  // No flag or language check needed because this is only for display
  const updatedCategoryTriggerTypes = [
    ...categoryTriggerTypes,
    { label: 'Smart Phrases', value: 'smart_phrases' },
  ]

  const handleReorder = (result) => {
    const { type, source, destination } = result
    const sourceCategoryId = source.droppableId
    const destinationCategoryId = destination.droppableId

    if (!destination) return

    // Reordering dynamic prompt entries
    if (type === 'droppable-entry') {
      // If drag and dropping within the same category
      if (sourceCategoryId === destinationCategoryId) {
        const originalOrder = categories.entries[sourceCategoryId].order
        const newOrder = reorder(originalOrder, source.index, destination.index)

        dispatch(reorderEntry(accessor)({ categoryId: sourceCategoryId, order: newOrder }))
      } else {
        // If drag and dropping to a new category
        const updatedEntries = getUpdatedSourceAndDestination({
          entries: cloneDeep(categories.entries),
          source,
          destination,
          sourceCategoryId,
          destinationCategoryId,
        })

        dispatch(reorderAndMoveEntry(accessor)(updatedEntries))
      }
    }

    // Reordering dynamic promppt categories
    if (type === 'droppable-category') {
      const originalOrder = categories.order
      const newOrder = reorder(originalOrder, source.index, destination.index)

      dispatch(reorderEntry(accessor)(newOrder))
    }
  }

  return (
    <div className="playbook-entries">
      {isEmpty(categories.order) ? (
        <div className="empty-table">
          <NoData text="Create a new category to begin" position="left" />
        </div>
      ) : (
        <>
          {isLatestPlaybookVersion && (
            <SectionWarnings
              section={section}
              warnings={warnings}
              loading={loading.warnings}
              style={{ margin: '0px calc(30px + 0.75rem) 1rem calc(30px + 0.75rem)' }}
            />
          )}
          <DragAndDrop onDragEnd={handleReorder}>
            <Drop droppableId="droppable" type="droppable-category">
              {categories.order.map((categoryId, categoryIndex) => {
                const category = categories.entries[categoryId]

                return (
                  <Drag
                    key={categoryId}
                    draggableId={categoryId}
                    index={categoryIndex}
                    outer
                    nestedEntriesCount={category.order.length}
                    readOnly={readOnly}
                  >
                    <div className="playbook-category-container">
                      <CategoryHeader
                        categoryId={categoryId}
                        category={category}
                        readOnly={readOnly}
                        nestedEntriesCount={category.order.length}
                        validationError={validationError}
                      />
                      <Drop droppableId={categoryId} type="droppable-entry">
                        {category.order.map((entryId, index) => {
                          return (
                            <Drag
                              key={entryId}
                              draggableId={entryId}
                              index={index}
                              readOnly={readOnly}
                            >
                              <PlaybookEntry
                                accessor={accessor}
                                categoryId={categoryId}
                                key={entryId}
                                entryId={entryId}
                                entry={category.entries[entryId]}
                                isWinnable={accessor === accessors.POSTCALL}
                                label={
                                  accessor === accessors.DYNAMIC_PROMPT
                                    ? 'dynamic prompt'
                                    : 'post call item'
                                }
                                triggerTypes={updatedCategoryTriggerTypes}
                                readOnly={readOnly}
                              />
                            </Drag>
                          )
                        })}
                      </Drop>
                    </div>
                  </Drag>
                )
              })}
            </Drop>
          </DragAndDrop>
        </>
      )}
    </div>
  )
}
