// Action Types
export const SET_DYNAMIC_INSIGHT_EVENTS = 'dynamicInsights/setDynamicInsightEvents'

// Action Creators
export const setDyanmicInsightEvents = (payload) => ({ type: SET_DYNAMIC_INSIGHT_EVENTS, payload })

// State
export const initialState = {
  categories: [],
  type: '',
  stage: '',
  stage_items_total: null,
  stage_items_completed: null,
  stage_remaining: null,
  total_remaining: null,
  fraction_irrelevant: 0,
  err: '',
}

export default function dynamicInsightEventsReducer(state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case SET_DYNAMIC_INSIGHT_EVENTS:
      return { ...state, ...JSON.parse(payload) }
    default:
      return state
  }
}
