export const dynamicInsightsQuestions = {
  sales: [
    'Why are customers calling?',
    'What objections do customers have that prevent them from moving forward with a sale or similar commitment?',
    'What are the most common misunderstandings customers have about the company, product, or service?',
    'How are customers hearing about the company?',
  ],
  service: [
    'Why are customers calling?',
    'Why are calls getting escalated to supervisors?',
    "What's preventing agents from successfully resolving customers' issues?",
    'What frustrations do customers have with the product or service?',
    'What are the most common misunderstandings customers have about the company, product, or service?',
    'What complaints do customers have?',
    'How are customers hearing about the company?',
  ],
}
