import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { Form, Button, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { TextField } from '@/components/forms/formik'
import { DragAndDropFileUploader } from '@/components/forms/formik/file-uploader/DragAndDropFileUploader'
import { uploadCustomizationOptions } from '@/reducers/organizations/organization.actions'

import './CustomizeBranding.scss'

const defaultValues = {
  customBrandingDisplayName: '',
  customBrandingPrimaryColor: '',
  customBrandingSecondaryColor: '',
  iconLightUrl: null,
  iconDarkUrl: null,
  faviconUrl: null,
}

const validationSchema = Yup.object().shape({
  customBrandingDisplayName: Yup.string()
    .max(16, 'Organization display name cannot exceed sixteen characters')
    .required('Organization display name is required'),
  iconLightUrl: Yup.mixed(),
  iconDarkUrl: Yup.mixed(),
  faviconUrl: Yup.mixed(),
})

export const CustomizeBrandingForm = ({ organizationId, onClose }) => {
  const { loading, customBranding, imageCache } = useSelector(
    (state) => state.organizationCustomBranding
  )
  const { customBrandingDisplayName, customBrandingTheme } = customBranding
  const dispatch = useDispatch()

  const handleUpload = (values) => {
    dispatch(uploadCustomizationOptions(values, organizationId))
  }

  const resetCustomColors = () => {
    handleUpload({ customBrandingPrimaryColor: null })
  }

  const initialValues = {
    ...defaultValues,
    customBrandingDisplayName,
    customBrandingPrimaryColor: customBrandingTheme ? customBrandingTheme.primary_color : '',
    customBrandingSecondaryColor: customBrandingTheme ? customBrandingTheme.secondary_color : '',
  }

  if (loading) {
    return <Loader inline active />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpload}
    >
      {({ handleSubmit, isValid, errors, ...props }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            data-testid="customize-branding-form"
            className="customize-branding-form"
          >
            <Field
              required
              data-testid="display-name"
              name="customBrandingDisplayName"
              component={TextField}
              placeholder="Your sixteen-character (or less) display name"
              label="Display Name"
              type="text"
            />
            <DragAndDropFileUploader
              name="faviconUrl"
              acceptedFileTypes={{
                'image/x-icon': ['.svg'],
                'image/png': ['.png'],
              }}
              acceptedFileMessage="ICO, PNG. Max file size: 1MB"
              label="Favicon"
              existingValue={customBranding.faviconUrl}
              imageCache={imageCache}
              {...props}
            />
            <DragAndDropFileUploader
              name="iconLightUrl"
              label="Light Mode Logo (Default)"
              existingValue={customBranding.iconLightUrl}
              imageCache={imageCache}
              {...props}
            />
            <DragAndDropFileUploader
              dark
              name="iconDarkUrl"
              label="Dark Mode Logo (Optional)"
              existingValue={customBranding.iconDarkUrl}
              imageCache={imageCache}
              {...props}
            />
            <div className="color-picker-wrapper">
              <Field
                component={TextField}
                data-testid="primary-color"
                name="customBrandingPrimaryColor"
                type="color"
                label={
                  <>
                    <label>Primary Color</label>
                    <div className="form-caption">The color of buttons, links, and the sidebar</div>
                  </>
                }
                className="color-picker"
              />
              <Field
                component={TextField}
                data-testid="secondary-color"
                name="customBrandingSecondaryColor"
                type="color"
                label={
                  <>
                    <label>Secondary Color</label>
                    <div className="form-caption">
                      The hover color of buttons, links, and the sidebar
                    </div>
                  </>
                }
                className="color-picker"
              />
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                onClick={resetCustomColors}
                basic
                data-testid="reset-colors-button"
              >
                Reset Colors
              </Button>
              <Button secondary type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                primary
                data-testid="submit-button"
                type="submit"
                disabled={!isValid || !isEmpty(errors)}
              >
                Submit
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
