import React, { Fragment } from 'react'
import { v4 as uuid } from 'uuid'
import { Popup, Checkbox, Button } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { toString, isEmpty } from 'lodash'
import { IconChevronRight, IconChevronDown } from '@tabler/icons-react'

import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { DragAndDropButton } from '@/components/dragDrop/DragAndDropButton'
import { CopyButton } from '@/components/buttons/CopyButton'
import NoData from '@/components/NoData'
import { shouldShowCopyButton } from './helpers'

import { AdvancedTableCell } from './AdvancedTableCell'

export const AdvancedTableBody = ({
  filteredRows,
  columns,
  actions,
  index,
  borderlessRows,
  isBulkSelectable,
  selectedRows,
  handleBulkSelectSingle,
  reorderable,
  rowAction,
  stickyAction,
  wrapColumnContent,
  dropProvided,
  searchFilters,
  dataGrid,
  disabledSelection,
  expandable,
  handleExpand,
  expandedRows,
}) => {
  const colSpan =
    columns.length + (actions ? 1 : 0) + (reorderable ? 1 : 0) + (isBulkSelectable ? 1 : 0)
  const isNotFiltering =
    isEmpty(searchFilters) || Object.values(searchFilters).every((v) => isEmpty(v))

  return (
    <tbody ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
      {isEmpty(filteredRows) ? (
        <tr className="no-hover">
          <td colSpan={colSpan}>
            <div className="empty-table">
              <NoData />
            </div>
          </td>
        </tr>
      ) : (
        filteredRows.map((row, rowIndex) => {
          const isSelected = isBulkSelectable && selectedRows?.includes(row[index])
          const dragIndex = toString(row[index]) || uuid()

          return (
            <Draggable
              draggableId={dragIndex}
              index={rowIndex}
              key={dragIndex}
              isDragDisabled={!reorderable || !isNotFiltering}
            >
              {(dragProvided, dragSnapshot) => {
                return (
                  <Fragment key={index === 'uuid' ? uuid() : `${row[index]}`}>
                    <tr
                      data-testid={row[index]}
                      ref={dragProvided.innerRef}
                      {...row.rowProps}
                      {...dragProvided.draggableProps}
                      className={classNames({
                        'row-is-dragging': dragSnapshot.isDragging,
                        'row-is-selected': isSelected,
                      })}
                    >
                      {isBulkSelectable && (
                        <td
                          className={classNames('collapsing', {
                            'borderless-row': borderlessRows,
                            'sticky-column': dataGrid,
                            'bulk-selectable-column': dataGrid,
                          })}
                        >
                          <div className="flex-align-center">
                            <Checkbox
                              data-testid={`bulk-select-${row[index]}`}
                              checked={isSelected}
                              onChange={() => {
                                if (handleBulkSelectSingle) {
                                  handleBulkSelectSingle(row[index], row)
                                }
                              }}
                              disabled={disabledSelection}
                            />
                          </div>
                        </td>
                      )}
                      {reorderable && (
                        <td
                          className={classNames('collapsing', { 'borderless-row': borderlessRows })}
                        >
                          <div className="flex-align-center">
                            <ConditionalTooltip
                              condition={!isNotFiltering}
                              tooltipProps={{ position: 'top left' }}
                              content="Cannot reorder while searching or filtering playlists"
                            >
                              <DragAndDropButton
                                snapshot={dragSnapshot}
                                provided={dragProvided}
                                compact
                                disabled={!isNotFiltering}
                              />
                            </ConditionalTooltip>
                          </div>
                        </td>
                      )}
                      {expandable && (
                        <td
                          className={classNames('collapsing', {
                            'borderless-row': borderlessRows,
                            'sticky-column': dataGrid,
                          })}
                        >
                          <div className="flex-align-center">
                            <Button
                              basic
                              compact
                              icon
                              data-testid={`expand-${row[index]}`}
                              onClick={() => handleExpand(row[index])}
                            >
                              {expandedRows.includes(row[index]) ? (
                                <IconChevronDown className="icon-svg" />
                              ) : (
                                <IconChevronRight className="icon-svg" />
                              )}
                            </Button>
                          </div>
                        </td>
                      )}
                      {columns.map((column) => {
                        const sharedRowClasses = {
                          'no-wrap': wrapColumnContent,
                          'sticky-column': column.sticky,
                          'sticky-column-selectable-offset':
                            isBulkSelectable && dataGrid && column.sticky,
                          'borderless-row': borderlessRows,
                          'full-width-row': column.fullWidthRow,
                          [`${column.headerAlignment}-aligned`]: Boolean(column.headerAlignment),
                          collapsing: column.collapsing,
                        }

                        if (row[column.accessor]?.as) {
                          return (
                            <td
                              key={column.accessor}
                              data-testid={`custom-component-${column.accessor}`}
                              {...row[column.accessor].cellProps}
                              className={classNames(
                                row[column.accessor].cellProps?.className,
                                sharedRowClasses
                              )}
                            >
                              {row[column.accessor].as}
                            </td>
                          )
                        }
                        return (
                          <td
                            key={column.accessor}
                            data-testid="table-cell"
                            onClick={(event) =>
                              rowAction && !column.notClickable ? rowAction(event, row) : null
                            }
                            className={classNames({
                              'clickable-row': !!rowAction,
                              ...sharedRowClasses,
                            })}
                          >
                            {shouldShowCopyButton(column, row[column.accessor]) ? (
                              <div className="flex-align-center medium-gap">
                                <AdvancedTableCell row={row} column={column} />
                                <CopyButton
                                  tooltipProps={{ position: 'top left' }}
                                  content={row[column.accessor]}
                                  showLabel={false}
                                  showPopup
                                  iconOnly
                                />
                              </div>
                            ) : (
                              <AdvancedTableCell row={row} column={column} />
                            )}
                          </td>
                        )
                      })}
                      {!isEmpty(actions) && (
                        <td
                          className={classNames('action-cell', { 'sticky-action': stickyAction })}
                        >
                          <div className="actions-icons">
                            {actions.map((action) => (
                              <Popup
                                position="top center"
                                disabled={action.noPopup}
                                inverted
                                key={action.popupLabel ? action.popupLabel(row) : action.label}
                                content={action.popupLabel ? action.popupLabel(row) : action.label}
                                trigger={
                                  action.trigger ? (
                                    action.trigger(row)
                                  ) : (
                                    <Button
                                      basic
                                      compact
                                      icon={!!action.icon}
                                      type="button"
                                      id={`${action.label}-button-${row[index]}`}
                                      key={action.label}
                                      data-testid={`action-button-${action.label}`}
                                      onClick={(event) => action.fn(event, row)}
                                      className={classNames({ 'svg-button': !!action.icon })}
                                    >
                                      {action.icon || action.label}
                                    </Button>
                                  )
                                }
                              />
                            ))}
                          </div>
                        </td>
                      )}
                    </tr>
                    {expandedRows.includes(row[index]) && (
                      <tr>
                        <td colSpan={columns.length + 1}>{row.expandedContent}</td>
                      </tr>
                    )}
                  </Fragment>
                )
              }}
            </Draggable>
          )
        })
      )}
      {dropProvided.placeholder}
    </tbody>
  )
}
