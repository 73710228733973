import { formatTime, sharedScorecardParams } from '@/utils/helpers'

export const formatScorecardFilters = (
  filterValues = {},
  scoreData = null,
  isCopilot = false,
  isPreview = false
) => {
  const filterTypes = [
    { data: 'agents', key: 'agent_ids' },
    { data: 'tags', key: 'tag_ids' },
    { data: 'scorecards', key: 'scorecard_config_sids', value: 'sid' }, // note: qa copilot is "value"
    { data: 'dispositions', key: 'dispositions' },
    { data: 'groupBy', key: 'group_by' },
    { data: 'metrics', key: 'metrics' },
    { data: 'sections', key: 'sections', value: 'ssid' },
    { data: 'measures', key: 'measures', value: 'smid' },
    { data: 'criteria', key: 'criteria', value: 'scid' },
  ]
  const params = new URLSearchParams()
  const orgId = filterValues.organization

  sharedScorecardParams(filterTypes, filterValues, params, orgId, isCopilot)

  if (scoreData && scoreData.percentage_score !== null) {
    params.set('section_score', parseInt(scoreData.percentage_score, 10))
  }

  if (isPreview) {
    params.set('count', 20)
  }

  return params.toString()
}

export const parseDashboardToCsv = (rows, headers) => {
  const addComma = (string) => `${string},`

  const headerRow = headers.reduce(
    (reducer, header) => `${reducer ? addComma(reducer) : ''}${header.label}`,
    ''
  )

  const userScoreStrings = rows.reduce(
    (reducer, row) =>
      `${reducer}${headers.reduce(
        (reducer, header) =>
          `${reducer ? addComma(reducer) : ''}${String(row[header.accessor] || '')}`,
        ''
      )}\n`,
    ''
  )

  const csvText = `${headerRow}\n${userScoreStrings}`

  return new Blob([csvText], { type: 'test/csv' })
}

export const formatDisputes = (disputes) => {
  return disputes.map((dispute) => ({
    ...dispute,
    agent_name: `${dispute.first_name} ${dispute.last_name}`,
    created_at: formatTime(dispute.created_at),
    modified_at: formatTime(dispute.modified_at),
    status: dispute.status === 'pending' ? 'Needs Review' : 'Resolved',
    link_call_id: dispute.call_id,
  }))
}
