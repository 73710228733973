import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Loader } from 'semantic-ui-react'
import { IconCheck, IconX, IconAlertTriangle } from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { fetchOrganizationUsersAndTags } from '@/reducers/organizations/organization.actions'

const columns = [
  { accessor: 'data_type', isSearchable: true, label: 'Data Type' },
  {
    accessor: 'success',
    isSearchable: true,
    label: 'Success',
    format: (cell) => (
      <div className="flex-align-center small-gap">
        {cell === 'True' && <IconCheck className="icon-svg status-success" />}
        {cell === 'False' && <IconX className="icon-svg status-critical" />}
        {cell === 'Warning' && <IconAlertTriangle className="icon-svg status-warning" />}
        <span>{cell}</span>
      </div>
    ),
  },
  { accessor: 'message', isSearchable: true, label: 'Message' },
]

const Receipt = ({ setUserFilter, modalClose, setLoading }) => {
  const dispatch = useDispatch()
  const { organizationid } = useParams()

  const organizationUsersCSV = useSelector((state) => state.organizationUsersCSV)
  const { uploadResponse, streamingComplete } = organizationUsersCSV

  const data = uploadResponse.slice(1).map((row) => {
    const [data_type, success, message] = row
    return { data_type, success, message }
  })

  const loading = !streamingComplete

  const reloadUsersAndTags = () => {
    setUserFilter('active')
    return dispatch(
      fetchOrganizationUsersAndTags({
        organizationId: organizationid,
        setLoading,
        modalClose,
      })
    )
  }

  return (
    <div className="flex-column gap">
      {loading && <Loader active inline="centered" size="massive" />}
      {!loading && <AdvancedTable loading={loading} rows={data} columns={columns} />}
      <div className="flex-center">
        <Button primary onClick={reloadUsersAndTags} disabled={loading}>
          Reload Users
        </Button>
      </div>
    </div>
  )
}

export default Receipt
