import queryString from 'query-string'
import { toast } from 'react-toastify'

import { fetchingAPI, apiService } from '../../api'
import * as actions from './insightEvents.redux'
import * as dynamicInsightsActions from './dynamicInsightEvents.redux'
import { checkOrderDecklist } from './insights.helpers'

export const fetchInsightDetails =
  (cid, organizationId, categoryName, deckName, decklist) => async (dispatch) => {
    try {
      const queryParam = `?${queryString.stringify({
        cid,
        org_id: organizationId,
        deck_cat: categoryName,
        deck_name: deckName,
      })}`
      const insights = await fetchingAPI(
        `${apiService.insights}/api/recommendations/decklist${queryParam}`,
        'GET',
        dispatch
      )
      dispatch(actions.setInsightDetails(insights))

      const [result, orderSuggestion] = checkOrderDecklist(insights, decklist)
      dispatch(actions.setInsightsReorderRecommended(!result))
      if (!result) {
        dispatch(actions.setInsightsSuggestedOrder(orderSuggestion))
      }
    } catch (err) {
      console.error(err)
    }
  }

export const saveInsightEvent = (body) => async (dispatch) => {
  try {
    await fetchingAPI(`${apiService.insights}/api/events`, 'POST', dispatch, JSON.stringify(body))
  } catch (err) {
    console.error(err)
  }
}

export const publishInsightEvents = (events, id) => async (dispatch) => {
  try {
    await Promise.all(
      events.map((item) => {
        const saveEventPayload = { ...item, action_taken: 'save', new_config_id: id }

        return fetchingAPI(
          `${apiService.insights}/api/events`,
          'POST',
          dispatch,
          JSON.stringify(saveEventPayload)
        )
      })
    )
    dispatch(actions.clearInsightEvents())
  } catch (err) {
    console.error(err)
  }
}

export const fetchDynamicInsights = (body, username, token, organizationid) => async (dispatch) => {
  const response = await fetch(`${apiService.summary}/dynsight`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'auth-type': 'web',
      organizationid,
      username,
      token,
    },
  })

  if (!response.ok) {
    toast.error('Failed to retrieve dynamic insights')
    return
  }

  const reader = response.body.getReader()
  const decoder = new TextDecoder()
  let buffer = ''

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      // Decode the incoming chunk and append it to the buffer
      buffer += decoder.decode(value, { stream: true })

      // Process complete lines
      let newlineIndex
      // eslint-disable-next-line no-cond-assign
      while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
        const line = buffer.slice(0, newlineIndex).trim()
        buffer = buffer.slice(newlineIndex + 1) // Remove processed line

        if (line) {
          try {
            dispatch(dynamicInsightsActions.setDyanmicInsightEvents(line))
          } catch (err) {
            console.error('JSON parse error:', err, line)
          }
        }
      }
    }
  } catch (err) {
    console.error('Stream read error:', err)
  }
}
