import React, { useState } from 'react'
import format from 'date-fns/format'
import { differenceInDays } from 'date-fns'

import { getUnixMs } from '@/utils/helpers'

import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'

// Only works for Playlists table right now
export const FilterTypeDate = ({ schema, value, updateSearch, columnAccessor }) => {
  const defaultDateRange = { startDate: null, endDate: null }
  const [range, setRange] = useState(defaultDateRange)
  const [dateInvalid, setDateInvalid] = useState(false)

  const handleClear = (event) => {
    event.stopPropagation()

    setRange(defaultDateRange)
    updateSearch(schema.accessor, '')
    setDateInvalid(false)
  }

  const handleUpdateRange = ({ selection }) => {
    const currentStartDate = format(selection.startDate, "yyyy-MM-dd'T00:00:00'xxx")
    const currentEndDate = format(selection.endDate, "yyyy-MM-dd'T23:59:59'xxx")

    const datesDiff = differenceInDays(selection.endDate, selection.startDate)

    if (datesDiff > 62) {
      setDateInvalid(true)
      setRange({ startDate: currentStartDate, endDate: currentEndDate })
    } else {
      setDateInvalid(false)
      setRange({ startDate: currentStartDate, endDate: currentEndDate })
    }
  }

  const handleSave = () => {
    updateSearch(
      schema.accessor,
      `date_range?start_date=${getUnixMs(range.startDate)}&end_date=${getUnixMs(range.endDate)}`
    )
  }

  return (
    <DateRangePickerReusable
      data-testid="filter-type-date-range"
      withButtons
      startDate={range.startDate}
      endDate={range.endDate}
      cancelLabel="Clear"
      onCancel={handleClear}
      onChange={handleUpdateRange}
      onSave={handleSave}
      shouldNotResetOnCancel
      saveLabel="Apply Date Range"
      error={dateInvalid}
      disableSaveButton={dateInvalid}
      friendlyLabel
      basicLabel
      columnAccessor={columnAccessor}
      noValueSelected={!value}
    />
  )
}
