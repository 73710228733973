import React from 'react'

export const MoreActionsIcon = ({ ...props }) => {
  return (
    <svg height="15" width="15" fill="currentColor" {...props}>
      <circle cx="7.5" cy="2" r="1.75" />
      <circle cx="7.5" cy="7" r="1.75" />
      <circle cx="7.5" cy="12" r="1.75" />
    </svg>
  )
}
