import { useState } from 'react'

export const getPageByItemId = (itemIndex, itemId, array, calculatedRowsPerPage) => {
  let page = 1

  for (const [index, item] of array.entries()) {
    if (item[itemIndex] === itemId) {
      page = Math.ceil((index + 1) / calculatedRowsPerPage)
      break
    }
  }

  return page
}

export const usePagination = (array = [], rowsPerPage = 10) => {
  const [activePage, setActivePage] = useState(1)

  const count = array.length
  const calculatedRowsPerPage = rowsPerPage
  const totalPages = Math.ceil(count / calculatedRowsPerPage)
  const paginatedArray = array.slice(
    (activePage - 1) * calculatedRowsPerPage,
    activePage * calculatedRowsPerPage
  )
  const pagination = {
    activePage,
    setActivePage,
    paginatedArray,
    totalPages,
    count,
    rowsPerPage: calculatedRowsPerPage,
    setPageByItemId: (itemIndex, itemId) => {
      const page = getPageByItemId(itemIndex, itemId, array, calculatedRowsPerPage)

      if (page !== activePage) {
        setActivePage(page)
      }
    },
  }

  return pagination
}
