import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Dimmer, TransitionablePortal } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'
import queryString from 'query-string'

import './Drawer.scss'

export const Drawer = ({ id, title, trigger, wide, children }) => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const { search } = location
  const parsed = queryString.parse(search)

  const handleOpen = () => {
    const search = queryString.stringify({ id })
    history.push({ search })
    setOpen(true)
  }

  const handleClose = () => {
    const search = queryString.stringify({})
    history.push({ search })
    setOpen(false)
  }

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const currentDrawer = id && parsed.id == id

    if (currentDrawer && !open) {
      setOpen(true)
    }
  }, [open, id, parsed])

  return (
    <>
      {!!trigger && trigger(handleOpen)}

      <TransitionablePortal
        closeOnDocumentClick={false}
        open={open}
        transition={{ animation: 'fade', duration: '400' }}
      >
        <Dimmer page active onClick={handleClose} style={{ zIndex: 996 }} />
      </TransitionablePortal>

      <TransitionablePortal
        onClose={handleClose}
        open={open}
        closeOnDocumentClick={false}
        transition={{ animation: 'fly left', duration: '400' }}
      >
        <aside className={classNames('drawer', { wide })} data-testid="drawer">
          {title && (
            <div className="drawer-title">
              {title}
              <Button
                icon
                compact
                basic
                onClick={handleClose}
                className="svg-button"
                data-testid="drawer-close-button"
              >
                <IconX />
              </Button>
            </div>
          )}
          <div className="drawer-content">
            {React.cloneElement(children, { handleOpen, handleClose })}
          </div>
        </aside>
      </TransitionablePortal>
    </>
  )
}
