import React, { useState, useEffect, useCallback } from 'react'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'

const TemporaryLinkForm = ({ user, generateTemporaryLink }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [formattedExpiration, setFormattedExpiration] = useState()
  const [link, setLink] = useState()

  const updateTempLinkExpiration = useCallback((expiration, tempLink) => {
    const formatted = moment(expiration).format('LLLL')
    setFormattedExpiration(formatted)
    setLink(tempLink)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const data = { ...user, is_new: !user.password }
    generateTemporaryLink(updateTempLinkExpiration, data)
  }, [user])

  const instructionMessage = `We've sent an email to ${user.username} that contains a password reset link.
    If they don't see the email from noreply@baltocloud.com in their inbox, please have them check their spam folder.`
  const expirationMessage = `This link will expire on ${formattedExpiration}`
  const loaderMessage = 'Preparing password reset link...'

  const loading = (
    <div className="ui center aligned basic segment">
      <Loader active size="large" inline="centered">
        {loaderMessage}
      </Loader>
    </div>
  )

  const loaded = (
    <>
      <h3>{instructionMessage}</h3>
      {link && <h4>You can also use this link to reset the password:</h4>}
      {link && <h4>{link}</h4>}
      <p>{expirationMessage}</p>
    </>
  )

  return <div className="ui center aligned basic segment">{isLoading ? loading : loaded}</div>
}

export default TemporaryLinkForm
