import React from 'react'
import { Form } from 'semantic-ui-react'

export const TextField = ({
  label,
  errorMessage,
  field: { name, ...fieldProps },
  form: { touched, errors },
  description,
  ...props
}) => {
  const getErrorMessage = () => {
    if (errorMessage) return { content: errorMessage }
    return errors[name] && touched[name] ? { content: errors[name] } : null
  }

  return (
    <Form.Field>
      {label && <label htmlFor={name}>{label}</label>}
      {description && <div className="form-caption">{description}</div>}
      <Form.Input
        data-testid={`textField-${name}`}
        name={name}
        error={getErrorMessage()}
        {...fieldProps}
        {...props}
      />
    </Form.Field>
  )
}
