import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  addTaskComment,
  updateTaskComment,
  fetchTaskComments,
} from '@/reducers/tasks/tasks.actions'
import { Comments } from '@/components/comments/Comments'
import { COACHING_TASK_TYPE } from '@/reducers/tasks/tasks.constants'

export const TaskComments = ({ taskId, taskType, commentType, commentProperty }) => {
  const dispatch = useDispatch()
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [comments, setComments] = useState([])
  const sortedComments = comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

  const fetchComments = async () => {
    setCommentsLoading(true)
    const taskComments = await dispatch(fetchTaskComments(taskId))

    setComments(taskComments)
    setCommentsLoading(false)
  }

  const addComment = async (commentText, associatedId) => {
    await dispatch(addTaskComment(commentText, associatedId))
    fetchComments()
  }

  const updateComment = async (commentText, commentId, associatedId, isDelete) => {
    await dispatch(updateTaskComment(commentText, commentId, associatedId, isDelete))
    fetchComments()
  }

  useEffect(() => {
    fetchComments()
  }, [taskId])

  if (!taskId) {
    return null
  }

  return (
    <Comments
      loading={commentsLoading}
      associatedId={taskId}
      comments={sortedComments}
      addComment={addComment}
      updateComment={updateComment}
      showHeader={false}
      showLabel={false}
      commentType={commentType}
      placeholder={
        taskType === COACHING_TASK_TYPE
          ? 'Write a coaching note for your agent'
          : 'Enter a brief summary of your review, including any necessary notes for your records'
      }
      commentProperty={commentProperty}
      saveOnUnmount
    />
  )
}
