import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormField, Form, TextArea, Loader } from 'semantic-ui-react'
import { isNumber, isEmpty, startCase } from 'lodash'

import { resolveDispute } from '@/reducers/callSearch/callSearch.actions'
import { getCriteriaScoreByIds } from '@/reducers/tasks/tasks.actions'
import { TaskDrawerFooter } from './TaskDrawerFooter'
import { ScoreForm } from './ScoreForm'

export const DisputeDrawer = ({
  taskIsOpen,
  agent,
  task,
  handleClose,
  handlePrimaryAction,
  handleSecondaryAction,
}) => {
  const [criteriaScore, setCriteriaScore] = useState(null)
  const [score, setScore] = useState(0)
  const [comment, setComment] = useState('')
  const dispatch = useDispatch()
  const { user_id: userId } = useSelector((state) => state.currentUser)

  useEffect(() => {
    if (isEmpty(task?.evidence)) return

    const criteriaScoreId = task.evidence?.[0]?.scorecard_criteria_score_id

    if (!criteriaScoreId) return

    dispatch(getCriteriaScoreByIds(criteriaScoreId))
      .then((result) => {
        if (result?.length === 1) {
          setCriteriaScore(result[0])
        }
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    if (criteriaScore?.dispute) {
      setScore(criteriaScore.score)
      setComment(criteriaScore.dispute.manager_comment)
    }
  }, [criteriaScore])

  if (!criteriaScore) {
    return null
  }

  const dispute = criteriaScore?.dispute

  if (!dispute) {
    return null
  }

  const currentScore = criteriaScore?.score
  const criteria = criteriaScore?.name
  const originalScore = isNumber(dispute.previous_score) ? dispute.previous_score : currentScore
  const scorecard_criteria_score_id = criteriaScore?.criteria_score_id

  const getScoreLabel = () => {
    switch (originalScore) {
      case 1:
        return 'Passed'
      case 0:
        return 'Failed'
      case -1:
        return 'N/A'
      default:
        return 'Invalid Score'
    }
  }

  const handleResolveDispute = async () => {
    const resolvedDispute = {
      dispute: {
        ...dispute,
        score,
        manager_comment: comment,
        manager_id: userId,
        scorecard_criteria_scores_id: scorecard_criteria_score_id,
        id: dispute.dispute_id,
        status: originalScore === score ? 'declined' : 'accepted',
      },
    }
    await dispatch(resolveDispute(resolvedDispute, userId))
  }

  if (!criteriaScore) {
    return (
      <div className="empty mb">
        <Loader inline active>
          Loading evidence...
        </Loader>
      </div>
    )
  }

  return (
    <>
      <h3>Dispute Comment</h3>
      <div className="dispute-comment">
        <p data-testid="dispute-comment">
          {agent}: {dispute.user_comment}
        </p>
        <p className="muted-text" data-testid="dispute-original-score">
          Original Score: {getScoreLabel()}
        </p>
      </div>

      <h3>Disputed Criteria</h3>
      <p data-testid="dispute-criteria">{criteria}</p>
      <ScoreForm
        baseDataTestId="dispute"
        isDisabled={!taskIsOpen}
        score={score}
        setScore={setScore}
      />
      <Form>
        <FormField className="mb">
          {!isEmpty(dispute.manager_comment) && !taskIsOpen ? (
            <div className="mb">
              <h3 data-testid="dispute-status">Manager {startCase(dispute.status)} Dispute</h3>
              <div className="dispute-comment mb">
                <p data-testid="dispute-manager-comment">{dispute.manager_comment}</p>
              </div>
            </div>
          ) : (
            <TextArea
              placeholder="Enter your comment here"
              className="mb"
              data-testid="dispute-comment-textarea"
              onChange={(event) => setComment(event.target.value)}
              value={comment}
              disabled={!taskIsOpen}
            />
          )}
        </FormField>
      </Form>
      <TaskDrawerFooter
        taskIsOpen={taskIsOpen}
        handleClose={handleClose}
        handlePrimaryAction={async () => {
          if (taskIsOpen) {
            await handleResolveDispute()
          }
          handlePrimaryAction(task.id, handleClose)
        }}
        handleSecondaryAction={
          !taskIsOpen ? null : () => handleSecondaryAction(task.id, handleClose)
        }
        primaryActionButtonText={!taskIsOpen ? 'Reopen' : 'Mark as Reviewed'}
        secondaryActionButtonText={!taskIsOpen ? null : 'Dismiss'}
      />
    </>
  )
}
