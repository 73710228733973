import React, { useState } from 'react'
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react'
import { Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { MultiSelect } from '@/components/forms/MultiSelect'

import './TransferList.scss'
import pluralize from 'pluralize'

export const TransferList = ({
  label,
  allOptions = [],
  selectedOptions = [],
  handleSelect,
  loading = false,
}) => {
  const [optionsTempValue, setOptionsTempValue] = useState([])
  const [selectedOptionsTempValue, setSelectedOptionsTempValue] = useState([])
  const filteredOptions = allOptions.filter(
    (o) => !selectedOptions.some((so) => so.value === o.value)
  )
  const pluralizedLabel = pluralize(label)

  const handleMoveRight = () => {
    const newSelectedOptions = [...selectedOptions, ...optionsTempValue]
    setOptionsTempValue([])

    handleSelect(newSelectedOptions)
  }

  const handleMoveLeft = () => {
    const newSelectedOptions = selectedOptions.filter((so) => {
      return !selectedOptionsTempValue.find((sot) => sot.value === so.value)
    })

    setSelectedOptionsTempValue([])

    handleSelect(newSelectedOptions)
  }

  return (
    <div className="transfer-list">
      <div>
        <div className="label-style">All {pluralizedLabel}</div>
        <div className="transfer-list__dropdown">
          <MultiSelect
            isSelectAll
            label={label}
            placeholder={`Search Available ${pluralizedLabel}`}
            options={filteredOptions}
            loading={loading}
            value={optionsTempValue}
            onChange={(value) => setOptionsTempValue(value)}
            removeDropdown
            menuIsOpen
          />
        </div>
      </div>
      <div className="flex-column flex-align-center gap">
        <Button
          secondary
          icon
          className="svg-button"
          onClick={handleMoveRight}
          data-testid="move-right-button"
          disabled={isEmpty(optionsTempValue)}
        >
          <IconArrowRight />
        </Button>
        <Button
          secondary
          icon
          className="svg-button"
          onClick={handleMoveLeft}
          data-testid="move-left-button"
          disabled={isEmpty(selectedOptionsTempValue)}
        >
          <IconArrowLeft />
        </Button>
      </div>
      <div>
        <div className="label-style">Selected {pluralizedLabel}</div>
        <div className="transfer-list__dropdown">
          <MultiSelect
            isSelectAll
            label={label}
            placeholder={`Search Selected ${pluralizedLabel}`}
            options={selectedOptions}
            value={selectedOptionsTempValue}
            onChange={(value) => setSelectedOptionsTempValue(value)}
            removeDropdown
            menuIsOpen
            noOptionsMessage={() => `Add ${pluralizedLabel}`}
          />
        </div>
      </div>
    </div>
  )
}
