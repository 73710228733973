import React from 'react'
import classNames from 'classnames'

import { useTranscript } from './TranscriptContext'
import { TranscriptHeader } from './TranscriptHeader'
import { TranscriptBlocks } from './TranscriptBlocks'

import './Transcript.scss'

export const Transcript = ({
  metadata = {},
  showTranscriptSelector = false,
  hardSelectedEvent = null,
  handleTimestampClick = null,
  enableAutoScroll = false,
  connected = false, // connected === not call explorer
}) => {
  const {
    transcriptToDisplay,
    transcriptSelectorOptions,
    transcriptBlockRef,
    showTranscriptHypothesis,
    closestTranscriptIndexRef,
    transcriptType,
    searchQuery,
    allowAutoScroll,
    handleAllowAutoScrollChange,
    handleTranscriptSelectionChange,
    handleSearchTranscript,
  } = useTranscript()

  return (
    <>
      <TranscriptHeader
        searchQuery={searchQuery}
        handleSearchTranscript={handleSearchTranscript}
        handleAllowAutoScrollChange={handleAllowAutoScrollChange}
        showTranscriptSelector={showTranscriptSelector}
        enableAutoScroll={enableAutoScroll}
        allowAutoScroll={allowAutoScroll}
        transcriptSelectorOptions={transcriptSelectorOptions}
        handleTranscriptSelectionChange={handleTranscriptSelectionChange}
        transcriptType={transcriptType}
        connected={connected}
      />
      <div
        data-testid="transcript"
        id="transcript-viewer"
        className={classNames({
          'transcript-connected': connected,
          'call-media-and-transcript-container': !connected,
          'call-window-scrollable__content': !connected,
        })}
      >
        <TranscriptBlocks
          transcriptBlockRef={transcriptBlockRef}
          transcript={transcriptToDisplay}
          handleTimestampClick={handleTimestampClick}
          showTranscriptHypothesis={showTranscriptHypothesis}
          closestTranscriptIndexRef={closestTranscriptIndexRef}
          searchQuery={searchQuery}
          hardSelectedEvent={hardSelectedEvent}
          metadata={metadata}
          connected={connected}
        />
      </div>
    </>
  )
}
