import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { IconArrowLeft, IconFilter, IconStar } from '@tabler/icons-react'
import pluralize from 'pluralize'

import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { deleteSavedFilter } from '@/reducers/savedFilters/savedFilters.actions'
import { MY_TEAM_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { getMyTeamFilterAgents } from '@/reducers/savedFilters/savedFilters.helpers'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import Select from '@/components/forms/Select'

import { SavedFilterForm } from './SavedFilterForm'
import { SavedFiltersList } from './SavedFiltersList'
import { AgentList } from './AgentList'

import './SavedFilters.scss'

const MANAGE_FILTERS_MODAL = 'savedFilterModal/manageFilters'
const CREATE_EDIT_FILTER_MODAL = 'savedFilterModal/createFilter'

export const SavedFilters = ({
  filterType,
  filterFormOptions = [],
  modalProps = {},
  buttonProps = {},
  buttonLabel,
  hideFilterList,
  hideButton,
  hideEditCreate,
  noFiltersTitle,
  noFiltersMessage,
  noFiltersButtonLabel,
  selectedSavedFilterId,
  handleSelectSavedFilter,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const { savedFilterList, loading: savedFiltersLoading } = useSelector(
    (state) => state.savedFilters
  )
  const [selectedFilter, setSelectedFilter] = useState(null)
  const savedFiltersByType = savedFilterList[filterType] || []
  const isMyTeamFilter = filterType === MY_TEAM_FILTER_TYPE
  const savedFilterOptions = savedFilterList[filterType].map((filter) => ({
    value: filter.uuid,
    label: filter.name,
    is_default: filter.is_default,
  }))

  const showNoFiltersMessage = isEmpty(savedFiltersByType)
  const myTeamAgents = getMyTeamFilterAgents(savedFiltersByType)

  const openManageFiltersModal = () => {
    if (isMyTeamFilter && !isEmpty(savedFiltersByType)) {
      setSelectedFilter(savedFiltersByType[0])
      dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
    } else {
      dispatch(openModal(MANAGE_FILTERS_MODAL))
    }
  }

  const openCreateFilterModal = () => {
    setSelectedFilter(null)
    dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
  }

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const handleBackButtonClick = () => {
    dispatch(openModal(MANAGE_FILTERS_MODAL))
  }

  const handleEditFilter = (filterId) => {
    const selectedFilter = savedFiltersByType.find((filter) => filter.uuid === filterId)

    setSelectedFilter(selectedFilter)
    dispatch(openModal(CREATE_EDIT_FILTER_MODAL))
  }

  const handleDeleteFilter = (filterId) => {
    dispatch(deleteSavedFilter(filterId, filterType))

    if (hideEditCreate) {
      handleCloseModal()
    }
  }

  return (
    <>
      <div data-testid="manage-filters" style={{ display: 'flex', gap: '0.75rem' }}>
        {!isEmpty(myTeamAgents) && isMyTeamFilter && <AgentList agents={myTeamAgents} />}
        {!hideButton && (
          <Button
            icon
            className="no-wrap svg-button"
            data-testid="manage-filters-button"
            type="button"
            secondary
            loading={savedFiltersLoading[filterType]}
            onClick={openManageFiltersModal}
            {...buttonProps}
          >
            <IconFilter />
            {buttonLabel || 'Saved Filters'}
          </Button>
        )}

        {!isEmpty(savedFilterOptions) && !hideFilterList && (
          <Select
            placeholder="Select Filter"
            options={savedFilterOptions}
            data-testid="filter-select"
            value={selectedSavedFilterId}
            isClearable
            onChange={handleSelectSavedFilter}
            getOptionLabel={(props) => {
              const { label } = props

              if (props.is_default) {
                return (
                  <div className="flex-align-center small-gap">
                    <IconStar className="icon-svg status-brand" />
                    <span>{label}</span>
                  </div>
                )
              }

              return label
            }}
          />
        )}
      </div>

      {modal === MANAGE_FILTERS_MODAL && (
        <BasicModal
          data-testid="manage-filters-modal"
          className="saved-filters-modal"
          title={isMyTeamFilter ? 'My Team' : 'My Saved Filters'}
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
          footer={
            <Button primary onClick={handleCloseModal}>
              Done
            </Button>
          }
          {...modalProps}
        >
          {showNoFiltersMessage ? (
            <div className="no-filters-wrapper">
              <h1>{noFiltersTitle || 'You have no filters'}</h1>
              <div className="text-center">
                {noFiltersMessage || 'Get started by creating a new filter'}
              </div>
              {!hideEditCreate && (
                <Button primary data-testid="create-filter-button" onClick={openCreateFilterModal}>
                  {noFiltersButtonLabel || 'Create Filter'}
                </Button>
              )}
            </div>
          ) : (
            <>
              {!isMyTeamFilter && !isEmpty(savedFiltersByType) && (
                <div className="saved-filters-summary">
                  <span className="saved-filters-count">
                    {pluralize('saved filter', savedFiltersByType.length, true)}
                  </span>
                  {!hideEditCreate && (
                    <Button
                      data-testid="new-filter-button"
                      secondary
                      onClick={openCreateFilterModal}
                    >
                      Create Filter
                    </Button>
                  )}
                </div>
              )}

              <SavedFiltersList
                hideEditCreate={hideEditCreate}
                savedFilters={savedFiltersByType}
                onEditClick={handleEditFilter}
                onDeleteClick={handleDeleteFilter}
              />
            </>
          )}
        </BasicModal>
      )}

      {modal === CREATE_EDIT_FILTER_MODAL && (
        <BasicModal
          data-testid="new-filter-modal"
          title={
            isMyTeamFilter ? (
              'My Team'
            ) : (
              <div className="flex-align-center medium-gap">
                <Button
                  icon
                  secondary
                  type="button"
                  className="svg-button"
                  onClick={handleBackButtonClick}
                >
                  <IconArrowLeft />
                </Button>
                <div>{selectedFilter ? 'Edit' : 'New'} Filter</div>
              </div>
            )
          }
          onClose={handleCloseModal}
          show={!!modal}
          size="small"
          showCloseButton={false}
        >
          <SavedFilterForm
            selectedFilter={selectedFilter}
            filterFormOptions={filterFormOptions}
            filterType={filterType}
          />
        </BasicModal>
      )}
    </>
  )
}
