import React, { useState } from 'react'
import { Segment, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { CopyButton } from '@/components/buttons/CopyButton'

export const Evidence = ({ call }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleSummary = () => {
    setExpanded(!expanded)
  }

  return (
    <Segment key={call.call_id} data-testid="evidence-component">
      <h4>{call.explanation}</h4>
      {expanded ? (
        <p data-testid="call-summary-full">{call.summary}</p>
      ) : (
        <p data-testid="call-summary-partial" className="truncated">
          {call.summary}
        </p>
      )}
      <Button type="button" onClick={toggleSummary} data-testid="expand-collapse-button">
        {expanded ? 'Collapse' : 'Expand'} Summary
      </Button>
      <h5>Call Excerpts</h5>
      <ul>
        {call.excerpts.map((excerpt) => {
          return <li key={uuid()}>{excerpt}</li>
        })}
      </ul>
      <div className="flex align-center flex-end">
        <Link to={`/call-explorer/${call.call_id}`} target="_blank" className="view-call-button">
          View call
        </Link>
        <CopyButton
          content={`${call.explanation} \n\n ${call.summary}`}
          popupContent="Copy evidence to clipboard"
          showLabel={false}
          showPopup
          iconOnly
        />
      </div>
    </Segment>
  )
}
