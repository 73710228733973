import { isEmpty, isArray, isObject, isNil, isString, isBoolean, toLower } from 'lodash'
import queryString from 'query-string'

function lower(value) {
  if (isString(value)) {
    return toLower(value)
  }

  return value
}

export const sortArray = (a, b, isAscending) => {
  if (a[0] == null) {
    return 1
  }

  if (b[0] == null) {
    return -1
  }

  if (isAscending) {
    return lower(a[0].value) < lower(b[0].value) ? -1 : 1
  }

  return lower(a[0].value) < lower(b[0].value) ? 1 : -1
}

export const sortComparator = (a, b, orderBy, isAscending) => {
  if (
    typeof a[orderBy] === 'object' &&
    a[orderBy] != null &&
    typeof b[orderBy] === 'object' &&
    b[orderBy] != null
  ) {
    if (Array.isArray(b[orderBy]) && Array.isArray(a[orderBy])) {
      const bArray = b[orderBy]
      const aArray = a[orderBy]

      return sortArray(aArray, bArray, isAscending)
    }

    // Some of the values will be an object with a value property instead of just the value itself, this handles that case
    const objA = a[orderBy].value
    const objB = b[orderBy].value

    if (objB == null) {
      return -1
    }

    if (objA == null) {
      return 1
    }

    if (objA === objB) {
      return 0
    }

    if (isAscending) {
      return lower(objA) < lower(objB) ? -1 : 1
    }

    return lower(objA) < lower(objB) ? 1 : -1
  }

  if (b[orderBy] == null) {
    return -1
  }

  if (a[orderBy] == null) {
    return 1
  }

  if (lower(a[orderBy]) === lower(b[orderBy])) {
    return 0
  }

  if (isAscending) {
    return lower(a[orderBy]) < lower(b[orderBy]) ? -1 : 1
  }

  return lower(a[orderBy]) < lower(b[orderBy]) ? 1 : -1
}

export const getComparator = (order, orderBy) => {
  const isAscending = order !== 'desc'
  const comparatorOrderBy = orderBy === 'full_name' ? 'last_name' : orderBy

  return (a, b) => sortComparator(a, b, comparatorOrderBy, isAscending)
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

export function getPageBeginning(activePage, rowsPerPage, count) {
  if (count === 0) {
    return 0
  }

  // if the first page, the first entry is 1
  if (activePage === 1) {
    return 1
  }

  // Number of entries multiplied by the page, offset by one
  return rowsPerPage * (activePage - 1) + 1
}

export function getPageEnd(activePage, totalPages, rowsPerPage, count, pageBeginning) {
  if (count === 0) {
    return 0
  }

  // if the last page, last entry will be the total count
  if (activePage === totalPages) {
    return count
  }

  // Number of entries plus the first entry of the page, offset by one
  return pageBeginning + rowsPerPage - 1
}

export const filterRows = (rows, searchFilters) => {
  const filteredRows = rows.filter((row) => {
    if (isEmpty(searchFilters)) return row

    return Object.keys(searchFilters).every((accessor) => {
      // Dealing with false Booleans as values is annoying
      let rowValue = !isNil(row[accessor]?.value) ? row[accessor].value : row[accessor]

      if (!isNil(rowValue)) {
        rowValue = rowValue.toString()
      }

      // Begin filtering values

      // Multi select is an array
      if (isArray(searchFilters[accessor])) {
        if (isEmpty(searchFilters[accessor])) return true

        return searchFilters[accessor].some((filter) => {
          return toLower(filter.value).includes(toLower(rowValue))
        })
      }

      if (isBoolean(searchFilters[accessor])) {
        return toLower(rowValue).includes(toLower(searchFilters[accessor].toString()))
      }

      // Regular search is string values
      if (isString(searchFilters[accessor])) {
        // Time for some hacking to filter dates within the table
        // I formatted the value as `datetime?start_date={unix_ms}&end_date={unix_ms}`
        if (searchFilters[accessor].startsWith('date_range?')) {
          const dateRangeValues = searchFilters[accessor].split('date_range?')
          const { start_date, end_date } = queryString.parse(dateRangeValues[1])

          // Handle date range
          return rowValue >= start_date && rowValue <= end_date
        }

        return toLower(rowValue).includes(toLower(searchFilters[accessor]))
      }

      return true
    })
  })

  return filteredRows
}

// Right now, headers for a data grid page can look like this:
//
// [main header]            : fixed height (60px)
// [sub header]             : potentially variable height (optional)
// [bulk select banner]     : fixed height (optional)
// [table]                  : requires exact calculated height passed in based on the view height minus all offsets
// [pagination]             : fixed height (44px)
//
// The sub header is optional, and the height is potentially variable because the number of pills visible on the call explorer page can vary.
// This function exists to pass in the ref with the clientHeight of the potentially variable subheader, and calculate the height of the table based on what is visible.
// It's kind of a nightmare, but we wanted those fixed column headers and all the other features.
export const calculateDataGridOffsets = (
  dataGrid,
  someRowsSelected = false,
  additionalHeaderOffset = 0
) => {
  if (!dataGrid) {
    return 0
  }

  const headerOffset = 60
  const paginationOffset = 44
  const bulkSelectBannerOffset = someRowsSelected ? 50 : 0
  const offset = headerOffset + additionalHeaderOffset + paginationOffset + bulkSelectBannerOffset

  return offset
}

export const shouldShowCopyButton = (column, value) => {
  if (!column.isCopiable) {
    return false
  }

  if (isArray(value) || isObject(value)) {
    return !isEmpty(value)
  }

  return !!value
}
