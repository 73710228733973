import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field } from 'formik'
import { isEmpty } from 'lodash'
import { Form, Button, Loader } from 'semantic-ui-react'

import { getSinglePromptTestResults } from '@/reducers/prompts/prompts.actions'
import { TextField, Select } from '@/components/forms/formik'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { AgentSummaryView } from './AgentSummaryView'

export const TestPromptForm = ({ values, setFieldValue }) => {
  const dispatch = useDispatch()
  const { rtnModelOptions, rtnPrompt } = useSelector((state) => state.playbook)
  const { organizationid } = useSelector((state) => state.currentUser)
  const {
    loading: { promptTests: promptTestsLoading },
  } = useSelector((state) => state.prompts)
  const [testResponse, setTestResponse] = useState(null)
  const formattedModelOptions = rtnModelOptions.map((option) => ({
    value: option.name,
    label: option.display_name,
  }))

  useEffect(() => {
    if (!isEmpty(rtnPrompt.model)) setFieldValue('model', rtnPrompt.model)
  }, [rtnPrompt.model])

  const handleTestRTNPrompt = async () => {
    const response = await dispatch(
      getSinglePromptTestResults(
        values.prompt_id,
        {
          user_prompt: values.user_prompt,
          model: values.model,
          feature: 'summarization_test',
        },
        '',
        values.call_id
      )
    )

    setTestResponse(response)
  }

  const handleModelChange = (model, action) => {
    if (action.action === 'clear') {
      setFieldValue('model', '')
    } else {
      setFieldValue('model', model.value)
    }
  }

  return (
    <div className="rtn-editor__prompt">
      <h2>3. Test Your Prompt</h2>
      <p className="muted-text">Run your instructions to generate a preview and verify results.</p>
      <div className="rtn-prompt__additional-details">
        {organizationid === 1 && (
          <Form.Field data-testid="ai-model-dropdown-container">
            <label>AI Model</label>
            <Field
              data-testid="ai-model-dropdown"
              name="model"
              component={Select}
              options={formattedModelOptions}
              onChange={handleModelChange}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label>Call ID</label>
          <Field name="call_id" component={TextField} placeholder="Enter a CallID" />
        </Form.Field>
        <ConditionalTooltip
          condition={!values.call_id}
          content="You must add a call ID"
          triggerProps={{ style: { display: 'inline-flex' } }}
        >
          <Button
            data-testid="run-test-btn"
            secondary
            onClick={handleTestRTNPrompt}
            disabled={!values.call_id}
            type="button"
          >
            Run Test
          </Button>
        </ConditionalTooltip>
        <div>
          {promptTestsLoading ? (
            <div className="empty-rtn-response" data-testid="test-result-loader">
              <Loader active inline />
            </div>
          ) : (
            testResponse?.parsed_response && (
              <div className="rtn-response-holder" data-testid="rtn-response-holder">
                <AgentSummaryView parsedSummary={testResponse?.parsed_response} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
