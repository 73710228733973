import React from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { IconVideo, IconVideoOff } from '@tabler/icons-react'

export const VideoPlayerToggleButton = ({ isVideoVisible, toggleVideoVisibility }) => {
  return (
    <Popup
      inverted
      content={isVideoVisible ? 'Hide Video' : 'Show Video'}
      trigger={
        <Button className="svg-button" secondary icon compact onClick={toggleVideoVisibility}>
          {isVideoVisible ? <IconVideoOff className="status-critical" /> : <IconVideo />}
        </Button>
      }
    />
  )
}
