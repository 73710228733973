import React from 'react'
import { Popup } from 'semantic-ui-react'
import classNames from 'classnames'

export const FilterTypePopup = ({
  open,
  setOpen,
  trigger,
  children,
  position,
  popupProps = {},
}) => {
  const { className, ...props } = popupProps
  return (
    <Popup
      basic
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className={classNames('filter-pill__popup', className)}
      on="click"
      position={position}
      trigger={<div className="filter-pill__trigger">{trigger}</div>}
      {...props}
    >
      {children}
    </Popup>
  )
}
