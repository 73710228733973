import React, { useState } from 'react'
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react'

import { FilterPill } from '../FilterPill'
import { FilterTypePopup } from './FilterTypePopup'
import { FilterDropdownOptions } from './FilterDropdownOptions'

export const FilterTypeSort = ({
  label,
  options,
  sortConfig,
  loading,
  updateSortConfig,
  showAllOnEmpty = false,
  popupProps = {},
}) => {
  const [open, setOpen] = useState(false)
  const orderByOptions = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
  ]

  const optionSets = [
    { accessor: 'sortBy', options, selectedValue: sortConfig.sortBy },
    { accessor: 'orderBy', options: orderByOptions, selectedValue: sortConfig.orderBy },
  ]

  return (
    <FilterTypePopup
      open={open}
      setOpen={setOpen}
      position="bottom left"
      popupProps={{ className: 'filter-pill__popup--medium', ...popupProps }}
      trigger={
        <FilterPill
          icon={sortConfig.orderBy === 'asc' ? <IconSortAscending /> : <IconSortDescending />}
          showAllOnEmpty={showAllOnEmpty}
          clickable
          isClearable={false}
          loading={loading}
          label={label}
          value={options.find((option) => option.value === sortConfig.sortBy)?.label}
        />
      }
    >
      <FilterDropdownOptions
        optionSets={optionSets}
        handleChange={(accessor, value) => {
          const updatedValue = { ...sortConfig, [accessor]: value }
          updateSortConfig(updatedValue)
          setOpen(false)
        }}
      />
    </FilterTypePopup>
  )
}
