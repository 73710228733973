import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Button, Form, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { first, isEmpty } from 'lodash'

import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'

import {
  fetchAgentsByOrg,
  fetchDispositionsByOrg,
  fetchScorecardsByOrg,
  fetchTagsByOrg,
} from '@/reducers/scorecards/scorecards.actions'
import { setData, setDateRangeFilter, setFilter } from '@/reducers/scorecards/scorecards.redux'
import {
  getNestedOptions,
  getOrganizationOptions,
  getHierarchyOrganizationOptions,
  getScorecardOptions,
  getSectionOptions,
} from '@/utils/helpers'
import DateRangePickerDefinedReusable from '@/components/DateRangePickerDefinedReusable'
import Select from '@/components/forms/Select'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { WinsFilter } from '@/components/filters/WinsFilter'
import { MultiFilter } from '@/components/filters/MultiFilter'

import './viewScoresFilters.css'

export const ViewScoresFilters = ({
  handleFilterSubmit,
  hide = {},
  hideFilterButton,
  dateType = 'full',
  multiScorecard = false,
  exports = false,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isQACopilot = location.pathname.startsWith('/qa-copilot')
  const requestedScorecardType = isQACopilot ? 'copilot' : 'automated'

  const { data, filters, loading } = useSelector((state) => state.scorecards)
  const {
    organizationid: currentUserOrgId,
    hierarchy_manager,
    edit_qa_copilot,
  } = useSelector((state) => state.currentUser)
  const { flattenedUserOrgHierarchy } = useSelector((state) => state.orgHierarchy)
  const { organizations } = useSelector((state) => state)

  const [selectedOrgId, setSelectedOrgId] = useState(currentUserOrgId)
  const [selectedScorecards, setSelectedScorecards] = useState([])
  const [selectedSections, setSelectedSections] = useState([])
  const [selectedMeasures, setSelectedMeasures] = useState([])
  const [dateInvalid, setDateInvalid] = useState(false)

  const isBaltoAdmin = currentUserOrgId === 1
  const showManagerHierarchyOrgDropdown =
    !isBaltoAdmin && hierarchy_manager && edit_qa_copilot && isQACopilot // needed here because this is shared with non-QA Copilot scorecards
  const hasOrganizationDropdown = isBaltoAdmin || showManagerHierarchyOrgDropdown

  useEffect(() => {
    // If something is hidden, we need to reset the filter, so it doesn't get added to api calls on other pages.
    Object.keys(hide).forEach((key) => {
      if (hide[key]) {
        if (key === 'isWin') {
          dispatch(setFilter('isWin', null))
        } else if (key === 'multiScorecard' || key === 'dateRange') {
          // Not arrays so don't reset. Hopefully no new hides get added...
        } else {
          // Can't use initial state because filter initial state is incomplete
          dispatch(setFilter(key, []))
        }
      }
    })
    if (!exports) {
      dispatch(setFilter('groupBy', []))
      dispatch(setFilter('metrics', []))
    }
  }, [])

  // ** INITIAL DATA LOAD **
  useEffect(() => {
    if (selectedOrgId) {
      dispatch(fetchAgentsByOrg(selectedOrgId))
      dispatch(fetchScorecardsByOrg(selectedOrgId, requestedScorecardType))
      dispatch(fetchTagsByOrg(selectedOrgId))
      dispatch(fetchDispositionsByOrg(selectedOrgId))
    }
  }, [selectedOrgId])

  useEffect(() => {
    // scorecard exists in Redux filters but not local filters
    const scorecardNotSelected = first(filters.scorecards)?.value && isEmpty(selectedScorecards)

    if (selectedOrgId && !hide.scorecards) {
      if (scorecardNotSelected) {
        const scorecard = data.scorecardConfigs?.find(
          (scorecard) => scorecard.id === first(filters.scorecards)?.value
        )
        if (scorecard) {
          setSelectedScorecards([scorecard])
          dispatch(setData('targetScorecardConfig', scorecard))
        }
      } else if (!isEmpty(data.scorecardConfigs)) {
        const firstScorecard = first(data.scorecardConfigs)
        const firstScorecardFilter = {
          id: firstScorecard.id,
          value: firstScorecard.id,
          label: firstScorecard.name,
          sid: firstScorecard.sid, // Needed for aggregate endpoint
        }
        dispatch(setFilter('scorecards', [firstScorecardFilter]))
        dispatch(setData('targetScorecardConfig', firstScorecard))
        setSelectedScorecards([firstScorecard])
      } else {
        setSelectedScorecards([])
      }
    }
  }, [data.scorecardConfigs])

  const getSelectedScorecardFilters = () => {
    const scorecardFilters = []
    if (!isEmpty(selectedScorecards)) {
      selectedScorecards.forEach((scorecard) => {
        const payload = {
          id: scorecard.id,
          value: scorecard.id,
          label: scorecard.name,
          sid: scorecard.sid, // Needed for aggregate endpoint
        }
        scorecardFilters.push(payload)
      })
    }
    return scorecardFilters
  }

  const organizationOptions = useMemo(() => {
    return getOrganizationOptions(organizations)
  }, [organizations])

  const hierarchyOrganizationOptions = useMemo(
    () => getHierarchyOrganizationOptions(flattenedUserOrgHierarchy),
    [flattenedUserOrgHierarchy?.length]
  )

  const scorecardOptions = useMemo(() => {
    return selectedOrgId ? getScorecardOptions(data.scorecardConfigs) : []
  }, [data.scorecardConfigs, selectedOrgId])

  const sectionOptions = useMemo(() => {
    return getSectionOptions(first(selectedScorecards))
  }, [selectedScorecards])

  const measureOptions = useMemo(() => {
    return getNestedOptions(selectedSections, 'measures')
  }, [selectedSections])

  const criteriaOptions = useMemo(() => {
    return getNestedOptions(selectedMeasures, 'criteria')
  }, [selectedMeasures])

  // ** CLEAR DEPENDENT FILTERS **
  const resetMeasuresDependentFilters = () => {
    dispatch(setFilter('criteria', []))
  }

  const resetSectionsDependentFilters = () => {
    dispatch(setFilter('measures', []))
    setSelectedMeasures([])
    resetMeasuresDependentFilters()
  }

  const resetScorecardDependentFilters = () => {
    dispatch(setFilter('sections', []))
    setSelectedSections([])
    resetSectionsDependentFilters()
  }

  const resetGroupByAndMetricsFilters = () => {
    dispatch(setFilter('groupBy', []))
    dispatch(setFilter('metrics', []))
    resetSectionsDependentFilters()
  }

  const resetOrgDependentFilters = () => {
    dispatch(setFilter('scorecards', []))
    setSelectedScorecards([])

    dispatch(setFilter('agents', []))
    dispatch(setFilter('tags', []))
    dispatch(setFilter('dispositions', []))
    resetScorecardDependentFilters()
  }

  const filterIsDisabled = () => {
    // Disable button if date is over 62 days
    return loading.scores || dateInvalid
  }

  // FORM SELECTOR COMMON METHODS
  const selectScorecard = (scorecardId) => {
    if (scorecardId) {
      const selectedScorecard = data.scorecardConfigs?.find(
        (scorecard) => scorecard.id === scorecardId
      )
      const payload = {
        id: selectedScorecard?.id,
        value: selectedScorecard?.id,
        label: selectedScorecard?.name,
        sid: selectedScorecard?.sid,
      }
      resetScorecardDependentFilters()
      setSelectedScorecards([payload])
      dispatch(setData('targetScorecardConfig', selectedScorecard))
      dispatch(setFilter('scorecards', [payload]))
    }
  }

  const selectSections = (sectionIds) => {
    const filteredSections = []
    const filteredSectionsOption = []
    if (sectionIds?.length > 0 && selectedScorecards) {
      sectionIds.forEach((sectionId) => {
        const sectionFound = first(selectedScorecards)?.sections?.find((section) => {
          return section.id === sectionId
        })
        if (sectionFound) {
          filteredSections.push(sectionFound)
          filteredSectionsOption.push({
            value: sectionFound.id,
            label: sectionFound.name,
            ssid: sectionFound.ssid,
          })
        }
      })
    }
    resetSectionsDependentFilters()
    setSelectedSections(filteredSections)
    dispatch(setFilter('sections', filteredSectionsOption))
  }

  const selectMeasures = (measureIds) => {
    const filteredMeasures = []
    const filteredMeasuresOption = []
    if (measureIds?.length > 0 && selectedSections?.length > 0) {
      measureIds.forEach((id) => {
        selectedSections.forEach((section) => {
          const measureFound = section?.measures?.find((measure) => measure.id === id)
          if (measureFound) {
            filteredMeasures.push(measureFound)
            filteredMeasuresOption.push({
              value: measureFound.id,
              label: measureFound.name,
              smid: measureFound.smid,
            })
          }
        })
      })
    }

    resetMeasuresDependentFilters()
    setSelectedMeasures(filteredMeasures)
    dispatch(setFilter('measures', filteredMeasuresOption))
  }

  useEffect(() => {
    // QA Copilot has only one measure per section, so we select those by default
    if (!isEmpty(selectedSections) && isQACopilot) {
      const newMeasureOptions = []
      selectedSections.forEach((section) => {
        const firstMeasure = section.measures[0]
        if (firstMeasure) {
          newMeasureOptions.push(firstMeasure.id)
        }
      })
      selectMeasures(newMeasureOptions)
    }
  }, [measureOptions])

  // ** FORM HANDLERS **
  const handleOrgSelect = (selectedOrgLabel) => {
    const selectedOrgId = selectedOrgLabel?.value ?? null

    // When new Org is selected, we re-fetch options
    if (selectedOrgId) {
      dispatch(fetchTagsByOrg(selectedOrgId))
      dispatch(fetchAgentsByOrg(selectedOrgId))
      dispatch(fetchScorecardsByOrg(selectedOrgId, requestedScorecardType))
      setSelectedOrgId(selectedOrgId)
    }
    // When Org is cleared, we clear dependent filters
    resetOrgDependentFilters()
    dispatch(setFilter('organization', selectedOrgId))
    setSelectedOrgId(selectedOrgId)
  }

  const handleMultiScorecardConfigSelect = (multiScorecardOptions) => {
    const selectedScorecardIds = multiScorecardOptions.map(({ value }) => value)
    const selectedScorecards = data.scorecardConfigs?.filter((scorecard) =>
      selectedScorecardIds.includes(scorecard.id)
    )
    resetScorecardDependentFilters()
    resetGroupByAndMetricsFilters()
    setSelectedScorecards(selectedScorecards)
    dispatch(setFilter('scorecards', multiScorecardOptions))
  }

  const handleScorecardConfigSelect = (selectedScorecardsLabel) => {
    const selectedScorecardsId = selectedScorecardsLabel?.value ?? null
    selectScorecard(selectedScorecardsId)
  }

  const handleSectionsSelect = (sectionOptions) => {
    const sectionIds = sectionOptions.map((option) => option.value)
    selectSections(sectionIds)
  }

  const handleUpdateFilters = (section, value) => {
    dispatch(setFilter(section, value))
  }

  const handleMeasuresSelect = (measuresOptions) => {
    const measureIds = measuresOptions.map((option) => option.value)
    selectMeasures(measureIds)
  }

  const selectCriteria = (criteriaIds) => {
    const filteredCriteriaOptions = []
    if (criteriaIds?.length > 0 && selectedSections?.length > 0) {
      criteriaIds.forEach((id) => {
        selectedMeasures.forEach((measure) => {
          const criteriaFound = measure?.criteria?.find((criteria) => criteria.id === id)
          if (criteriaFound) {
            filteredCriteriaOptions.push({
              value: criteriaFound.id,
              label: criteriaFound.name,
              scid: criteriaFound.scid,
            })
          }
        })
      })
    }
    dispatch(setFilter('criteria', filteredCriteriaOptions))
  }

  const handleCriteriaSelect = (criteriaOptions) => {
    const criteriaIds = criteriaOptions.map((option) => option.value)
    selectCriteria(criteriaIds)
  }

  const handleDateRangeFilterChange = (value) => {
    const formattedDate = {}
    formattedDate.startDate = moment(value.startDate).startOf('day').utc().format()
    formattedDate.endDate = moment(value.endDate).endOf('day').utc().format()
    const datesDiff = moment.utc(value.endDate).diff(moment.utc(value.startDate), 'days')
    if (datesDiff > 62) {
      setDateInvalid(true)
    } else {
      setDateInvalid(false)
      dispatch(setDateRangeFilter(formattedDate))
    }
  }

  const emptyCriteria = criteriaOptions?.length === 0
  const emptyMeasures = measureOptions?.length === 0
  const emptySections = sectionOptions?.length === 0
  const criteriaPlaceHolderText = emptyCriteria ? 'No criteria found' : 'Select Criteria'
  const measurePlaceHolderText = emptyMeasures ? 'No measures found' : 'Select Measures'
  const sectionPlaceHolderText = emptySections ? 'No sections found' : 'Select Sections'

  return (
    <div className="view-scores-filters" data-testid="view-scores-filters">
      <Form
        className="ui form filter-form"
        data-testid="view-scores-form"
        onSubmit={() => handleFilterSubmit()}
      >
        <div>
          <div className="exports-filters-grid">
            {!hide.dateRange && dateType === 'full' && (
              <Form.Field data-testid="date-range-field">
                <label>Date Range</label>
                <DateRangePickerReusable
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onChange={({ selection }) => {
                    handleDateRangeFilterChange(selection)
                  }}
                  error={dateInvalid}
                />

                {dateInvalid && (
                  <ErrorMessage
                    content="Date range must be two months or less"
                    data-testid="datepicker-error"
                  />
                )}
              </Form.Field>
            )}
            {!hide.dateRange && dateType === 'defined' && (
              <Form.Field data-testid="date-range-field">
                <label>Date Range</label>
                <DateRangePickerDefinedReusable
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onChange={({ selection }) => {
                    handleDateRangeFilterChange(selection)
                  }}
                  error={dateInvalid}
                />

                {dateInvalid && (
                  <ErrorMessage
                    content="Date range must be two months or less"
                    data-testid="datepicker-error"
                  />
                )}
              </Form.Field>
            )}
            {!hide.organization && hasOrganizationDropdown && (
              <OrganizationFilter
                options={
                  showManagerHierarchyOrgDropdown
                    ? hierarchyOrganizationOptions
                    : organizationOptions
                }
                value={selectedOrgId}
                onChange={handleOrgSelect}
              />
            )}
            {!hide.agents && (
              <MultiFilter
                isSelectAll
                label="Agents"
                accessor="agents"
                store={{ data, filters, loading }}
                onChange={(option) => handleUpdateFilters('agents', option)}
              />
            )}
            {!hide.tags && (
              <MultiFilter
                isSelectAll
                label="Tags"
                accessor="tags"
                store={{ data, filters, loading }}
                onChange={(option) => handleUpdateFilters('tags', option)}
              />
            )}
            {!hide.isWin && (
              <WinsFilter
                filters={filters}
                onChange={(option, action) => {
                  dispatch(setFilter('isWin', action.action === 'clear' ? null : option.value))
                }}
              />
            )}
            {!hide.scorecards &&
              (!multiScorecard ? (
                <Form.Field data-testid="scorecard-config-field">
                  <label id="scorecards">Scorecards</label>
                  <Select
                    placeholder="Select Scorecard"
                    options={scorecardOptions}
                    aria-labelledby="scorecards"
                    loading={loading.scorecards}
                    isClearable={false}
                    value={first(selectedScorecards)?.id}
                    onChange={(value) => handleScorecardConfigSelect(value)}
                    fixedWidth
                  />
                </Form.Field>
              ) : (
                <Form.Field data-testid="scorecard-config-field">
                  <label id="scorecards">Scorecards</label>
                  <MultiSelect
                    isSelectAll
                    placeholderPill="All Scorecards"
                    options={scorecardOptions}
                    aria-labelledby="scorecards"
                    loading={loading.scorecards}
                    value={getSelectedScorecardFilters()}
                    onChange={(value) => handleMultiScorecardConfigSelect(value)}
                    fixedWidth
                  />
                </Form.Field>
              ))}
            {!hide.dispositions && !isEmpty(data.dispositions) && (
              <MultiFilter
                label="Dispositions"
                accessor="dispositions"
                store={{ data, filters, loading }}
                onChange={(option) => handleUpdateFilters('dispositions', option)}
              />
            )}
            {!hide.sections && (
              <Form.Field
                className={emptySections ? 'score-filter-disable-form-field' : ''}
                data-testid={
                  emptySections
                    ? 'sections-field-score-filter-disable-form-field'
                    : 'sections-field'
                }
                disabled={emptySections || selectedScorecards.length > 1}
              >
                <label className="score-filter-disable-form-label" id="sections">
                  Sections
                </label>
                <div data-testid="section-picker">
                  <Popup
                    inverted
                    content="One scorecard must be selected in order to view the section"
                    position="top center"
                    disabled={!emptySections}
                    trigger={
                      <div>
                        <MultiSelect
                          isSelectAll
                          placeholderPill="All Sections"
                          placeholder={sectionPlaceHolderText}
                          options={sectionOptions}
                          allowSelectAll
                          aria-labelledby="sections"
                          loading={loading.scorecards}
                          value={filters?.sections}
                          onChange={(value) => handleSectionsSelect(value)}
                          disabled={emptySections || selectedScorecards.length > 1}
                          fixedWidth
                        />
                      </div>
                    }
                  />
                </div>
              </Form.Field>
            )}
            {!hide.measures && (
              <Form.Field
                className={emptyMeasures ? 'score-filter-disable-form-field' : ''}
                data-testid={
                  emptyMeasures
                    ? 'measures-field-score-filter-disable-form-field'
                    : 'measures-field'
                }
                disabled={emptyMeasures}
              >
                <label className="score-filter-disable-form-label" id="measures">
                  Measures
                </label>
                <div data-testid="measure-picker">
                  <Popup
                    inverted
                    content={`One section must be selected in order to view the ${
                      !isQACopilot ? 'measure' : 'criteria'
                    }`}
                    position="top center"
                    disabled={!emptyMeasures}
                    trigger={
                      <div>
                        <MultiSelect
                          isSelectAll
                          placeholder={measurePlaceHolderText}
                          options={measureOptions}
                          allowSelectAll
                          aria-labelledby="measures"
                          loading={loading.scorecards}
                          value={filters?.measures}
                          onChange={(value) => handleMeasuresSelect(value)}
                          disabled={emptyMeasures}
                          fixedWidth
                        />
                      </div>
                    }
                  />
                </div>
              </Form.Field>
            )}
            {!hide.criteria && (
              <Form.Field
                className={emptyCriteria ? 'score-filter-disable-form-field' : ''}
                data-testid={
                  emptyCriteria
                    ? 'criteria-field-score-filter-disable-form-field'
                    : 'criteria-field'
                }
                disabled={emptyCriteria}
              >
                <label className="score-filter-disable-form-label" id="criteria">
                  Criteria
                </label>
                <Popup
                  inverted
                  content={`One ${
                    !isQACopilot ? 'measure' : 'section'
                  } must be selected in order to view criteria`}
                  position="top center"
                  disabled={!emptyCriteria}
                  trigger={
                    <div>
                      <MultiSelect
                        isSelectAll
                        placeholder={criteriaPlaceHolderText}
                        options={criteriaOptions}
                        disabled={emptyCriteria}
                        aria-labelledby="criteria"
                        loading={loading.scorecards}
                        value={filters?.criteria}
                        onChange={(value) => handleCriteriaSelect(value)}
                        fixedWidth
                      />
                    </div>
                  }
                />
              </Form.Field>
            )}
          </div>
        </div>
        {!hideFilterButton && (
          <div className="filter-buttons">
            <Form.Field>
              <label className="visibility-hidden">&nbsp;</label>
              <div>
                <Button
                  primary
                  type="submit"
                  data-testid="filter-button"
                  loading={loading.scores}
                  disabled={filterIsDisabled()}
                >
                  Filter
                </Button>
              </div>
            </Form.Field>
          </div>
        )}
      </Form>
    </div>
  )
}
