import React from 'react'
import moment from 'moment'
import { Button, Popup } from 'semantic-ui-react'
import { toNumber } from 'lodash'
import { IconSparkles } from '@tabler/icons-react'
import pluralize from 'pluralize'

import { Pill } from '@/components/pills/Pill'
import { TASK_STATUS_MAP } from '@/reducers/tasks/tasks.constants'
import { determineGradient } from '@/components/helpers'

import './TaskCard.scss'

export const TaskCard = ({
  id,
  title,
  status,
  isAiGenerated,
  agent,
  description,
  callCount,
  createdDate,
  updatedDate,
  handlePrimaryAction,
  primaryActionButtonText,
  handleSecondaryAction,
  secondaryActionButtonText,
  index,
}) => {
  const created = moment.utc(createdDate).local()
  const updated = moment.utc(updatedDate).local()
  const now = moment()
  const hasBeenUpdated = !created.isSame(updated)
  const dateToUse = hasBeenUpdated ? updated : created
  const displayDate =
    now.diff(dateToUse, 'hours') < 24
      ? dateToUse.fromNow()
      : dateToUse.format('MMMM Do[,] YYYY [at] h:mma')
  const dataTestId = `task-card-${id}`

  return (
    <div className="task-card" data-testid={dataTestId} onClick={handlePrimaryAction}>
      <header>
        <h2 data-testid={`${dataTestId}-title`}>
          {isAiGenerated && <IconSparkles className="status-magical icon-svg-large" />}
          {title}
        </h2>
      </header>
      {description && (
        <section>
          <p data-testid={`${dataTestId}-description`}>{description}</p>
        </section>
      )}
      <footer>
        <div className="task-card__metadata">
          <div>
            <Popup
              position="top left"
              inverted
              content={dateToUse.format('MMMM Do YYYY, h:mm:ss a')}
              trigger={
                <small>
                  {hasBeenUpdated ? 'Last updated' : 'Created'} {displayDate}
                </small>
              }
            />
          </div>
          <div className="task-card__metadata--divider" />
          <Pill xs className="uppercase" gradient={determineGradient(toNumber(index || 0))}>
            {agent}
          </Pill>
          <div className="task-card__metadata--divider" />
          <Pill xs className="uppercase">
            {pluralize('related calls', callCount, true)}
          </Pill>
          <div className="task-card__metadata--divider" />
          <Pill
            xs
            success={status === 'completed'}
            emphasized={status === 'ready_for_review'}
            magical={status === 'collecting_evidence'}
            className="uppercase"
          >
            {TASK_STATUS_MAP[status]}
          </Pill>
        </div>
        <div className="flex-align-center flex-end small-gap" style={{ marginLeft: 'auto' }}>
          {handleSecondaryAction && (
            <Button
              className="no-wrap"
              data-testid="task-secondary-button"
              type="button"
              basic
              onClick={(event) => {
                event.stopPropagation()
                handleSecondaryAction(event)
              }}
            >
              {secondaryActionButtonText}
            </Button>
          )}
          <Button type="button" className="no-wrap" secondary data-testid="drawer-trigger-button">
            {primaryActionButtonText}
          </Button>
        </div>
      </footer>
    </div>
  )
}
