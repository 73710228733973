import React from 'react'
import { Loader } from 'semantic-ui-react'
import {
  IconCircleMinus,
  IconDots,
  IconXboxXFilled,
  IconCircleCheckFilled,
} from '@tabler/icons-react'

export const columns = [
  {
    label: 'Matching Content',
    accessor: 'content',
    format: (value) => (
      <div style={{ maxWidth: '400px', whiteSpace: 'initial' }}>
        <span>{value}</span>
      </div>
    ),
  },
  {
    label: 'Playbook',
    accessor: 'config_name',
    format: (value, row) => (
      <div
        style={{
          maxWidth: '200px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <a href={`/playbooks/${row.config_id}/checklist`} target="_blank" rel="noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    label: '',
    accessor: 'progress',
    format: (value) => {
      switch (value) {
        case 'initial':
          return <IconDots className="extra-muted-text" />
        case 'ignore':
          return <IconCircleMinus className="extra-muted-text" />
        case 'inProgress':
          return <Loader active inline size="small" />
        case 'failed':
          return <IconXboxXFilled className="status-critical" />
        case 'done':
          return <IconCircleCheckFilled className="status-success" />
        default:
          return <IconDots />
      }
    },
  },
]
