import React from 'react'
import { IconCheck } from '@tabler/icons-react'
import { Button } from 'semantic-ui-react'

import './FilterDropdownOptions.scss'

export const FilterDropdownOptions = ({ optionSets = [], handleChange, isClearable }) => {
  return (
    <div className="filter-dropdown-options">
      {optionSets.map((optionSet) => (
        <div className="filter-dropdown-options__section" key={optionSet.accessor}>
          {optionSet.options.map((option) => (
            <Button
              basic
              className="dropdown-action-button"
              key={option.value}
              onClick={() => {
                if (isClearable && option.value === optionSet.selectedValue) {
                  handleChange(optionSet.accessor, '')
                } else {
                  handleChange(optionSet.accessor, option.value)
                }
              }}
            >
              {option.label}
              {option.value === optionSet.selectedValue && (
                <IconCheck className="icon-svg status-brand" />
              )}
            </Button>
          ))}
        </div>
      ))}
    </div>
  )
}
