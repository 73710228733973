import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { kebabCase } from 'lodash'
import pluralize from 'pluralize'
import { toast } from 'react-toastify'
import { IconTrash, IconAlertTriangle, IconPlus, IconPencil } from '@tabler/icons-react'
import classNames from 'classnames'

import { fetchPlaybookSectionWarnings } from '@/reducers/playbooks/playbook.actions'
import { deleteEntry, updateCategoryName } from '@/reducers/playbooks/playbook.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { Banner } from '@/components/banners/Banner'

import { accessorSectionMap } from '../../playbook.helpers'

export const CategoryHeader = ({
  categoryId,
  category,
  readOnly,
  nestedEntriesCount,
  validationError,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { section, id } = useParams()
  const [editing, setEditing] = useState(false)
  const accessor = accessorSectionMap[section]
  const hasValidationError =
    validationError.accessor === accessor && validationError?.entry === categoryId

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
    dispatch(updateCategoryName(accessor)({ name: values.name, categoryId }))
    setEditing(false)
  }

  const handleCreate = () => {
    history.push(`/playbooks/${id}/${section}/create?category_id=${categoryId}`)
  }

  const handleDelete = (value) => {
    dispatch(deleteEntry(accessor)(value))
    toast.success('Category and entries have been deleted')
    dispatch(fetchPlaybookSectionWarnings(accessor))
  }

  return (
    <>
      <div className="playbook-category" id={kebabCase(category.name)}>
        <div className="playbook-category-draggable">
          {!editing ? (
            <>
              <h2
                className={classNames('playbook-category-name', {
                  'status-critical': hasValidationError,
                })}
              >
                {category.name}
              </h2>
              {!readOnly && (
                <Button
                  icon
                  basic
                  className="svg-button hidden-outer"
                  onClick={() => setEditing(!editing)}
                  data-testid="edit-playbook-name"
                >
                  <IconPencil />
                </Button>
              )}
            </>
          ) : (
            <AbstractForm
              horizontal
              onSubmit={handleSubmit}
              existingValues={{ name: category.name }}
              schema={[{ name: 'name', type: 'text', required: true, autoFocus: true }]}
              buttonLabel="Update"
              closeButtonLabel="Cancel"
              onClose={() => setEditing(false)}
            />
          )}
        </div>
        {!editing && !readOnly && (
          <div className="flex flex-align-center gap" style={{ justifyContent: 'flex-end' }}>
            <ButtonAndFormModal
              icon={<IconTrash />}
              modalTitle="Delete Category"
              modalId={`deleteCategory/${categoryId}`}
              modalProps={{ size: 'tiny' }}
              buttonProps={{
                basic: true,
                classNames: 'hidden-outer',
              }}
              form={
                <ConfirmForm
                  bodyText={`Are you sure you want to delete the category "${
                    category.name
                  }"? ${pluralize('entry', category.order.length, true)} will also be deleted.`}
                  danger
                  primaryButtonText="Delete Category"
                  handleSubmit={() => handleDelete(categoryId)}
                />
              }
            />

            <Button
              data-testid="add-entry-item-button"
              secondary
              icon
              onClick={handleCreate}
              disabled={readOnly}
              className="svg-button"
              style={{ marginRight: '40px' }}
            >
              <IconPlus />
              <span>Add Item</span>
            </Button>
          </div>
        )}
      </div>
      {nestedEntriesCount < 1 && (
        <Banner
          header="Warning"
          warning={!hasValidationError}
          critical={hasValidationError}
          icon={<IconAlertTriangle />}
        >
          At least one category entry is required
        </Banner>
      )}
    </>
  )
}
