import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import { Field, Formik } from 'formik'
import { IconFlag, IconFlag3 } from '@tabler/icons-react'
import { useDispatch } from 'react-redux'
import { startCase, isEmpty } from 'lodash'
import * as Yup from 'yup'
import classNames from 'classnames'

import { closeModal } from '@/reducers/ui/ui.redux'
import { createManualTask } from '@/reducers/tasks/tasks.actions'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { TextArea, TextField } from '@/components/forms/formik'

const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    reason: Yup.string().required('Reason is required'),
    // minutes: Yup.number()
    //   .min(0, 'Enter a valid number')
    //   .max(maxMinutes, `Can not be greater than length of call`),
    // seconds: Yup.number()
    //   .min(0, 'Enter a valid number')
    //   .max(60, 'Seconds cannot be greater than 60')
    //   .when('minutes', {
    //     is: (val) => val >= maxMinutes,
    //     then: Yup.number().max(maxSeconds, `Can not be greater than length of call`),
    //   }),
  })

export const ManualTaskModal = ({
  callId,
  userId,
  organizationId,
  type,
  maxMinutes,
  maxSeconds,
}) => {
  const dispatch = useDispatch()
  // NOTE: If this modal needs to be expanded for a third manual type, PLEASE pass these variables in
  // as props instead of adding more conditionals here. Two is fine, three is not.
  const defaultName =
    type === 'compliance'
      ? `Compliance Task for Call: ${callId}`
      : `Coaching Task for Call: ${callId}`
  const title = type === 'compliance' ? 'Flag for Compliance' : 'Flag for Coaching'
  const placeholder =
    type === 'compliance'
      ? `What was the compliance issue?`
      : `What does this agent need coaching on?`
  const buttonLabel = type === 'compliance' ? 'Send to Compliance Inbox' : 'Send to Coaching Inbox'

  const saveEscalation = ({ name, reason }) => {
    // let timestamp

    // if (minutes || seconds) {
    //   timestamp = 0
    // }
    // if (minutes) {
    //   timestamp += minutes * 60
    // }
    // if (seconds) {
    //   timestamp += seconds
    // }

    const timestamp = 0

    dispatch(createManualTask(callId, userId, organizationId, name, reason, timestamp, type))
    dispatch(closeModal())
  }

  return (
    <ButtonAndFormModal
      icon={
        <IconFlag
          className={classNames({
            'status-critical': type === 'compliance',
            'status-success': type === 'coaching',
          })}
        />
      }
      buttonProps={{ compact: true, secondary: true }}
      data-testid={`manual-task/${type}`}
      buttonLabel={startCase(type)}
      modalTitle={
        <div className="flex-align-center medium-gap">
          <IconFlag3
            className={classNames({
              'status-critical': type === 'compliance',
              'status-success': type === 'coaching',
            })}
          />
          <span>{title}</span>
        </div>
      }
      modalId={`manual-task/${type}`}
      form={
        <Formik
          validateOnMount
          initialValues={{
            reason: '',
            name: defaultName,
            // minutes: 0,
            // seconds: 0,
          }}
          validationSchema={getValidationSchema(maxMinutes, maxSeconds)}
          onSubmit={saveEscalation}
        >
          {({ handleSubmit, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field name="name" component={TextField} placeholder="Title" label="Task Title" />
                <Field
                  name="reason"
                  component={TextArea}
                  placeholder={placeholder}
                  label="Reason"
                />

                {/* <div className="flex gap mb">
                  <div className="label-style">Start at:</div>
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="minutes"
                    component={NumberField}
                    label="Minutes"
                    min="0"
                  />
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="seconds"
                    component={NumberField}
                    label="Seconds"
                    min="0"
                  />
                </div> */}
                <div className="modal-footer">
                  <Button
                    basic
                    data-testid="cancel-manual-task"
                    onClick={() => {
                      dispatch(closeModal())
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="save-manual-task"
                    primary
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isEmpty(errors)}
                  >
                    {buttonLabel}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
    />
  )
}
