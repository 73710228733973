import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { openModal, closeModal } from '../../../reducers/ui/ui.redux'
import { BasicModal } from './BasicModal'

const ButtonWrapper = ({ popup, modalTitle, children, ...props }) => {
  return popup ? (
    <Popup on="hover" content={modalTitle} inverted trigger={children} {...props} />
  ) : (
    children
  )
}

export const ButtonAndFormModal = ({
  modalId,
  modalTitle,
  modalContent = null,
  modalProps = {},
  buttonLabel,
  buttonProps = {},
  popup = false,
  popupProps = {},
  form,
  icon,
  disabled,
  showButton = true,
}) => {
  const dispatch = useDispatch()
  const { currentlyOpenModalId } = useSelector((state) => state.ui)
  const { classNames: buttonClassNames, ...updatedButtonProps } = buttonProps

  const handleOpen = () => dispatch(openModal(modalId))
  const handleClose = () => dispatch(closeModal())

  return (
    <>
      {showButton && (
        <ButtonWrapper popup={popup} modalTitle={modalTitle} {...popupProps}>
          <Button
            icon={!!icon}
            type="button"
            data-testid={`${modalId}-button`}
            disabled={disabled}
            onClick={handleOpen}
            className={classNames('no-wrap', buttonClassNames, {
              'svg-button': !!icon,
            })}
            {...updatedButtonProps}
          >
            {icon}
            {buttonLabel}
          </Button>
        </ButtonWrapper>
      )}

      {currentlyOpenModalId === modalId && (
        <BasicModal
          data-testid={`${modalId}-modal`}
          title={modalTitle}
          onClose={() => dispatch(closeModal())}
          show={currentlyOpenModalId === modalId}
          size="small"
          {...modalProps}
        >
          {modalContent}
          {form && React.cloneElement(form, { onClose: handleClose, modalClose: handleClose })}
        </BasicModal>
      )}
    </>
  )
}
