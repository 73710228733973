import produce from 'immer'

import { formatTime } from '@/utils/helpers'

const startOf30DaysAgo = formatTime(
  new Date(new Date().setDate(new Date().getDate() - 30)),
  'yyyy-MM-DDT00:00:00Z'
)
const endOfToday = formatTime(new Date(), 'yyyy-MM-DDT23:59:59Z')

// Action Types
export const SET_LOADING = 'qaCopilot/setLoading'
export const SET_ERROR = 'qaCopilot/setError'
export const SET_SCORECARD_CONFIG = 'qaCopilot/setScorecardConfig'
export const EDIT_SCORECARD_CONFIG = 'qaCopilot/editScorecardConfig'
export const SET_TEMPORARY_CRITERIA = 'qaCopilot/setTemporaryCriteria'
export const SET_DATA = 'qaCopilot/setData'
export const SET_FILTER = 'qaCopilot/setFilter'
export const SET_ORGANIZATION = 'qaCopilot/setOrganization'
export const UPDATE_SETTINGS = 'qaCopilot/updateSettings'
export const SET_FOCUS_AREA = 'qaCopilot/setFocusArea'
export const CLEAR_FILTERS = 'qaCopilot/clearFilters'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setError = (payload) => ({ type: SET_ERROR, payload })
export const setScorecardConfig = (payload) => ({ type: SET_SCORECARD_CONFIG, payload })
export const editScorecardConfig = (payload) => ({ type: EDIT_SCORECARD_CONFIG, payload })
export const setTemporaryCriteria = (payload) => ({ type: SET_TEMPORARY_CRITERIA, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setOrganization = (payload) => ({ type: SET_ORGANIZATION, payload })
export const updateSettings = (payload) => ({ type: UPDATE_SETTINGS, payload })
export const setFocusArea = (payload) => ({ type: SET_FOCUS_AREA, payload })
export const clearFilters = () => ({ type: CLEAR_FILTERS })

// State
export const initialState = {
  filters: {
    organizationId: null,
    scorecards: [],
    agents: [],
    tags: [],
    playbooks: [],
    dispositions: [], // filters set here, but data set in organziations reducer
    isWin: null,
    scoredStatus: null,
    startDate: startOf30DaysAgo,
    endDate: endOfToday,
    scorecard_type: 'copilot',
  },
  data: {
    organizations: [],
    scorecards: [],
    agents: [],
    tags: [],
    playbooks: [],
    nonCompliantCriteria: [],
    nonCompliantAgents: [],
    failedCallData: null,
    evaluatedCallData: null,
    coachingOpportunities: [],
    topInfractors: [],
    reporting: {
      aggregate_scores: { total: 0 },
      scorecard_counts: { total: 0 },
      call_counts: { total: 0 },
      filters: {},
      scorecard_configs: [],
    },
    reportingByDay: { aggregates: [], calls: [] },
    reportingByUser: {
      aggregate_scores_by_user: [],
      scorecard_counts: null,
      call_counts: null,
      filters: {},
    },
    enterpriseScorecardPerformance: [],
    enterpriseOrganizationPerformance: {},
    enterpriseEvaluatedCallData: {},
    enterpriseFailedCallData: {},
    enterpriseOverallAverageScore: null,
    enterpriseScoresPerOrganization: [],
  },
  scorecardConfig: null,
  temporaryCriteria: null,
  loading: {
    scorecards: false,
    agents: false,
    tags: false,
    playbooks: false,
    scorecardConfig: false,
    copilotTestPrompt: false,
    nonCompliantCriteria: false,
    nonCompliantAgents: false,
    failedCallData: false,
    evaluatedCallData: false,
    coachingOpportunities: false,
    topInfractors: false,
    enterpriseScorecardPerformance: false,
    enterpriseOrganizationPerformance: false,
    enterpriseEvaluatedCallData: false,
    enterpriseFailedCallData: false,
    enterpriseOverallAverageScore: false,
    enterpriseScoresPerOrganization: false,
  },
  error: {
    scorecardConfig: false,
  },
  dirty: false,
  focusArea: '',
}

// Reducer
export default function qaCopilotReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_ERROR:
        newState.error = { ...state.error, ...payload }
        break
      case SET_SCORECARD_CONFIG:
        newState.scorecardConfig = payload
        newState.dirty = false
        newState.error.scorecardConfig = false
        break
      case EDIT_SCORECARD_CONFIG:
        newState.scorecardConfig = payload
        newState.dirty = true
        break
      case UPDATE_SETTINGS:
        newState.scorecardConfig = { ...state.scorecardConfig, ...payload }
        newState.dirty = true
        break
      case SET_TEMPORARY_CRITERIA:
        newState.temporaryCriteria = payload
        newState.dirty = true
        break
      case SET_DATA:
        newState.data = { ...state.data, ...payload }
        break
      case SET_FILTER:
        newState.filters = { ...state.filters, ...payload }
        break
      case CLEAR_FILTERS:
        newState.filters = {
          ...initialState.filters,
          organizationId: state.filters.organizationId,
        }
        break
      case SET_ORGANIZATION:
        newState.filters = {
          ...state.filters,
          organizationId: action.payload || null, // also serves as a reset
          scorecards: [],
          agents: [],
          tags: [],
          playbooks: [],
          dispositions: [],
        }
        newState.data = {
          ...state.data,
          scorecards: [],
          agents: [],
          tags: [],
          playbooks: [],
          dispositions: [],
        }
        break
      case SET_FOCUS_AREA:
        newState.focusArea = payload
        break
      default:
        break
    }
  })
}
