import React from 'react'
import { IconClipboardList, IconListCheck, IconScale } from '@tabler/icons-react'

export const COACHING_TASK_TYPE = 'coaching'
export const COMPLIANCE_TASK_TYPE = 'compliance'
export const QA_DISPUTE_TASK_TYPE = 'qa_dispute'
export const QA_MANUAL_SCORE_TASK_TYPE = 'qa_manual_score'

export const READY_FOR_REVIEW = 'ready_for_review'
export const COMPLETED = 'completed'
export const EXPIRED = 'expired'
export const IGNORED = 'ignored'

export const INBOX = 'inbox'
export const COACHING_INBOX = [COACHING_TASK_TYPE]
export const COMPLIANCE_INBOX = [COMPLIANCE_TASK_TYPE]
export const QA_INBOX = [QA_DISPUTE_TASK_TYPE, QA_MANUAL_SCORE_TASK_TYPE]

export const TASK_STATUS_MAP = {
  [READY_FOR_REVIEW]: 'Ready for Review',
  [COMPLETED]: 'Reviewed',
  [EXPIRED]: 'Expired',
  [IGNORED]: 'Dismissed',
}

export const ONBOARDING = {
  [COACHING_INBOX]: {
    icon: <IconClipboardList />,
    title: 'Streamline Coaching with AI-Powered Sessions',
    description:
      'Balto’s AI automatically finds and gathers relevant calls based on the areas you want to focus on—giving you key insights and coaching recommendations to help your agents grow.',
  },
  [COMPLIANCE_INBOX]: {
    icon: <IconScale />,
    title: 'Stay Compliant with AI-Generated Exception Tasks',
    description:
      'Copilot helps you maintain compliance by automatically generating exception tasks based on your company’s policies—so you can review and take action with ease.',
  },
  [QA_INBOX]: {
    icon: <IconListCheck />,
    title: 'Streamline QA with Smarter Workflows',
    description:
      'Copilot makes QA faster by pulling key insights from calls into actionable tasks, so you can review, assess, and resolve quality issues efficiently.',
  },
}

export const COACHING_QUESTIONS = {
  sales: [
    'Did the agent clearly and concisely articulate the value proposition of their solution?',
    "Did the agent clearly attempt to earn the customer's business?",
    "Did the agent uncover the customer's concerns?",
  ],
  customer_service: [
    "Did the agent reduce the customer's frustration?",
    'Did the agent express empathy for any difficulties the customer is encountering?',
    "Did the agent ask follow-up questions to better understand the customer's issue?",
  ],
  collections: [
    'Did the collector ask for payment in full?',
    "Did the collector ask questions to understand the consumers' income streams and where they might have funds available to pay their balance?",
    'Did the collector recap the arrangement for the consumer at the end of the interaction?',
  ],
}
