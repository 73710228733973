import queryString from 'query-string'
import { toast } from 'react-toastify'
import uuid from 'uuid/v4'

import { fetchingAPI, apiService } from '@/api'
import { publishInsightEvents } from '@/reducers/insights/insightEvents.actions'
import {
  checklistUpdateProgress,
  massPlaybookUpdateProgressStatus,
} from '@/views/Playbooks/playbook.helpers'
import { convertToSeconds } from '@/views/Playbooks/modules/checklist/ChecklistForm'
import { setCurrent } from './playbook.redux'

import * as actions from './playbooks.redux'
import { createPlaybookVersion, publishPlaybookVersion } from './playbook.actions'

export const fetchPlaybooksHelper = async (dispatch, organizationId, getBody, isSilent) => {
  const bodyProp = getBody ? ',body' : ''
  const requestedProperties = `name,cid,organization_id,organization_name,config_created,language${bodyProp}`
  const requestedPropertiesQueryString = `?${queryString.stringify({
    active_only: true,
    requested_properties: requestedProperties,
  })}`
  const orgFilter = organizationId ? `${organizationId}/` : ''
  const url = `${apiService.web}/api/${orgFilter}configs${requestedPropertiesQueryString}`
  const playbooks = await fetchingAPI(url, 'GET', dispatch, undefined, undefined, { isSilent })

  dispatch(actions.setPlaybooks(playbooks))
}

export const fetchPlaybooks = (organizationId, getBody, isSilent) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      await fetchPlaybooksHelper(dispatch, organizationId, getBody, isSilent)
    } catch (err) {
      dispatch(actions.setMassPlaybookUpdateMatches([]))
      dispatch(actions.setMassPlaybookFetchFailed())
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const updatePlaybookOrganization = (organizationId, cid) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      await fetchingAPI(
        `${apiService.web}/api/configs/${cid}/versions`,
        'PATCH',
        dispatch,
        JSON.stringify({ organization_id: organizationId })
      )
      await fetchPlaybooksHelper(dispatch)
      toast.success('Playbook organization updated')
    } catch (err) {
      toast.error('Failed to update playbook organization')
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const createPlaybook = (playbook, initialCid, insightEvents) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))

    try {
      const body = {
        name: playbook.name,
        cid: uuid(),
        began_editing: playbook.began_editing,
        organization_id: playbook.organization_id,
        startingConfigCid: initialCid,
      }

      const newPlaybook = await fetchingAPI(
        `${apiService.web}/api/configs`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )
      dispatch(publishInsightEvents(insightEvents.events, newPlaybook.id))

      await fetchPlaybooksHelper(dispatch)
      toast.success('New playbook created')
    } catch (err) {
      toast.error('Failed to create playbook')
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const checkPlaybookForUsers = (
  playbookCid,
  setUsersPreventingDelete,
  setLoading,
  setFetched
) => {
  return async (dispatch) => {
    setLoading(true)

    try {
      const response = await fetchingAPI(
        `${apiService.web}/api/configs/${playbookCid}/users`,
        'GET',
        dispatch
      )

      setUsersPreventingDelete(response)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      setFetched(true)
    }
  }
}

export const deletePlaybook = (cid) => async (dispatch) => {
  dispatch(actions.setLoading(true))

  try {
    await fetchingAPI(`${apiService.web}/api/configs/${cid}/versions`, 'DELETE', dispatch)
    await fetchPlaybooksHelper(dispatch)
    toast.success('Playbook deleted')
  } catch (err) {
    toast.error('Failed to delete playbook')
  } finally {
    dispatch(actions.setLoading(false))
  }
}

export const reassignUserPlaybooks =
  (userIds, cid, setSubmitting, setUsersPreventingDelete) => async (dispatch) => {
    setSubmitting(true)

    try {
      await fetchingAPI(
        `${apiService.web}/api/users/batch`,
        'PATCH',
        dispatch,
        JSON.stringify({ user_ids: userIds, config_cid: cid })
      )
      toast.success('Playbooks reassigned')
      setUsersPreventingDelete([])
    } catch (err) {
      toast.error('Failed to reassign playbook')
    } finally {
      setSubmitting(false)
    }
  }

export const updatePlaybookLanguage = (language, cid) => async (dispatch) => {
  dispatch(actions.setLoading(true))

  try {
    await fetchingAPI(
      `${apiService.web}/api/configs/${cid}/versions`,
      'PATCH',
      dispatch,
      JSON.stringify({ language })
    )
    await fetchPlaybooksHelper(dispatch)
    toast.success('Playbook language updated')
  } catch (err) {
    toast.error('Failed to update playbook language')
  } finally {
    dispatch(actions.setLoading(false))
  }
}

export const getPlaybookPhraseSuggestions = (payload, currentEntity, isSilent) => {
  return async (dispatch) => {
    dispatch(actions.setLoading(true))
    dispatch(actions.setMassPlaybookUpdateChecklistSelection([]))
    dispatch(actions.setMassPlaybookUpdateFetchedEntity(currentEntity))

    try {
      const phaseSuggestions = await fetchingAPI(
        `${apiService.web}/api/configs/playbook_phrase_suggestions`,
        'POST',
        dispatch,
        JSON.stringify(payload),
        undefined,
        { isSilent }
      )
      dispatch(
        actions.setMassPlaybookUpdateMatches(
          phaseSuggestions?.data?.ndarray.map((item) => ({
            ...item,
            compoundId: `${item.config_id}_${item.content_id}`,
            progress: checklistUpdateProgress.INITIAL,
          }))
        )
      )
    } catch (err) {
      dispatch(actions.setMassPlaybookUpdateMatches([]))
      dispatch(actions.setMassPlaybookFetchFailed())
    } finally {
      dispatch(actions.setLoading(false))
    }
  }
}

export const submitMassPlaybookUpdate = (
  playbooks,
  matches,
  selection,
  config_id,
  content_id,
  values,
  setShowConfirmPublish
) => {
  return async (dispatch) => {
    let matchesUpdates = [...matches]

    try {
      await dispatch(
        actions.setMassPlaybookUpdateProgress(massPlaybookUpdateProgressStatus.IN_PROGRESS)
      )
      setShowConfirmPublish(false)

      // Matches checklist table progress update
      matchesUpdates = matchesUpdates.map((item) => ({
        ...item,
        progress: selection.includes(item.compoundId)
          ? checklistUpdateProgress.IN_PROGRESS
          : checklistUpdateProgress.IGNORE,
      }))
      await dispatch(actions.setMassPlaybookUpdateMatches(matchesUpdates))

      const selectionSelected = selection.map((item) => {
        const [configId, contentId] = item.split('_')
        return {
          config_id: configId,
          content_id: contentId,
        }
      })

      // Omit `required_before_time_unit` and convert `required_before` to seconds
      const { required_before_time_unit, ...trigger } = values.trigger
      const required_before = convertToSeconds(values)
      const newEntry = {
        ...values,
        trigger: {
          ...trigger,
          required_before,
        },
      }

      const selectionExtended = [{ config_id, content_id }, ...selectionSelected]

      for (const checklist of selectionExtended) {
        const playbook = playbooks.find((p) => p.id.toString() === checklist.config_id)
        const { entries } = playbook.body.checklist
        const newEntries = {
          ...entries,
          [checklist.content_id]: newEntry,
        }
        playbook.body.checklist.entries = newEntries

        // Call APIs: Save Draft & Publish
        const newPlaybookId = await dispatch(createPlaybookVersion(playbook))
        if (newPlaybookId) {
          await dispatch(publishPlaybookVersion(newPlaybookId, false))

          if (checklist.config_id === config_id && checklist.content_id === content_id) {
            // For current item, update new playbook version & set current to false - to disable editing
            await dispatch(actions.setMassPlaybookUpdateNewPlaybookVersion(newPlaybookId))
            await dispatch(setCurrent(false))
          } else {
            // For other items, update progress to DONE
            const compoundId = `${checklist.config_id}_${checklist.content_id}`

            // Matches checklist table progress update
            matchesUpdates = matchesUpdates.map((item) => ({
              ...item,
              config_id: item.config_id === checklist.config_id ? newPlaybookId : item.config_id,
              progress:
                item.compoundId === compoundId ? checklistUpdateProgress.DONE : item.progress,
            }))
            await dispatch(actions.setMassPlaybookUpdateMatches(matchesUpdates))
          }
        }
      }

      toast.success('The selected playbooks have been successfully updated')
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      dispatch(actions.setMassPlaybookUpdateProgress(massPlaybookUpdateProgressStatus.DONE))
    }
  }
}
