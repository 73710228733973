import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Checkbox, Loader } from 'semantic-ui-react'
import { IconAlertCircle } from '@tabler/icons-react'
import { isEmpty } from 'lodash'
import {
  fetchPlaybooks,
  getPlaybookPhraseSuggestions,
} from '@/reducers/playbooks/playbooks.actions'
import {
  massPlaybookUpdateReset,
  setIsMassPlaybookUpdateOnOff,
  setMassPlaybookUpdateChecklistSelection,
} from '@/reducers/playbooks/playbooks.redux'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Banner } from '@/components/banners/Banner'
import { massPlaybookUpdateProgressStatus } from '@/views/Playbooks/playbook.helpers'
import { CheckboxWrap } from './ChecklistForm'
import { columns } from './ChecklistBulkEditColumns'

export const MassPlaybookUpdateWarningBanner = ({ isConfirmation = false }) => (
  <Banner
    caution
    className="mb"
    header="Proceed with Caution"
    icon={<IconAlertCircle />}
    data-testid="mass-playbook-edit-banner"
  >
    <div className="mbp">
      This experimental feature publishes updates to multiple Playbooks at once.
    </div>
    <div className="mbp">
      {`Once published, the save as draft step will be skipped, and these text changes and other
      settings will apply to all selected Playbooks${isConfirmation ? '' : ' listed below'}.`}
    </div>
  </Banner>
)

const ChecklistBulkEdit = ({ readOnly }) => {
  const dispatch = useDispatch()
  const { id: config_id, entryId } = useParams()

  const { playbook } = useSelector((state) => state.playbook)
  const { loading, massPlaybookUpdate, playbooks } = useSelector((state) => state.playbooks)
  const { isEnabled, progress, matches, fetchedEntity, fetchFailed, selection } =
    massPlaybookUpdate || {}

  const setIsMassPlaybookUpdateOn = (value) => dispatch(setIsMassPlaybookUpdateOnOff(value))

  const currentEntity = `${config_id}_${entryId}`

  const fetchPlaybookPhraseSuggestions = () => {
    const configs = {}
    playbooks
      .filter((p) => {
        return !isEmpty(p.body)
      })
      .forEach((p) => {
        configs[p.id] = {
          name: p.name,
          body: {
            checklist: p.body?.checklist,
          },
        }
      })
    // If editing a draft, include the current playbook in the list of playbooks to compare
    if (!playbook.active) {
      configs[playbook.id] = {
        name: playbook.name,
        body: {
          checklist: playbook.body?.checklist,
        },
      }
    }
    const target_item = { config_id, uuid: entryId }
    const payload = { data: { ndarray: [{ configs, target_item }] } }
    dispatch(getPlaybookPhraseSuggestions(payload, currentEntity, true))
  }

  useEffect(() => {
    dispatch(massPlaybookUpdateReset())
    return () => {
      dispatch(massPlaybookUpdateReset())
    }
  }, [])

  // Preload all organization playbooks (for Mass Playbook Update)
  useEffect(() => {
    dispatch(fetchPlaybooks(playbook.organization_id, true, true))
  }, [playbook.organization_id])

  useEffect(() => {
    if (!isEmpty(playbooks) && fetchedEntity !== currentEntity && !loading) {
      fetchPlaybookPhraseSuggestions()
    }
  }, [isEnabled, playbooks, entryId, loading])

  const handleBulkSelectSingle = (compoundId) => {
    if (!selection.includes(compoundId)) {
      dispatch(setMassPlaybookUpdateChecklistSelection([...selection, compoundId]))
    } else {
      dispatch(
        setMassPlaybookUpdateChecklistSelection(
          selection.filter((selectedId) => selectedId !== compoundId)
        )
      )
    }
  }

  const handleSelectAll = (rows) => {
    dispatch(setMassPlaybookUpdateChecklistSelection(rows.map((row) => row.compoundId)))
  }

  const handleDeselectAll = () => {
    dispatch(setMassPlaybookUpdateChecklistSelection([]))
  }

  return (
    <>
      <CheckboxWrap
        label="Edit in Bulk Across Playbooks (Alpha)"
        text="Let the system identify similar content across your Playbooks, allowing you to select and edit multiple items simultaneously. Please note, this is an experimental feature."
      >
        <div>
          <Checkbox
            checked={isEnabled}
            onChange={() => setIsMassPlaybookUpdateOn(!isEnabled)}
            disabled={readOnly}
            data-testid="mass-playbook-update-checkbox"
            toggle
          />
        </div>
      </CheckboxWrap>
      {isEnabled && (
        <div className="mb" style={{ maxWidth: '715px' }}>
          <MassPlaybookUpdateWarningBanner />
          {loading || fetchedEntity !== currentEntity ? (
            <Loader active inline="centered" />
          ) : (
            <AdvancedTable
              columns={columns}
              rows={matches}
              selectedRows={selection}
              index="compoundId"
              bulkSelectable
              bulkSelectAction={<></>}
              handleBulkSelectSingle={handleBulkSelectSingle}
              handleDeselectAll={handleDeselectAll}
              handleSelectAll={handleSelectAll}
              disabledSelection={progress !== massPlaybookUpdateProgressStatus.INITIAL}
              itemName="checklist"
              noDataHeader={
                fetchFailed ? 'Failed to get phrase suggestions for bulk editing' : undefined
              }
              noDataText={
                fetchFailed
                  ? 'Please retry by reloading or reopening the page'
                  : 'No similar content found across your Playbooks'
              }
            />
          )}
        </div>
      )}
    </>
  )
}

export default ChecklistBulkEdit
