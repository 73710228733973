import React from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { IconVolume, IconVolumeOff } from '@tabler/icons-react'
import classNames from 'classnames'

export const VolumeSlider = ({ isDisabled, volume, handleVolumeChange }) => {
  const isMuted = volume === '0'

  const handleToggleMute = () => {
    if (isMuted) {
      handleVolumeChange('1')
    } else {
      handleVolumeChange('0')
    }
  }

  return (
    <div className="flex-space-between small-gap">
      <Popup
        inverted
        position="top left"
        content={isDisabled ? 'Volume is Not Available' : isMuted ? 'Unmute Volume' : 'Mute Volume'}
        trigger={
          <Button
            icon
            basic
            compact
            className="svg-button"
            onClick={handleToggleMute}
            disabled={isDisabled}
          >
            {isMuted ? <IconVolumeOff /> : <IconVolume />}
          </Button>
        }
      />
      <input
        id="volume-slider"
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={(event) => handleVolumeChange(event.target.value)}
        disabled={isDisabled}
        className={classNames({ disabled: isDisabled })}
      />
    </div>
  )
}
