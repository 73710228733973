import React from 'react'
import { Button } from 'semantic-ui-react'
import pluralize from 'pluralize'

export const BulkSelectBanner = ({
  handleBulkDeselect,
  handleSelectAll,
  selectedRows,
  searchFilteredRows,
  rows,
  rowsPerPage,
  bulkSelectAction,
  itemName = 'item',
  pagination,
  disabledSelection,
}) => {
  const allRowsSelected = selectedRows.length === rows.length
  const itemNamePlural = pluralize(itemName)
  return (
    <div className="bulk-select-banner">
      <div className="flex-align-center medium-gap">
        <div>
          {allRowsSelected
            ? selectedRows.length === 1
              ? `All ${itemNamePlural} selected.`
              : `All ${selectedRows.length} ${itemNamePlural} selected.`
            : `${selectedRows.length} ${pluralize(itemName, selectedRows.length)} selected.`}

          {allRowsSelected && !disabledSelection && (
            <Button
              data-testid="deselect-all-button"
              className="inline-link-button"
              onClick={handleBulkDeselect}
            >
              {`Deselect all ${itemNamePlural}.`}
            </Button>
          )}

          {/* Theoretically, the selected rows might be the same length as the filteed rows if they do some shenanigans
        like selecting items before filtering, then deselecting some items after filtering, but it's unlikely enough that
        I didn't think it was necessary here to check all the item IDs in both lists and ensure they're the same. If something comes up,
        we can fix it.
         */}
          {!allRowsSelected &&
            !disabledSelection &&
            searchFilteredRows.length !== selectedRows.length && (
              <Button
                data-testid="select-all-button"
                className="inline-link-button"
                onClick={handleSelectAll}
              >
                {rows.length === searchFilteredRows.length
                  ? `Select all ${searchFilteredRows.length} ${itemNamePlural}${pagination ? ' across all pages' : ''}.`
                  : `Select matching ${searchFilteredRows.length} ${itemNamePlural}${pagination && rowsPerPage < searchFilteredRows.length ? ' across all pages' : ''}.`}
              </Button>
            )}
        </div>
      </div>
      {bulkSelectAction}
    </div>
  )
}
