import { toast } from 'react-toastify'
import queryString from 'query-string'
import { cloneDeep } from 'lodash'

import { fetchingAPI, apiService } from '@/api'
import { fetchAudioForCallId, fetchVideoForCallId } from '@/reducers/media/media.actions'
import { fetchSavedFilters } from '../savedFilters/savedFilters.actions'

import * as redux from './tasks.redux'
import * as TASK_TYPES from './tasks.constants'
import { fetchCall } from '../callSearch/callSearch.actions'

export const fetchTasks =
  (taskType, agentIds = [], tagIds = []) =>
  async (dispatch) => {
    try {
      // we aren't showing a date range picker in filters but there were concerns
      // about amount loaded so just going to hard code 60 days before today
      // in the future if product wants a date picker we just have to pass value to here
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 60)
      startDate.setHours(0, 0, 0, 0)

      const endDate = new Date()
      endDate.setHours(23, 59, 59, 999)
      // if agent_ids/tag_ids becomes too long, we may need to change endpoint to a post
      const params = queryString.stringify({
        task_types: taskType,
        agent_ids: agentIds,
        tag_ids: tagIds,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        task_statuses: ['completed', 'expired', 'ignored', 'ready_for_review'],
      })

      dispatch(redux.setLoading({ [TASK_TYPES.INBOX]: true }))
      const response = await fetchingAPI(`${apiService.workflow}/tasks?${params}`, 'GET', dispatch)
      const updatedTasks = response.map((task) => ({
        ...task,
        call_count: task.evidence?.length || 0,
      }))

      dispatch(redux.setTasks(updatedTasks))
    } catch (err) {
      toast.error('Failed to fetch tasks')
    } finally {
      dispatch(redux.setLoading({ [TASK_TYPES.INBOX]: false }))
    }
  }

export const patchTask = (taskId, body) => async (dispatch) => {
  try {
    await fetchingAPI(
      `${apiService.workflow}/tasks/${taskId}`,
      'PATCH',
      dispatch,
      JSON.stringify(body)
    )

    toast.success('Task updated')
  } catch (err) {
    toast.error('Failed to update task')
  }
}

export const getCriteriaScoreByIds = (criteriaScoreId) => async (dispatch) => {
  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/criteria_scores/by_ids?criteria_score_ids=${criteriaScoreId}`,
      'GET',
      dispatch
    )
    return response
  } catch (err) {
    toast.error('Failed to get criteria scores')
    return []
  }
}

export const fetchTaskComments = (taskId) => async (dispatch) => {
  let comments = []

  try {
    comments = await fetchingAPI(`${apiService.workflow}/tasks/${taskId}/note`, 'GET', dispatch)
  } catch (err) {
    console.error(err)
  }

  return comments
}

export const postTaskComment = (commentText, taskId) => async (dispatch, getState) => {
  const { user_id } = getState().currentUser
  const body = { note_text: commentText.trim(), user_id }

  return fetchingAPI(
    `${apiService.workflow}/tasks/${taskId}/note`,
    'POST',
    dispatch,
    JSON.stringify(body)
  )
}

export const addTaskComment = (commentText, taskId) => async (dispatch) => {
  try {
    await dispatch(postTaskComment(commentText, taskId))
    toast.success('Note added to task')
  } catch (err) {
    toast.error('Failed to add note to task')
  }
}

export const patchTaskComment =
  (updatedProperty, commentId, taskId) => async (dispatch, getState) => {
    const { user_id } = getState().currentUser

    return fetchingAPI(
      `${apiService.workflow}/tasks/${taskId}/note/${commentId}`,
      'PATCH',
      dispatch,
      JSON.stringify({ ...updatedProperty, user_id })
    )
  }

export const updateTaskComment =
  (updatedProperty, commentId, taskId, isDelete) => async (dispatch) => {
    try {
      await dispatch(patchTaskComment(updatedProperty, commentId, taskId))

      toast.success(`Note ${isDelete ? 'deleted' : 'updated'}`)
    } catch (err) {
      toast.error(`Failed to ${isDelete ? 'delete' : 'update'} note`)
    }
  }

export const createManualTask =
  (callId, userId, organizationId, name, reason, timestamp, type) => async (dispatch) => {
    try {
      const body = {
        name,
        call_id: callId,
        agent_id: userId,
        description: reason,
        type,
        status: 'ready_for_review',
        identifier_id: callId,
      }
      if (timestamp) {
        body.manual_timestamp = timestamp
      }
      await fetchingAPI(
        `${apiService.workflow}/tasks?requested_organization_id=${organizationId}`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )
      const successMessage = type === 'compliance' ? 'Call escalated' : 'Coaching task created'
      toast.success(successMessage)
    } catch (err) {
      const failureMessage =
        type === 'compliance' ? 'Failed to escalate call' : 'Failed to create coaching task'
      toast.error(failureMessage)
    }
  }

export const fetchMediaForEvidence = async (evidenceArray, dispatch) => {
  const updatedEvidenceArray = []

  for (const evidence of evidenceArray) {
    let updatedEvidence = { ...evidence }

    // Fetch transcript
    const callData = await dispatch(fetchCall(evidence.call_id))
    updatedEvidence = { ...updatedEvidence, call_data: callData }

    const audioResponse = await fetchAudioForCallId(evidence.call_id, dispatch)

    // Fetch audio
    if (audioResponse) {
      const callAudio = {
        call_id: evidence.call_id,
        audio_url: audioResponse.audioUrl,
        audio_status: audioResponse.audioStatus,
        audio_url_expiration: audioResponse.audioUrlExpiration,
      }

      updatedEvidence = { ...updatedEvidence, call_audio: callAudio }
    } else {
      updatedEvidence = {
        ...updatedEvidence,
        call_audio: { call_id: evidence.call_id, audio_status: 'unavailable' },
      }
    }

    // Fetch video
    const videoResponse = await fetchVideoForCallId(evidence.call_id, dispatch)

    if (videoResponse) {
      const callVideo = {
        call_id: evidence.call_id,
        video_urls: videoResponse.videoUrls,
        video_status: videoResponse.videoStatus,
      }

      updatedEvidence = { ...updatedEvidence, call_video: callVideo }
    } else {
      updatedEvidence = {
        ...updatedEvidence,
        call_video: { call_id: evidence.call_id, video_status: 'unavailable' },
      }
    }

    updatedEvidenceArray.push(updatedEvidence)
  }

  return updatedEvidenceArray
}

export const fetchScorecardByIdentifierId = (originalTask) => async (dispatch) => {
  try {
    const [response] = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores?scorecard_ids=${originalTask.scorecard_id}&requested_organization_id=${originalTask.organization_id}&include_deleted=True`,
      'GET',
      dispatch
    )

    const task = cloneDeep(originalTask)
    task.score = response

    const manualCriteria = []

    task.score.section_scores.forEach((section) => {
      section.measure_scores.forEach((measure) => {
        measure.criteria_scores.forEach((criteria) => {
          if (criteria.criteria_type === 'manual') {
            manualCriteria.push(criteria)
          }
        })
      })
    })
    task.manualCriteria = manualCriteria
    dispatch(redux.setTask(task))
  } catch (err) {
    toast.error('Failed to fetch scorecard')
  }
}

export const resolveManualScore = (updatedCriteria, scorecardId) => async (dispatch) => {
  try {
    await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/${scorecardId}/update_criteria`,
      'POST',
      dispatch,
      JSON.stringify(updatedCriteria)
    )
  } catch {
    toast.error('Failed to update scores')
  }
}
export const fetchTaskById =
  (taskId, handleOpenTaskDrawer, handleCloseTaskDrawer) => async (dispatch) => {
    dispatch(redux.setLoading({ task: true }))

    try {
      const response = await fetchingAPI(`${apiService.workflow}/tasks/${taskId}`, 'GET', dispatch)

      handleOpenTaskDrawer(response)
    } catch (err) {
      toast.error('Failed to load task')
      handleCloseTaskDrawer()
    } finally {
      dispatch(redux.setLoading({ task: false }))
    }
  }

export const fetchInitialFilters = (filterType) => async (dispatch, getState) => {
  await dispatch(fetchSavedFilters(filterType))
  const filters = getState().savedFilters.savedFilterList[filterType]

  // Find the default saved filter, if exists
  const defaultFilter = filters.find((filter) => filter.is_default)

  if (defaultFilter) {
    dispatch(redux.setSavedFilterId(defaultFilter.uuid))
  }

  dispatch(redux.setFiltersLoaded(true))
}

export const fetchOrganizationData = (organizationId) => async (dispatch) => {
  dispatch(redux.setLoading({ agents: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )

    response.users.sort((a, b) => a.last_name.localeCompare(b.last_name))
    const agentOptions = response.users.map((agent) => ({
      value: agent.id,
      label: `${agent.first_name} ${agent.last_name}`,
    }))

    dispatch(redux.setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch organization data')
  } finally {
    dispatch(redux.setLoading({ agents: false }))
  }
}

export const submitCoachingRequest =
  (payload, fetchTasksWithFilters, handleOpenTaskDrawer) => async (dispatch) => {
    dispatch(redux.setLoading({ coachingRequest: true }))

    try {
      const response = await fetchingAPI(
        `${apiService.summary}/coaching`,
        'POST',
        dispatch,
        JSON.stringify(payload)
      )

      if (response) {
        handleOpenTaskDrawer(response)
        fetchTasksWithFilters()
      }
    } catch (err) {
      toast.error(`Failed to generate coaching task. ${err?.message || 'Please try again.'}`)
    } finally {
      dispatch(redux.setLoading({ coachingRequest: false }))
    }
  }
