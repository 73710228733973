import React from 'react'
import { capitalize, isEmpty, isNil, isObject, isArray, startCase, isString } from 'lodash'

import { formatCustomColumns } from '@/components/helpers/tableColumnHelpers'
import { DEFAULT_CALL_COLUMNS } from '@/views/Calls/callColumns'

// If saved custom columns are returned from the API, merge and format them with the default columns
// otherwise return the default
export const getMemoizedColumns = (customColumns, defaultColumns) => {
  return !isEmpty(customColumns)
    ? formatCustomColumns(defaultColumns, customColumns, true)
    : defaultColumns.filter((value) => !value?.is_hidden)
}

export const isSummaryColumnHidden = (columns) => {
  return columns.reduce((acc, column) => {
    if (column.accessor === 'call_summary') {
      return column.is_hidden
    }
    return acc
  }, true)
}

// Turn all the summary keys into columns
export const getDynamicColumns = (summaryColumnHidden, calls = []) => {
  const defaultAccessors = DEFAULT_CALL_COLUMNS.map((column) => column.accessor.toLowerCase())
  let columnsFoundInSummariesToRender = []
  if (!summaryColumnHidden) {
    columnsFoundInSummariesToRender = calls.reduce((acc, call) => {
      if (!isEmpty(call.call_summary)) {
        acc.push(...Object.keys(call.call_summary))
      }
      return acc
    }, [])
  }
  // ensure dynamic columns don't include any keys that are already in the default columns
  const dynamicColumns = [...new Set(columnsFoundInSummariesToRender)].filter(
    (column) => !defaultAccessors.includes(column)
  )

  return dynamicColumns
}

const formatArrayContent = (arrayContent) => (
  <ul className="summary-list-content">
    {arrayContent.map((item) => (
      <li key={`list-item-${item}`}>{item}</li>
    ))}
  </ul>
)

const formatNestedContent = (nestedContent) => (
  <ul className="summary-list-content">
    {Object.keys(nestedContent).map((key) => (
      <li key={`list-item-${key}`}>{`${startCase(key)}: ${nestedContent[key]}`}</li>
    ))}
  </ul>
)

export const formatContent = (content) => {
  if (isEmpty(content)) {
    return ''
  }
  if (isArray(content)) {
    return formatArrayContent(content)
  }
  if (isObject(content)) {
    return formatNestedContent(content)
  }

  return content
}

const formatSummaryTableContent = (content) => {
  const formattedContent = formatContent(content)

  if (isString(formattedContent)) {
    const fitColumnWidthToContentWidth = content.length < 60

    if (fitColumnWidthToContentWidth) {
      return <div>{content}</div>
    }

    return <div className="table-summary-section">{content}</div>
  }

  return formattedContent
}

// Insert all the dynamic columns where the summary column is listed
export const getCompleteColumns = (columns, dynamicColumns) => {
  let completeColumns = columns
  const indexOfSummaryColumn = columns.findIndex((column) => column.accessor === 'call_summary')

  if (indexOfSummaryColumn !== -1) {
    const beginningColumns = columns.slice(0, indexOfSummaryColumn)
    const middleColumns = dynamicColumns.map((column) => ({
      label: capitalize(column),
      accessor: column,
      is_hidden: false,
      isSortable: false,
      parent: 'call_summary',
      isCopiable: true,
      isSearchable: true,
      format: (value) => {
        if (value) {
          return formatSummaryTableContent(value)
        }

        return null
      },
    }))

    const trailingColumns = columns.slice(indexOfSummaryColumn + 1)
    completeColumns = [...beginningColumns, ...middleColumns, ...trailingColumns]
  }

  return completeColumns
}
// makes coulmn names for the scores part of a call
export const getScorecardsCustomColumns = (scorecards) => {
  const scorecardNames = scorecards?.map((scorecard) => scorecard.label)
  const newColumns = []
  scorecardNames?.forEach((scorecardName) => {
    newColumns.push(`${scorecardName} - Overall QA Score`)
  })
  return newColumns
}

// inserts scores columns (similar to getCompleteColumns()
export const getCompleteColumnsWithScores = (columns, dynamicColumns) => {
  let completeColumns = columns
  const indexOfScoresSection = columns.findIndex((column) => column.accessor === 'scores')

  if (indexOfScoresSection !== -1) {
    const beginningColumns = columns.slice(0, indexOfScoresSection)
    const middleColumns = dynamicColumns.map((column) => ({
      label: column,
      accessor: column,
      is_hidden: false,
      isSortable: false,
      isCopiable: true,
      isSearchable: true,
      parent: 'scores',
      format: (value) => {
        if (isNil(value)) return null
        if (column.includes('QA Score')) return `${value.toFixed(2)}%`
        return value
      },
    }))
    const trailingColumns = columns.slice(indexOfScoresSection + 1)
    completeColumns = [...beginningColumns, ...middleColumns, ...trailingColumns]
  }

  return completeColumns
}

export const filterExtraColumns = (completeColumns, savedColumns) => {
  return completeColumns
    .filter((column) => {
      const savedColumn = savedColumns.find((_column) => _column.accessor === column.accessor)
      if (savedColumn) {
        return !savedColumn.is_hidden
      }
      return !column.is_hidden
    })
    .sort((columnA, columnB) => {
      if (columnA.parent && columnB.parent && columnA.parent === columnB.parent) {
        const indexA = savedColumns.findIndex((_column) => _column.accessor === columnA.accessor)
        const indexB = savedColumns.findIndex((_column) => _column.accessor === columnB.accessor)
        return indexA - indexB
      }
      return savedColumns.indexOf(columnA) - savedColumns.indexOf(columnB)
    })
}

export const getChildColumns = (completeColumns, savedCustomColumns) => {
  return completeColumns
    .filter((col) => !isNil(col.parent))
    .map((col) => {
      const savedExtraColumn = savedCustomColumns.find((col1) => {
        return col1.accessor === col.accessor
      })
      if (isNil(savedExtraColumn)) {
        return col
      }
      return savedExtraColumn
    })
}
