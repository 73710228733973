import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import classNames from 'classnames'

import { BaltoLogo } from '@/components/BaltoLogo'
import { IconLayoutSidebarRightCollapse, IconLayoutSidebarLeftCollapse } from '@tabler/icons-react'

export const SidebarHeader = ({
  organizationName,
  branding,
  sidebarCollapsed,
  handleToggleSidebarCollapsed,
}) => {
  const { loading, customBranding, imageCache } = branding
  const shouldShowCustomLogo = customBranding.iconDarkUrl || customBranding.iconLightUrl
  const customLogoUrl = `${shouldShowCustomLogo}?${imageCache}`
  const BaltoCompanyName = 'Balto'

  return (
    <header className="balto-sidebar__header">
      {!sidebarCollapsed && (
        <div className="balto-sidebar__brand">
          <Popup
            position="bottom left"
            content="Powered by Balto"
            trigger={
              <div
                className={classNames({
                  'balto-sidebar__brand-logo': shouldShowCustomLogo,
                  'balto-sidebar__balto-logo': !shouldShowCustomLogo,
                })}
              >
                {shouldShowCustomLogo ? (
                  <img
                    src={customLogoUrl}
                    alt="App Logo"
                    className="custom-app-logo"
                    data-testid="custom-app-logo"
                  />
                ) : (
                  <BaltoLogo height={24} width={24} fill="#ffffff" data-testid="balto-logo" />
                )}
              </div>
            }
          />
          <div className="balto-sidebar__brand-name">
            <div className="text-overflow">
              {loading ? '' : customBranding.customBrandingDisplayName || BaltoCompanyName}
            </div>
            <div className="organization-name text-overflow">{organizationName}</div>
          </div>
        </div>
      )}
      <div className="balto-sidebar__collapse-action">
        <Button
          compact
          secondary
          onClick={handleToggleSidebarCollapsed}
          data-testid="collapse-sidebar"
        >
          {sidebarCollapsed ? (
            <IconLayoutSidebarRightCollapse />
          ) : (
            <IconLayoutSidebarLeftCollapse />
          )}
        </Button>
      </div>
    </header>
  )
}
