import React, { useEffect, useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'

import WidgetContext from '../../../components/widget/widget.context'
import WidgetContainer from '../../../components/widget/WidgetContainer'
import { AdvancedTable } from '../../../components/tables/AdvancedTable/AdvancedTable'

export const UsersWithNoCallsWidget = () => {
  const { data, loading } = useSelector((state) => state.analytics)
  const { handleChangeCsvData } = useContext(WidgetContext)

  const rows = useMemo(() => {
    return data.usersWithoutCalls.map((user) => {
      return { ...user, name: `${user.first_name} ${user.last_name}` }
    })
  }, [data])

  const columns = [
    { accessor: 'name', label: 'Name' },
    { accessor: 'email', label: 'Email' },
    {
      accessor: 'call_count',
      label: 'Total Call Count',
      format: (v) => Number(v).toLocaleString(),
      headerAlignment: 'right',
    },
    {
      accessor: 'last_call',
      label: 'Last Call',
      format: (v) => (v ? moment.utc(v).local().format('MM/DD/YYYY h:mm:ss A') : '—'),
    },
    {
      accessor: 'last_login',
      label: 'Last Agent App Login',
      format: (v) => (v ? moment.utc(v).local().format('MM/DD/YYYY h:mm:ss A') : '—'),
    },
  ]

  useEffect(() => {
    handleChangeCsvData({ headers: columns, data: rows })
  }, [rows])

  return (
    <WidgetContainer
      large
      loading={loading.usagePage}
      noData={isEmpty(rows)}
      withPagination
      table={
        <AdvancedTable
          index="email"
          defaultOrderBy="last_login"
          defaultOrder="desc"
          columns={columns}
          rows={rows}
          pagination
          enableUpdateRowsPerPage={false}
          rowsPerPage={10}
        />
      }
      chart={<div />}
    />
  )
}
