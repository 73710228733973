import React, { useEffect, useState, useRef } from 'react'
import { SKIP_MODIFIER } from '@/utils/constants'

import { AudioTrackTime } from './components/AudioTrackTime'
import { AudioTrackProgress } from './components/AudioTrackProgress'
import { SkipButton } from './components/SkipButton'
import { PlayPauseButton } from './components/PlayPauseButton'

import './MediaPlayer.scss'

export const MediaPlayerPlaceholder = ({ handleSelectMediaPlayer, callAudio, progress = 0 }) => {
  const audioRef = useRef()
  const [duration, setDuration] = useState(0)
  const calculatedProgress = progress >= duration ? 0 : progress

  useEffect(() => {
    const durationListener = (event) => {
      setDuration(event.target.duration)
    }

    audioRef.current.addEventListener('loadedmetadata', durationListener)

    return () => {
      audioRef.current.removeEventListener('loadedmetadata', durationListener)
    }
  }, [callAudio.audio_url])

  return (
    <div className="audio-player-container">
      <audio src={callAudio.audio_url} ref={audioRef} />
      <div className="audio-player placeholder" data-testid="audio-player-placeholder">
        <div className="audio-player-controls">
          <PlayPauseButton togglePlayState={() => handleSelectMediaPlayer(calculatedProgress)} />
          <div className="flex-align-center small-gap">
            <SkipButton modifier={SKIP_MODIFIER} isDisabled />
            <SkipButton modifier={SKIP_MODIFIER} isForwardSkip isDisabled />
          </div>
          <AudioTrackTime duration={duration} progress={calculatedProgress} divider />
          <div className="audio-track-progress">
            <AudioTrackProgress
              duration={duration}
              progress={calculatedProgress}
              handleScrub={(event) => handleSelectMediaPlayer(event.target.valueAsNumber)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
