import React from 'react'

import { rankOnOptions } from '@/utils/constants'

export const LeaderboardSettingsDisplay = ({ leaderboard }) => {
  if (!leaderboard?.version) return null

  const {
    version: { config_ids, config_name, rank_identifier, date_range, min_duration_in_seconds },
    leaderboard: { agent_visible, include_managers },
  } = leaderboard

  const displayType = config_name || (config_ids?.length ? 'Playbook' : 'QA Scorecard')
  const rankMetric = rankOnOptions.find((option) => option.value === rank_identifier)?.label
  const timePeriod = date_range
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
  const duration = min_duration_in_seconds
    ? `${Math.round(min_duration_in_seconds / 60)} min`
    : 'No minimum'

  return (
    <div className="leaderboard-settings-display" data-testid="leaderboard-settings-display">
      <span>📄 {displayType}</span> |<span>📊 Ranked by {rankMetric}</span> |
      <span>👀 Visible to Agents: {agent_visible ? 'Yes' : 'No'}</span> |
      <span>👥 Managers Included: {include_managers ? 'Yes' : 'No'}</span> |
      <span>🕒 {timePeriod}</span> |<span>⏳ {duration}</span>
    </div>
  )
}
