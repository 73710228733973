import React, { useState } from 'react'
import { Segment } from 'semantic-ui-react'
import { startCase } from 'lodash'

import { Accordion } from '@/components/accordions/Accordion'
import { Pill } from '@/components/pills/Pill'

import './SuggestedQuestions.scss'

export const SuggestedQuestions = ({ initialCategory = '', questions = [], onQuestionClick }) => {
  const [selectedCategory, setSelectedCategory] = useState(initialCategory)
  const [collapsed, setCollapsed] = useState(false)

  const handleCategoryClick = (category) => {
    setSelectedCategory(category)
  }

  const handleQuestionClick = (question) => {
    onQuestionClick(question)
  }

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className="suggested-question-container" data-testid="suggested-question-container">
      <Accordion
        large
        title="Suggested Questions"
        expanded={!collapsed}
        handleToggle={handleCollapse}
      >
        <div className="category-container" data-testid="category-container">
          {Object.keys(questions).map((category) => (
            <Pill
              key={category}
              clickable
              onClick={() => handleCategoryClick(category)}
              contrast={selectedCategory === category}
              className="category"
              data-testid={`${category}-pill`}
            >
              {startCase(category)}
            </Pill>
          ))}
        </div>
        <div className="question-container">
          {questions[selectedCategory].map((question, index) => (
            <Segment
              key={question}
              className="question"
              onClick={() => handleQuestionClick(question)}
              data-testid={`question-${index}`}
            >
              {question}
            </Segment>
          ))}
        </div>
      </Accordion>
    </div>
  )
}
