/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import { IconArrowsMaximize } from '@tabler/icons-react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { useVideoPlayer } from './VideoPlayerContext'

import './VideoPlayer.scss'

const VideoPlayer = ({
  sources = [],
  onPlaying,
  onPause,
  onSeeked,
  progress,
  playbackRate,
  isPlaying,
}) => {
  const {
    videoRef,
    jumpVideoTo,
    playVideo,
    changeVideoPlaybackRate,
    toggleFullScreen,
    isVideoVisible,
  } = useVideoPlayer()
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
      videoRef.current.onloadeddata = () => {
        jumpVideoTo(progress)
        changeVideoPlaybackRate(playbackRate)

        // Keep playing video if it was already playing
        if (isPlaying) {
          playVideo()
        }
      }
    }
  }, [currentVideoIndex])

  if (isEmpty(sources)) {
    return null
  }

  return (
    <div className={classNames('video-player', { hidden: !isVideoVisible })}>
      <div className="video-player-header">
        <Menu pointing secondary className="tab-navigation video-tabs">
          {sources.map((_, index) => (
            <Menu.Item
              key={index}
              data-testid={`video-player-tab-${index}`}
              active={currentVideoIndex === index}
              onClick={() => setCurrentVideoIndex(index)}
            >
              <span>Screen {index + 1}</span>
            </Menu.Item>
          ))}
        </Menu>
        <Button className="svg-button" secondary icon compact onClick={toggleFullScreen}>
          <IconArrowsMaximize />
        </Button>
      </div>
      <video
        className="call-explorer-video"
        ref={videoRef}
        onSeeked={onSeeked}
        onPlaying={onPlaying}
        onPause={onPause}
      >
        <source src={sources[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default VideoPlayer
