import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Comments } from '@/components/comments/Comments'

import {
  fetchCoachingCommentsByCall,
  addCoachingComment,
  updateCoachingComment,
} from '@/reducers/playlists/playlists.actions'
import { setPlaylistCallComments } from '@/reducers/playlists/playlists.redux'

export const CommentEditorContainer = ({ callId }) => {
  const dispatch = useDispatch()
  const { callComments } = useSelector((state) => state.playlists)

  useEffect(() => {
    dispatch(fetchCoachingCommentsByCall(callId))

    return () => {
      dispatch(setPlaylistCallComments([]))
    }
  }, [])

  const addComment = (comment, associatedId) => {
    dispatch(addCoachingComment(comment, associatedId))
  }

  const updateComment = (comment, commentId, associatedId) => {
    dispatch(updateCoachingComment(comment, commentId, associatedId))
  }

  return (
    <Comments
      associatedId={callId}
      comments={callComments}
      addComment={addComment}
      updateComment={updateComment}
      showHeader={false}
      commentType="Comment"
      placeholder="Begin writing your comment..."
    />
  )
}
