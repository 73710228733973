import IntegrationsEditPage from '@/views/Integrations/IntegrationsEditPage'
import IntegrationsPage from '@/views/Integrations/IntegrationsPage'
import OrgHierarchyPage from '@/views/OrgHierarchy/OrgHierarchyPage'
import CommandCenterPage from '@/views/CommandCenter/CommandCenterPage'
import PlaybooksPage from '@/views/Playbooks/PlaybooksPage'
import PlaybookEditorPage from '@/views/Playbooks/PlaybookEditorPage'
import OrganizationsPage from '@/views/Organizations/OrganizationsPage'
import OrganizationPage from '@/views/Organizations/OrganizationPage'
import TagPage from '@/views/Organizations/TagPage'
import OrganizationFlagsPage from '@/views/Organizations/OrganizationFlagsPage'
import CallSearchPage from '@/views/Calls/CallSearchPage'
import CallSearchProxy from '@/views/Calls/CallSearchProxy'
import CallExplorerPage from '@/views/Calls/CallExplorerPage'
import KeywordRepositoryPage from '@/views/KeywordRepository/KeywordRepositoryPage'
import LeaderboardsPage from '@/views/Leaderboards/LeaderboardsPage'
import QACopilotPage from '@/views/QACopilot/QACopilotPage'
import AnalyticsPage from '@/views/Analytics/AnalyticsPage'
import ScorecardsPage from '@/views/Scorecards/Scorecards'
import RealtimeCoachingPage from '@/views/RealtimeCoaching/RealtimeCoaching'
import ScorecardEditorPage from '@/views/Scorecards/ScorecardEditorWrapper'
import ViewScoresPage from '@/views/Scorecards/ViewScoresPage/ViewScoresPage'
import ScorecardDashboardPage from '@/views/Scorecards/DashboardPage/DashboardPage'
import ScorecardExportPage from '@/views/Scorecards/ExportsPage/ExportsDashboard'
import ScorecardActivityPage from '@/views/Scorecards/ActivitiesPage/QAActivitiesPage'
import CopilotDashboardPage from '@/views/QACopilot/CopilotDashboard/CopilotDashboardPage'
import EnterpriseDashboardPage from '@/views/QACopilot/CopilotDashboard/EnterpriseDashboardPage'
import PlaylistPage from '@/views/Playlists/PlaylistPage'
import PlaylistsPage from '@/views/Playlists/PlaylistsPage'
import RealtimeNotetakerPage from '@/views/RealtimeNotetaker/RealtimeNotetakerPage'
import InsightsPage from '@/views/Insights/InsightsPage'
import FeatureFeedbackPage from '@/views/FeatureFeedback/FeatureFeedbackPage'
import { CoachingRedirect } from '@/views/Coaching/CoachingRedirect'
import { CoachingInbox } from '@/views/Coaching/CoachingInbox'
import { ComplianceInbox } from '@/views/Compliance/ComplianceInbox'
import { QACopilotInbox } from '@/views/QACopilot/QACopilotInbox'
import { PromptsPage } from '@/views/Analytics/PromptsPage'
import ClearTranscriptsAndAudioPage from '@/views/Calls/ClearTranscriptsAndAudioPage'
import DynamicInsightsPage from '@/views/DynamicInsights/DynamicInsightsPage'

import IndexRedirect from './IndexRedirect'

export const protectedRoutes = [
  {
    path: '/',
    Component: IndexRedirect,
  },

  {
    path: '/command-center',
    Component: CommandCenterPage,
    pageTitle: 'Live Listen',
  },

  {
    path: '/playbooks',
    Component: PlaybooksPage,
    pageTitle: 'Playbooks',
  },

  // New playbook editor
  {
    path: '/playbooks/:id',
    Component: PlaybookEditorPage,
    pageTitle: 'Redirecting...',
  },
  {
    path: '/playbooks/:id/:section',
    Component: PlaybookEditorPage,
    pageTitle: 'Playbook Editor',
  },
  {
    path: '/playbooks/:id/:section/:entryId',
    Component: PlaybookEditorPage,
    pageTitle: 'Playbook Editor',
  },

  {
    path: '/reports',
    Component: AnalyticsPage,
  },
  {
    path: '/reports/:section',
    Component: AnalyticsPage,
  },

  {
    path: '/users',
    Component: OrganizationPage,
    pageTitle: 'Users',
  },
  {
    path: '/users/tags',
    Component: TagPage,
    pageTitle: 'User Tag Manager',
  },

  {
    path: '/leaderboards',
    Component: LeaderboardsPage,
    pageTitle: 'Leaderboards',
  },
  // QA Routes
  {
    path: '/scorecards',
    Component: ScorecardsPage,
    qaRoute: true,
    pageTitle: 'Scorecards',
  },
  {
    path: '/scorecards/dashboard',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/dashboard/overview',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Overview' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/dashboard/live',
    Component: ScorecardDashboardPage,
    qaRoute: true,
    componentProps: { tab: 'Live Feed' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/scorecards/view_scores',
    Component: ViewScoresPage,
    componentProps: { copilot: false },
    qaRoute: true,
    pageTitle: 'Scores',
  },
  {
    path: '/scorecards/exports',
    Component: ScorecardExportPage,
    qaRoute: true,
    pageTitle: 'Export Scores',
  },
  {
    path: '/scorecards/activity',
    Component: ScorecardActivityPage,
    qaRoute: true,
    pageTitle: 'QA Activity',
  },
  {
    path: '/scorecards/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
    pageTitle: 'Scorecard Editor',
  },
  {
    path: '/scorecards/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaRoute: true,
    pageTitle: 'Score Dashboard',
  },
  // Copilot routes
  {
    path: '/qa-copilot',
    Component: ScorecardsPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Copilot Scorecards',
  },
  {
    path: '/qa-copilot/dashboard',
    Component: CopilotDashboardPage,
    qaCopilotRoute: true,
    pageTitle: 'Copilot Dashboard',
  },
  {
    path: '/qa-copilot/enterprise',
    Component: EnterpriseDashboardPage,
    qaCopilotRoute: true,
    pageTitle: 'Copilot Enterprise Dashboard',
  },
  {
    path: '/qa-copilot/dashboard/overview',
    Component: ScorecardDashboardPage,
    qaCopilotRoute: true,
    componentProps: { tab: 'Overview' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/dashboard/live',
    Component: ScorecardDashboardPage,
    qaCopilotRoute: true,
    componentProps: { tab: 'Live Feed' },
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/view_scores',
    Component: ViewScoresPage,
    componentProps: { copilot: true },
    qaCopilotRoute: true,
    pageTitle: 'Scores',
  },
  {
    path: '/qa-copilot/exports',
    Component: ScorecardExportPage,
    qaCopilotRoute: true,
    pageTitle: 'Export Scores',
  },
  {
    path: '/qa-copilot/activity',
    Component: ScorecardActivityPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Activity',
  },
  {
    path: '/qa-copilot/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    pageTitle: 'Scorecard Editor',
  },
  {
    path: '/qa-copilot/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/qa-copilot/inbox',
    Component: QACopilotInbox,
    pageTitle: 'Quality Inbox',
  },
  {
    path: '/qa-copilot/:id',
    Component: QACopilotPage,
    qaCopilotRoute: true,
    pageTitle: 'QA Copilot',
  },
  // Real-Time Coaching Routes
  {
    path: '/realtime_coaching/:section',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real-Time Coaching',
  },
  {
    path: '/realtime_coaching/alerts/:alert_config_id',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real Time Alerts',
  },
  {
    path: '/realtime_coaching/reports/:subsection',
    Component: RealtimeCoachingPage,
    pageTitle: 'Real Time Reports',
  },

  // Admin Routes
  {
    path: '/organizations',
    Component: OrganizationsPage,
    adminOnly: true,
    pageTitle: 'Organizations',
    dataGrid: true,
  },
  {
    path: '/organizations/:organizationid',
    Component: OrganizationPage,
    adminOnly: true,
    pageTitle: 'Organization Detail',
  },
  {
    path: '/organizations/:organizationid/tags',
    Component: TagPage,
    adminOnly: true,
    pageTitle: 'Organization Tag Manager',
  },
  {
    path: '/organizations/:organizationid/flags',
    Component: OrganizationFlagsPage,
    adminOnly: true,
    pageTitle: 'Organization Flags',
  },
  {
    path: '/organizations/:organizationid/hierarchy',
    Component: OrgHierarchyPage,
    adminOnly: true,
    pageTitle: 'Organization Hierarchy',
  },
  {
    path: '/calls',
    Component: CallSearchProxy,
    adminOnly: true,
    pageTitle: 'Call Explorer',
  }, // TODO: Remove this
  {
    path: '/call-explorer/:id',
    Component: CallExplorerPage,
    pageTitle: 'Call Detail',
  },
  {
    path: '/call-explorer',
    Component: CallSearchPage,
    pageTitle: 'Call Explorer',
    dataGrid: true,
  },
  {
    path: '/keywords_repository/:language(english|spanish)?',
    Component: KeywordRepositoryPage,
    adminOnly: true,
    pageTitle: 'Keyword Repository',
  },
  {
    path: '/clear_transcripts_and_audio',
    Component: ClearTranscriptsAndAudioPage,
    adminOnly: true,
    pageTitle: 'Clear Transcripts and Audio',
  },
  {
    path: '/integrations',
    Component: IntegrationsPage,
    adminOnly: true,
    pageTitle: 'Integrations',
  },
  {
    path: '/integrations/edit_config/:integration_config_id',
    Component: IntegrationsEditPage,
    qaRoute: true,
    componentProps: { tab: 'Connector Config' },
    pageTitle: 'Integration Editor',
  },

  // Playlists
  {
    path: '/playlists',
    Component: PlaylistsPage,
    pageTitle: 'Playlists',
  },
  {
    path: '/playlists/:playlistUuid',
    Component: PlaylistPage,
    pageTitle: 'Playlist',
    dataGrid: true,
  },

  // Realtime Notetaker
  {
    path: '/realtime-notetaker',
    Component: RealtimeNotetakerPage,
    pageTitle: 'Realtime Notetaker',
  },
  {
    path: '/feature-feedback',
    Component: FeatureFeedbackPage,
    pageTitle: 'Feature Feedback',
  },
  {
    path: '/coaching/scorecards',
    Component: ScorecardsPage,
    pageTitle: 'Coaching Scorecard',
    adminOnly: true,
    qaCopilotRoute: true,
  },
  {
    path: '/coaching',
    Component: CoachingRedirect,
    pageTitle: 'Coaching Opportunities',
  },
  {
    path: '/coaching/inbox',
    Component: CoachingInbox,
    pageTitle: 'Coaching Inbox',
  },
  {
    path: '/coaching/scorecards/edit_config/:scorecard_config_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    adminOnly: true,
    pageTitle: 'Scorecard Editor',
  },
  {
    path: '/coaching/scorecards/edit_config/:scorecard_config_id/edit_section/:scorecard_section_id',
    Component: ScorecardEditorPage,
    qaCopilotRoute: true,
    adminOnly: true,
    pageTitle: 'Score Dashboard',
  },
  {
    path: '/coaching/scorecards/:id',
    Component: QACopilotPage,
    qaCopilotRoute: true,
    adminOnly: true,
    pageTitle: 'Coaching Scorecard',
  },
  // Compliance
  {
    path: '/compliance/inbox',
    Component: ComplianceInbox,
    pageTitle: 'Compliance Inbox',
  },
  // Insights
  {
    path: '/insights',
    Component: InsightsPage,
    pageTitle: 'Insights',
  },
  {
    path: '/dynamic-insights',
    Component: DynamicInsightsPage,
    pageTitle: 'Dynamic Insights',
  },
  // AI Prompts Page
  {
    path: '/ai_usage',
    Component: PromptsPage,
    pageTitle: 'AI Usage',
  },
]
