import React from 'react'
import { Checkbox, Popup } from 'semantic-ui-react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { FilterComponent } from '@/components/filters/types/FilterComponent'

import { SortButton } from '../../SortButton'

export const AdvancedTableHeader = ({
  actions,
  columns,
  orderBy,
  order,
  onRequestSort,
  updateSearch,
  stickyAction = false,
  truncate = false,
  wrapColumnContent = true,
  searchFilters = {},
  compactHeader = false,
  isBulkSelectable,
  handleBulkSelectAll,
  selectedRows,
  allRowsSelected,
  reorderable,
  rows,
  filteredRows,
  handleSelectAll,
  handleDeselectAll,
  dataGrid,
  disabledSelection,
  expandable,
}) => {
  const actionsWidthCalculated = actions.length * 32
  const sharedHeadRowClasses = { 'compact-header': compactHeader }
  const noRowsVisible = isEmpty(filteredRows)
  const someRowsSelected = isBulkSelectable && !isEmpty(selectedRows)

  const createSortHandler = (accessor) => (event) => {
    onRequestSort(event, accessor)
  }

  return (
    <thead>
      <tr>
        {isBulkSelectable && (
          <th
            className={classNames('bulk-selectable-column', sharedHeadRowClasses, {
              'sticky-column': dataGrid,
            })}
          >
            <div className="advanced-table-column">
              {!noRowsVisible && handleBulkSelectAll && (
                <div className="vertical-center">
                  <div
                    className="flex-align-center small-gap"
                    data-testid={allRowsSelected ? 'bulk-deselect-all' : 'bulk-select-all'}
                  >
                    <Popup
                      inverted
                      content={allRowsSelected ? 'Deselect All' : 'Select All'}
                      trigger={
                        <Checkbox
                          indeterminate={!allRowsSelected && !isEmpty(selectedRows)}
                          checked={allRowsSelected}
                          onChange={handleBulkSelectAll}
                        />
                      }
                    />
                  </div>
                </div>
              )}
              {(someRowsSelected || handleSelectAll) && (
                <Popup
                  inverted
                  content="Deselect All"
                  disabled={!someRowsSelected}
                  trigger={
                    <Checkbox
                      indeterminate={!allRowsSelected && !isEmpty(selectedRows)}
                      checked={allRowsSelected}
                      onChange={someRowsSelected ? handleDeselectAll : handleSelectAll}
                      disabled={disabledSelection}
                    />
                  }
                />
              )}
            </div>
          </th>
        )}
        {(reorderable || expandable) && <th className={classNames(sharedHeadRowClasses)} />}
        {columns.map((headCell) => {
          const { isSortable = true, headerAlignment, isSearchable, searchSchema } = headCell
          const sorted = isSortable && orderBy === headCell.accessor

          return (
            <th
              key={headCell.accessor}
              className={classNames('table-header-cell', {
                ...sharedHeadRowClasses,
                'sticky-column': headCell.sticky,
                'sticky-column-selectable-offset': isBulkSelectable && dataGrid && headCell.sticky,
                'no-wrap': !wrapColumnContent,
                'full-width-row': headCell.fullWidthRow,
                'show-on-hover': !sorted,
                [`${headerAlignment}-aligned`]: headerAlignment && headerAlignment,
                collapsing: headCell.collapsing,
              })}
              style={headCell.color ? { borderBottom: `2px solid ${headCell.color}` } : {}}
              data-testid={`th--${headCell.accessor}`}
            >
              <div className="advanced-table-column">
                {isSearchable && (
                  <FilterComponent
                    type={!searchSchema ? 'text' : searchSchema.type}
                    schema={
                      !searchSchema ? headCell : { ...searchSchema, accessor: headCell.accessor }
                    }
                    value={searchFilters[headCell.accessor]}
                    loading={false}
                    updateSearch={updateSearch}
                    columnAccessor={headCell.accessor}
                    rows={rows}
                  />
                )}

                {!reorderable && isSortable ? (
                  <SortButton
                    label={headCell.label}
                    handleClick={createSortHandler(headCell.accessor)}
                    dataType={headCell.accessor}
                    sortBy={orderBy}
                    asc={order === 'asc'}
                    data-testid={`sort-button-${headCell.accessor}`}
                  >
                    <div
                      className={classNames({ truncate })}
                      data-testid={`static-header-${headCell.accessor}`}
                    >
                      {headCell.label}
                    </div>
                  </SortButton>
                ) : (
                  <div
                    className={classNames({ truncate })}
                    data-testid={`static-header-${headCell.accessor}`}
                  >
                    {headCell.label}
                  </div>
                )}
              </div>
            </th>
          )
        })}
        {!isEmpty(actions) && (
          <th
            style={{
              textAlign: 'center',
              width: actionsWidthCalculated,
              minWidth: actionsWidthCalculated,
            }}
            className={classNames({ 'sticky-action': stickyAction, 'no-wrap': !wrapColumnContent })}
          >
            <div className="advanced-table-column">Actions</div>
          </th>
        )}
      </tr>
    </thead>
  )
}
