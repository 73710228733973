import { QA_INBOX } from '@/reducers/tasks/tasks.constants'
import { orderBy as lodashSort } from 'lodash'

export const TODO = 'todo'
export const ARCHIVE = 'archive'

export const taskTypeOptions = [
  { label: 'Dismissed', value: 'ignored' },
  { label: 'Reviewed', value: 'completed' },
]

export const getSortByOptions = (inboxType) => {
  const sortByOptions = [
    { label: 'Recently Updated', value: 'updated_at' },
    { label: 'Creation Date', value: 'created_at' },
  ]

  if (inboxType !== QA_INBOX) {
    sortByOptions.push({
      label: 'Number of Related Calls',
      value: 'call_count',
    })
  }

  return sortByOptions
}

export const DEFAULT_SORT_CONFIG = { sortBy: 'updated_at', orderBy: 'desc' }

export const inboxSort = (items = [], sortConfig = DEFAULT_SORT_CONFIG) => {
  return lodashSort(items, sortConfig.sortBy, sortConfig.orderBy)
}
