import React from 'react'
import { Popup } from 'semantic-ui-react'

import { UserInitial } from '@/components/UserInitial'

export const AgentList = ({ agents }) => {
  return (
    <Popup
      inverted
      content={
        <div className="text-left">
          {agents.map((agent) => (
            <div key={agent.label}>{agent.label}</div>
          ))}
        </div>
      }
      trigger={
        <div>
          {agents
            .filter((agent, index) => index < 3)
            .map((agent, index) => (
              <UserInitial userFullName={agent.label} index={index} inline key={agent.label} />
            ))}
          {agents.length > 3 && <UserInitial overflowCount={agents.length - 3} inline />}
        </div>
      }
    />
  )
}
