import React, { createContext, useRef, useContext, useState } from 'react'

const VideoPlayerContext = createContext()

export const VideoPlayerProvider = ({ children }) => {
  const videoRef = useRef(null)
  const [isVideoVisible, setIsVideoVisible] = useState(true)

  const changeVideoPlaybackRate = (speed) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed
    }
  }

  const jumpVideoTo = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
    }
  }

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  const toggleFullScreen = () => {
    if (videoRef.current && videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    }
  }

  const toggleVideoVisibility = () => {
    setIsVideoVisible((prevVisibility) => !prevVisibility)
  }

  const addVideoEvent = (eventType, callback) => {
    if (videoRef.current) {
      videoRef.current.addEventListener(eventType, callback)
    }
  }

  const removeVideoEvent = (eventType, callback) => {
    if (videoRef.current) {
      videoRef.current.addEventListener(eventType, callback)
    }
  }

  return (
    <VideoPlayerContext.Provider
      value={{
        videoRef,
        changeVideoPlaybackRate,
        jumpVideoTo,
        playVideo,
        pauseVideo,
        toggleFullScreen,
        toggleVideoVisibility,
        isVideoVisible,
        addVideoEvent,
        removeVideoEvent,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}

export const useVideoPlayer = () => {
  return useContext(VideoPlayerContext)
}
