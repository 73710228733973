import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import {
  fetchQAScoresByCallId,
  addCoachingComment,
  updateCoachingComment,
} from '@/reducers/callSearch/callSearch.actions'

import { ActivityViewer } from '@/views/Calls/components/ActivityViewer'
import { fetchScorecardsByCallId } from '@/reducers/scorecards/scorecards.actions'
import { fetchQaAuditEventsByCallId } from '@/reducers/scorecards/scores.actions'
import { setData } from '@/reducers/scorecards/scorecards.redux'
import { Comments } from '@/components/comments/Comments'
import { EventsViewer } from './components/EventsViewer'
import { Summary } from './components/Summary'
import { TechnicalDetails } from './components/TechnicalDetails'
import { CallQAScores } from './components/CallQAScores'

import {
  CALL_EXPLORER_TAB_ENUMS as enums,
  parseCallExplorerParams,
  updateCallExplorerParams,
} from './components/helpers'

import './CallExplorerTabs.scss'

export const CallExplorerTabs = ({ highlightedEvents, callId, organizationId }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [openTab, setOpenTab] = useState(enums.SUMMARY)
  const {
    callExplorer: { metadata = {}, analysis, copilotAnchors, callComments },
  } = useSelector((state) => state.callSearch)

  const updateComment = (comment, commentId, associatedId) => {
    dispatch(updateCoachingComment(comment, commentId, associatedId))
  }

  const addComment = (comment, associatedId) => {
    dispatch(addCoachingComment(comment, associatedId))
  }

  const tabs = [
    { id: enums.SUMMARY, label: 'Summary' },
    { id: enums.DETAILS, label: 'Details' },
    { id: enums.EVENTS, label: 'Events' },
    { id: enums.QA_SCORES, label: 'QA Scores' },
    { id: enums.COMMENTS, label: 'Comments' },
    { id: enums.ACTIVITY, label: 'Activity' },
  ]

  const tabComponentMap = {
    [enums.EVENTS]: (
      <EventsViewer
        analysis={analysis}
        highlightedEvents={highlightedEvents}
        copilotAnchors={copilotAnchors}
        metadata={metadata}
      />
    ),
    [enums.SUMMARY]: <Summary metadata={metadata} callId={callId} />,
    [enums.DETAILS]: <TechnicalDetails metadata={metadata} callId={callId} />,
    [enums.QA_SCORES]: (
      <CallQAScores
        metadata={metadata}
        callId={callId}
        organizationId={organizationId}
        setOpenTab={setOpenTab}
      />
    ),
    [enums.ACTIVITY]: <ActivityViewer />,
    [enums.COMMENTS]: (
      <Comments
        associatedId={callId}
        comments={callComments}
        addComment={addComment}
        updateComment={updateComment}
        commentType="Comment"
        placeholder="Begin writing your comment..."
      />
    ),
  }

  const fetchScoreData = async () => {
    const resetEvents = dispatch(setData('qaAuditEvents', []))
    const fetchScores = dispatch(fetchQAScoresByCallId(callId, organizationId))
    const fetchScorecards = dispatch(fetchScorecardsByCallId(callId, organizationId))
    const fetchAuditEvents = dispatch(fetchQaAuditEventsByCallId(callId, organizationId))

    await Promise.all([resetEvents, fetchScores, fetchScorecards, fetchAuditEvents])

    const { tab, keywords, events } = parseCallExplorerParams(location)

    if (tab) {
      setOpenTab(tab)
    }
    if (!tab && (keywords || events)) {
      setOpenTab(enums.EVENTS)
    }
  }

  useEffect(() => {
    fetchScoreData()
  }, [])

  return (
    <div className="flex-column flex-item" style={{ width: '50%' }}>
      <header className="call-window-scrollable__headers call-explorer-tabs horizontal-scroll">
        <Menu pointing secondary className="tab-navigation">
          {tabs.map((tab) => {
            return (
              <Menu.Item
                key={tab.id}
                data-testid={tab.id}
                active={openTab === tab.id}
                onClick={() => {
                  updateCallExplorerParams(location, history, { tab: tab.id })
                  setOpenTab(tab.id)
                }}
              >
                <span>{tab.label}</span>
              </Menu.Item>
            )
          })}
        </Menu>
      </header>
      <div
        className="call-window-scrollable__content call-explorer-details-container flex-item"
        data-testid="call-explorer-details"
      >
        {tabComponentMap[openTab]}
      </div>
    </div>
  )
}
