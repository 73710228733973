import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react'
import { IconChevronRight, IconChevronDown } from '@tabler/icons-react'

import { fetchScorecardByIdentifierId, resolveManualScore } from '@/reducers/tasks/tasks.actions'
import { updateMediaPlayer } from '@/reducers/media/media.redux'

import { JumpToMomentPill } from '@/components/media/components/JumpToMomentPill'
import { formatTime } from '@/views/Calls/components/helpers'
import { TaskDrawerFooter } from './TaskDrawerFooter'
import { ScoreForm } from './ScoreForm'

export const ManualScoreDrawer = ({
  taskIsOpen,
  task,
  handleClose,
  handlePrimaryAction,
  handleSecondaryAction,
}) => {
  const [scores, setScores] = useState({})
  const [openPanels, setOpenPanels] = useState({})
  const dispatch = useDispatch()

  const handleUpdateScores = async () => {
    const updatedScores = task.manualCriteria.map((criteria) => ({
      ...criteria,
      score: scores[criteria.id],
      scorecard_criteria_score_uuid: criteria.uuid,
    }))
    await dispatch(resolveManualScore(updatedScores, task.scorecard_id))
  }

  useEffect(() => {
    dispatch(fetchScorecardByIdentifierId(task))
  }, [task.scorecard_id])

  useEffect(() => {
    if (task.manualCriteria) {
      setScores(
        task.manualCriteria.reduce((acc, criteria) => {
          acc[criteria.id] = criteria.score
          return acc
        }, {})
      )
      setOpenPanels(
        task.manualCriteria.reduce((acc, criteria) => {
          acc[criteria.id] = true
          return acc
        }, {})
      )
    }
  }, [task.manualCriteria])

  if (!task.manualCriteria) {
    return null
  }

  return (
    <>
      <Accordion>
        {task.manualCriteria.map((manualCriteria) => {
          const baseDataTestId = `manual-score-${manualCriteria.id}`
          return (
            <Fragment key={manualCriteria.id}>
              <AccordionTitle
                key={manualCriteria.id}
                active={openPanels[manualCriteria.id]}
                index={manualCriteria.id}
                className="clickable"
                onClick={() =>
                  setOpenPanels((prevOpenPanels) => ({
                    ...prevOpenPanels,
                    [manualCriteria.id]: !prevOpenPanels[manualCriteria.id],
                  }))
                }
              >
                <div className="flex-align-center small-gap">
                  {openPanels[manualCriteria.id] ? (
                    <IconChevronDown className="icon-svg" />
                  ) : (
                    <IconChevronRight className="icon-svg" />
                  )}
                  <h3
                    className="margin-0 accordion-subheader"
                    data-testid={`${baseDataTestId}-header`}
                  >
                    {manualCriteria.name}
                  </h3>
                </div>
              </AccordionTitle>
              <AccordionContent active={openPanels[manualCriteria.id]}>
                <>
                  {manualCriteria.description && (
                    <p data-testid={`${baseDataTestId}-description`}>
                      {manualCriteria.description}
                    </p>
                  )}
                  {manualCriteria.ai_anchor_text && (
                    <p data-testid={`${baseDataTestId}-ai-text`}>{manualCriteria.ai_anchor_text}</p>
                  )}
                  {manualCriteria.ai_anchor_time && (
                    <JumpToMomentPill
                      onClick={() =>
                        dispatch(
                          updateMediaPlayer({
                            hardSelectedEvent: { timestamp: manualCriteria.ai_anchor_time },
                          })
                        )
                      }
                      formattedTime={formatTime(manualCriteria.ai_anchor_time)}
                      dataTestId={manualCriteria.id}
                    />
                  )}
                  <ScoreForm
                    baseDataTestId={baseDataTestId}
                    isDisabled={!taskIsOpen}
                    score={scores[manualCriteria.id]}
                    setScore={(score) => setScores({ ...scores, [manualCriteria.id]: score })}
                  />
                </>
              </AccordionContent>
            </Fragment>
          )
        })}
      </Accordion>
      <TaskDrawerFooter
        taskIsOpen={taskIsOpen}
        handleClose={handleClose}
        primaryButtonDisabled={
          taskIsOpen && Object.values(scores).some((score) => score === (undefined || null))
        }
        handlePrimaryAction={async () => {
          if (taskIsOpen) {
            await handleUpdateScores()
          }
          handlePrimaryAction(task.id, handleClose)
        }}
        handleSecondaryAction={
          !taskIsOpen ? null : () => handleSecondaryAction(task.id, handleClose)
        }
        primaryActionButtonText={!taskIsOpen ? 'Reopen' : 'Mark as Reviewed'}
        secondaryActionButtonText={!taskIsOpen ? null : 'Dismiss'}
      />
    </>
  )
}
