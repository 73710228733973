import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { IconArrowLeft } from '@tabler/icons-react'

import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import { EventFilters } from './EventFilters'
import {
  CALL_EXPLORER_CRITERIA_FILTER_ENUMS as enums,
  parseCallExplorerParams,
  updateCallExplorerParams,
  removeCallExplorerParams,
  canUserSave,
} from './helpers'

export const QAScoreEditHeader = ({ handleSubmit, handleCancelEdit, score }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { selectedCriteriaFilter } = useSelector((state) => state.callSearch.callExplorer)
  const { qa_disputes_enabled: qaDisputesEnabled } = useSelector(
    (state) => state.currentOrganization
  )
  const { edit_qa: editRTQA, edit_qa_copilot: editQaCopilot } = useSelector(
    (state) => state.currentUser
  )
  const allFilterOptions = [
    { id: enums.ALL, label: 'All Criteria' },
    { id: enums.INCOMPLETE, label: 'Incomplete' },
    { id: enums.COMPLETE, label: 'Complete' },
    { id: enums.PASSING, label: 'Passing' },
    { id: enums.FAILING, label: 'Failing' },
    { id: enums.DISPUTES, label: 'Disputes' },
    { id: enums.NA, label: 'N/A' },
  ]
  const filterOptions = qaDisputesEnabled
    ? allFilterOptions
    : allFilterOptions.filter((option) => option.id !== enums.DISPUTES)

  const handleFilterSelect = (scoreFilter) => {
    updateCallExplorerParams(location, history, { scoreFilter: scoreFilter.id })
    dispatch(setCallExplorerData({ selectedCriteriaFilter: scoreFilter.id }))
    dispatch(setCallExplorerData({ selectedCriteriaFocusIndex: 0 }))
  }
  // when the component unmounts, reset the selectedCriteriaFilter to 'All Criteria'
  useEffect(() => {
    const { scoreFilter } = parseCallExplorerParams(location)

    dispatch(setCallExplorerData({ selectedCriteriaFilter: scoreFilter || enums.ALL }))
  }, [])

  const cancelEdit = () => {
    removeCallExplorerParams(location, history, ['scorecardId', 'scoreFilter', 'criteriaScoreId'])
    handleCancelEdit()
  }

  return (
    <>
      <header className="qa-score-edit-header">
        <div className="flex-align-center gap full-width">
          <Button secondary icon className="svg-button" onClick={cancelEdit}>
            <IconArrowLeft />
          </Button>
          <h2>{score.name}</h2>
        </div>
        <div className="flex-align-center small-gap">
          <Button secondary onClick={cancelEdit}>
            Dismiss
          </Button>
          <Button
            primary
            disabled={!canUserSave(editRTQA, editQaCopilot, score.type)}
            onClick={handleSubmit}
            className="no-wrap"
            type="button"
          >
            Save Score
          </Button>
        </div>
      </header>
      <EventFilters
        title="Filters"
        filterOptions={filterOptions}
        selectedFilters={selectedCriteriaFilter}
        handleFilterSelect={handleFilterSelect}
      />
    </>
  )
}
