import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Loader } from 'semantic-ui-react'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'

import { fetchUsers } from '@/reducers/organizations/organizationUsers.actions'
import { loadConfigs } from '@/actions/server'
import { uploadUserCSV } from '@/reducers/organizations/organizationUsersCSV.actions'
import { fetchOrganizationTags } from '@/reducers/organizations/organizationTags.actions'

import {
  clearCsvUploadResponse,
  updatePreviewUsers,
} from '@/reducers/organizations/organizationUsersCSV.redux'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { toTitleCase } from '@/components/forms/CSVUploadForms/helpers'

// matches new tag regex on back end tag creation endpoint
// used to alert users which tags in the csv are invalid
const tagRegex = /[^0-9a-z-_]+/

const Preview = ({ nextPanel }) => {
  const dispatch = useDispatch()
  const { organizationid } = useParams()

  const configs = useSelector((state) => state.configs)
  const organizationTags = useSelector((state) => state.organizationTags)
  const organizationUsersCSV = useSelector((state) => state.organizationUsersCSV)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (organizationTags.length === 0) {
      dispatch(fetchOrganizationTags({ organizationId: organizationid }))
    }
    if (configs.length === 0) {
      dispatch(loadConfigs({ requestedProperties: 'name' }))
    }

    dispatch(
      fetchUsers({
        queryParam: 'all',
        setLoading,
        organizationId: organizationid,
        dispatchAction: 'organziations/updatePreviewUsers',
      })
    )
  }, [])

  const handleUploadUserCSV = ({ tags, users, newTags, configNames }) => {
    // clears already loaded response data before loading again if user went back
    dispatch(clearCsvUploadResponse())
    dispatch(updatePreviewUsers([]))
    dispatch(
      uploadUserCSV({
        tags,
        users,
        newTags,
        configNames,
        organizationid,
      })
    )
  }

  const {
    tags,
    users,
    headers,
    configNames,
    previewUsers: existingUsers,
    tagCategories,
  } = organizationUsersCSV

  const formattedHeaders = headers.map((header) => {
    return {
      accessor: header,
      label: toTitleCase(header),
    }
  })

  const invalidTags = tags.filter((item) => tagRegex.test(item.tag))
  const existingTags = organizationTags.map((tag) => tag.name)
  const newTags = tags.filter(
    (item) =>
      !existingTags.includes(item.tag) &&
      invalidTags.filter((invalidTag) => invalidTag.tag === item.tag).length === 0
  )
  const existingConfigNames = configs.map((config) => config.name)
  const invalidConfigNames = configNames.filter(
    (configName) => !existingConfigNames.includes(configName)
  )
  const existingUsernames = existingUsers.map((user) => user.username)

  const newUsers = []
  const usersBeingEdited = []
  const restoringUsers = []
  const deletingUsers = []

  // TODO: Make this less verbose, use the proper database properties
  users.forEach((_user) => {
    const user = { ..._user }
    if (!existingUsernames.includes(user.username)) {
      newUsers.push(user.username)
    } else {
      const exUsers = existingUsers.filter(
        (existingUser) => existingUser.username === user.username
      )
      let existingUser = null
      if (exUsers.length > 0) {
        ;[existingUser] = exUsers
      }

      if (user.first_name === '') {
        user.first_name = null
      }

      if (user.first_name !== existingUser.first_name) {
        usersBeingEdited.push(user.username)
        return
      }

      if (user.last_name === '') {
        user.last_name = null
      }

      if (user.last_name !== existingUser.last_name) {
        usersBeingEdited.push(user.username)
        return
      }

      if (user.voip_user_id === '') {
        user.voip_user_id = null
      }

      if (user.voip_user_id !== existingUser.code) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.balto_cloud_access &&
        user.balto_cloud_access.toLowerCase() === 'yes' &&
        !existingUser.password
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.balto_cloud_access &&
        user.balto_cloud_access.toLowerCase() === 'no' &&
        existingUser.password
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.playbook_edit_access &&
        user.playbook_edit_access.toLowerCase() === 'yes' &&
        !existingUser.edit_config
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.playbook_edit_access &&
        user.playbook_edit_access.toLowerCase() === 'no' &&
        existingUser.edit_config
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.user_management_access &&
        user.user_management_access.toLowerCase() === 'yes' &&
        !existingUser.edit_users
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.user_management_access &&
        user.user_management_access.toLowerCase() === 'no' &&
        existingUser.edit_users
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      if (user.user_status && user.user_status.toLowerCase() === 'active' && existingUser.deleted) {
        usersBeingEdited.push(user.username)
        return
      }

      if (
        user.user_status &&
        user.user_status.toLowerCase() === 'deleted' &&
        !existingUser.deleted
      ) {
        usersBeingEdited.push(user.username)
        return
      }

      // tags.
      const existingUserTags = existingUser.tags.map((tag) => tag.name.trim().toLowerCase())

      let userTags = []

      if (user.uncategorized_tags && user.uncategorized_tags.split(',').length > 0) {
        userTags = user.uncategorized_tags.split(',').map((tag) => tag.trim().toLowerCase())
      }

      for (const category of tagCategories) {
        if (user[category.name] && user[category.name].trim()) {
          userTags.push(user[category.name].trim().toLowerCase())
        }
      }

      userTags = userTags.filter((tag) => tag && tag !== '')

      for (const tag of userTags) {
        if (!existingUserTags.includes(tag)) {
          usersBeingEdited.push(user.username)
          return
        }
      }

      for (const tag of existingUserTags) {
        if (!userTags.includes(tag)) {
          usersBeingEdited.push(user.username)
          return
        }
      }

      // playbook.
      // check if a new playbook has assigned.
      if (!existingUser.config_cid && user.playbook !== '') {
        usersBeingEdited.push(user.username)
        return
      }

      // check if playbook has been removed.
      if (existingUser.config_cid && user.playbook === '') {
        usersBeingEdited.push(user.username)
        return
      }

      // check if playbook has changed.
      if (user.playbook) {
        let selectedPlaybook = configs.filter(
          (config) => config.name.trim() === user.playbook.trim()
        )
        if (selectedPlaybook.length > 0) {
          ;[selectedPlaybook] = selectedPlaybook
          if (
            existingUser.config_cid &&
            selectedPlaybook &&
            selectedPlaybook.cid !== existingUser.config_cid
          ) {
            usersBeingEdited.push(user.username)
            return
          }
        }
      }
    }

    if (
      user.user_status &&
      user.user_status.toLowerCase() === 'active' &&
      !newUsers.includes(user.username)
    ) {
      const item = existingUsers.filter((_user) => user.username === _user.username)
      if (item.length > 0 && item[0].deleted) {
        restoringUsers.push(user.username)
      }
    }

    if (
      user.user_status &&
      user.user_status.toLowerCase() === 'deleted' &&
      !newUsers.includes(user.username)
    ) {
      const item = existingUsers.filter((_user) => user.username === _user.username)
      if (item.length > 0 && !item[0].deleted) {
        deletingUsers.push(user.username)
      }
    }
  })

  // TODO: Clean this up and make it into a table
  return (
    <div className="flex-column gap">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        {newTags.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconCheck className="icon-svg status-success" />
            <label style={{ fontWeight: 'bold' }}>New tags: </label>
            <p>{newTags.map((item) => item.tag).join(', ')}</p>
          </span>
        )}
        {invalidTags.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconAlertTriangle className="icon-svg status-critical" />
            <label style={{ fontWeight: 'bold' }}>Invalid tags: </label>
            <p>{invalidTags.map((item) => item.tag).join(', ')}</p>
          </span>
        )}
        {invalidConfigNames.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconAlertTriangle className="icon-svg status-critical" />
            <label style={{ fontWeight: 'bold' }}>Unable to find Playbooks: </label>
            <p>{invalidConfigNames.join(', ')}</p>
          </span>
        )}
        {newUsers.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconCheck className="icon-svg status-success" />
            <label style={{ fontWeight: 'bold' }}>
              {newUsers.length > 10 ? `Creating ${newUsers.length} users` : 'New Users: '}
            </label>
            {newUsers.length < 10 && <p>{newUsers.join(', ')}</p>}
          </span>
        )}
        {usersBeingEdited.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconCheck className="icon-svg status-success" />
            <label style={{ fontWeight: 'bold' }}>
              {usersBeingEdited.length > 10
                ? `Editing ${usersBeingEdited.length} users`
                : 'Editing Users: '}
            </label>
            {usersBeingEdited.length < 10 && <p>{usersBeingEdited.join(', ')}</p>}
          </span>
        )}
        {restoringUsers.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconCheck className="icon-svg status-success" />
            <label style={{ fontWeight: 'bold' }}>
              {restoringUsers.length > 10
                ? `Restoring ${restoringUsers.length} users`
                : 'Restoring Users: '}
            </label>
            {restoringUsers.length < 10 && <p>{restoringUsers.join(', ')}</p>}
          </span>
        )}
        {deletingUsers.length > 0 && (
          <span className="flex-align-center small-gap">
            <IconCheck className="icon-svg status-success" />
            <label style={{ fontWeight: 'bold' }}>
              {deletingUsers.length > 10
                ? `Deleting ${deletingUsers.length} users`
                : 'Deleting Users: '}
            </label>
            {deletingUsers.length < 10 && <p>{deletingUsers.join(', ')}</p>}
          </span>
        )}
      </div>

      {loading && <Loader active inline="centered" size="massive" />}

      {!loading && <AdvancedTable rows={users} columns={formattedHeaders} index="uuid" />}

      <div className="flex-center">
        <Button
          disabled={loading}
          primary
          onClick={() => {
            handleUploadUserCSV({
              tags,
              users,
              newTags,
              configNames,
            })
            nextPanel()
          }}
        >
          Upload CSV
        </Button>
      </div>
    </div>
  )
}

export default Preview
