import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'

import { showFirstResult } from '@/utils/helpers'

import { MultiSelect } from '../../forms/MultiSelect'
import { FilterColumnButton } from '../FilterColumnButton'
import { FilterPill } from '../FilterPill'
import { FilterTypePopup } from './FilterTypePopup'
import { getDynamicOptions } from '../filter.helpers'

export const FilterTypeMultiSelect = ({
  schema = {},
  rows,
  value,
  loading,
  updateSearch,
  columnAccessor,
}) => {
  const [open, setOpen] = useState(false)
  const options = schema.options || getDynamicOptions(rows, schema)

  return (
    <FilterTypePopup
      open={open}
      setOpen={setOpen}
      trigger={
        columnAccessor ? (
          <FilterColumnButton
            type="multiSelect"
            columnAccessor={columnAccessor}
            value={value}
            hasValue={!isEmpty(value)}
            displayLabel={showFirstResult(value, schema.label)}
          />
        ) : (
          <FilterPill
            clickable
            isClearable={!isEmpty(value)}
            handleRemoveFilter={(event) => {
              event.stopPropagation()

              updateSearch(schema.accessor, [])
            }}
            loading={loading}
            label={schema.label}
            value={showFirstResult(value, null)}
          />
        )
      }
    >
      <MultiSelect
        autoFocus
        menuIsOpen
        isSelectAll={!schema.options}
        showFullOptions
        condenseOptions={false}
        placeholderPill={`All ${pluralize(schema.label)}`}
        label={schema.label}
        options={options}
        // only show length if dynamic options
        optionsLength={schema.options ? undefined : options.length}
        loading={loading}
        value={value}
        onChange={(option) => {
          updateSearch(schema.accessor, option)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()

            setOpen(false)
          }
        }}
      />
    </FilterTypePopup>
  )
}
