import React, { useRef, useState, useEffect } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { Field } from 'formik'
import { toNumber, isEmpty } from 'lodash'
import { IconCheck, IconCalendarCog, IconSparkles } from '@tabler/icons-react'
import classNames from 'classnames'
import format from 'date-fns/format'
import { differenceInDays } from 'date-fns'

import { determineGradient } from '@/components/helpers'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { SuggestedQuestions } from '@/components/SuggestedQuestions'
import { Pill } from '@/components/pills/Pill'
import { COACHING_QUESTIONS } from '@/reducers/tasks/tasks.constants'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'

import './CoachingSelfService.scss'

export const CoachingSelfServiceForm = ({
  values,
  loading,
  suggestedAgents = [],
  handleSubmit,
  setFieldValue,
  data,
}) => {
  const [dateInvalid, setDateInvalid] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const textAreaRef = useRef(null)
  const filteredAgents = data.agents.filter(
    (a) => !suggestedAgents.some((sa) => sa.value === a.value)
  )

  const handleTextAreaClick = () => {
    setHasFocus(true)
  }

  const handleTextAreaBlur = () => {
    setHasFocus(false)
  }

  const formDisabledContent = (values) => {
    if (loading.coachingRequest) {
      return 'Generating...'
    }
    if (!values.agent) {
      return 'Please select an agent'
    }
    if (!values.question) {
      return 'Please describe your coaching focus'
    }
    return ''
  }

  const handleUpdateRange = ({ selection }) => {
    const currentStartDate = format(selection.startDate, "yyyy-MM-dd'T00:00:00'xxx")
    const currentEndDate = format(selection.endDate, "yyyy-MM-dd'T23:59:59'xxx")

    const datesDiff = differenceInDays(selection.endDate, selection.startDate)

    if (datesDiff > 62) {
      setDateInvalid(true)
    } else {
      setDateInvalid(false)
    }

    setFieldValue('startDate', currentStartDate)
    setFieldValue('endDate', currentEndDate)
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
    if (hasFocus && textAreaRef.current) {
      textAreaRef.current.focus()
    }
  }, [textAreaRef.current, values.question, hasFocus])

  return (
    <>
      <div>
        <Form
          className="coaching-selfservice-form"
          data-test-id="coaching-selfservice-form"
          onSubmit={handleSubmit}
        >
          <div className="mb">
            <div>
              <div className="label-style">Coach an Agent</div>
              <div className="flex-align-center small-gap flex-wrap mb">
                {!isEmpty(suggestedAgents) &&
                  suggestedAgents.map((suggestedAgent, index) => {
                    const isSelected = values.agent === suggestedAgent.value
                    const gradient = determineGradient(toNumber(index || 0))

                    return (
                      <Pill
                        key={suggestedAgent.value}
                        clickable
                        onClick={() => {
                          if (values.agent === suggestedAgent.value) {
                            setFieldValue('agent', null)
                          } else {
                            setFieldValue('agent', suggestedAgent.value)
                          }
                        }}
                        data-testid={`${suggestedAgent.value}-pill`}
                        selected={isSelected}
                        gradient={!isSelected && gradient}
                      >
                        {isSelected && <IconCheck className="status-brand" />}
                        {suggestedAgent.label}
                      </Pill>
                    )
                  })}
                {!suggestedAgents.some((agent) => agent.value === values.agent) && (
                  <FilterTypeSelect
                    autoFocus
                    loading={loading.agents}
                    schema={{ accessor: 'agent', label: 'Agent', options: filteredAgents }}
                    value={values.agent}
                    updateSearch={(accessor, value) => {
                      setFieldValue(accessor, value)
                    }}
                    showLabel={false}
                    selected={values.agent}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="label-style">Describe Your Coaching Focus</div>
          <div className={classNames('fake-textarea', { 'has-focus': hasFocus })}>
            <Field
              name="question"
              placeholder="What do you want to coach on?"
              as="textarea"
              rows={5}
              onBlur={handleTextAreaBlur}
              onClick={handleTextAreaClick}
              innerRef={textAreaRef}
              data-testid="textArea-question"
            />

            <div className="flex-align-center flex-space-between">
              <DateRangePickerReusable
                data-testid="filter-type-date-range"
                startDate={values.startDate}
                endDate={values.endDate}
                onChange={handleUpdateRange}
                shouldNotResetOnCancel
                error={dateInvalid}
                disableSaveButton={dateInvalid}
                friendlyLabel
                basicLabel
                isFilterPill
                icon={<IconCalendarCog />}
                showFilterLabel={false}
              />
              <ConditionalTooltip
                condition={!!formDisabledContent(values)}
                content={formDisabledContent(values)}
              >
                <Button
                  type="submit"
                  primary
                  disabled={!values.agent || !values.question}
                  loading={loading.coachingRequest}
                  data-testid="coaching-selfservice-submit"
                  className="no-wrap svg-button"
                  icon
                >
                  <IconSparkles />
                  Generate Session
                </Button>
              </ConditionalTooltip>
            </div>
          </div>
        </Form>
      </div>
      <SuggestedQuestions
        initialCategory="sales"
        questions={COACHING_QUESTIONS}
        onQuestionClick={(value) => setFieldValue('question', value)}
      />
    </>
  )
}
