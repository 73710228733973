import moment from 'moment'

import { apiService } from '@/api'
import { closeAlert } from '@/reducers/realtime/realtime.actions'
import { fetchActivityLogUsers } from '@/reducers/realtime/activityLog/activityLog.actions'
import {
  OPEN_RTC_SOCKET,
  CLOSE_RTC_SOCKET,
  UPDATE_SOCKET_STATUS,
  REFRESH_USERS_ALERTS,
  SEND_CHAT_TO_DESKTOP,
} from '@/reducers/realtime/realtimeSocket.redux'
import {
  UPDATE_ALERT_TRANSCRIPTION,
  LOAD_ALERTS,
} from '@/reducers/realtime/alerts/realtimeUserAlerts.redux'
import { END_CHAT, ADD_MESSAGE_TO_CHAT } from '@/reducers/realtime/realtimeChats.redux'
import { LOGOUT_USER } from '@/reducers/auth/currentUser.redux'
import RTCNotifySound from '@/assets/audio/RTCNotifySound.mp3'

// NOTE: Might require a fallback to use one client per tab and no shared worker if we need to
// support Safari at some point
export const rtcSocketMiddleware = (worker, broadcast) => (params) => (next) => (action) => {
  const { dispatch, getState } = params
  const { type: actionType, ...actionData } = action
  const { username, token, user_id } = getState().currentUser

  switch (actionType) {
    case OPEN_RTC_SOCKET:
      worker.init()
      broadcast.init()

      if (worker.unsupportedBrowser || broadcast.unsupportedBrowser) {
        return next(action)
      }

      broadcast.on('message', ({ data }) => {
        const { type: broadcastedType, ...broadcastedData } = data

        switch (broadcastedType) {
          // Whenever the connection status changes, the RTC alert sidebar handles the updates
          case 'CONNECTION_STATUS':
            dispatch({
              type: UPDATE_SOCKET_STATUS,
              isOpen: broadcastedData.isOpen,
              maxRetriesExceeded: broadcastedData.maxRetriesExceeded,
            })
            break

          // If a message comes through on the Broadcast Channel, handle it here
          // Can this be simplified by making each action + side effect have a type, as opposed
          // to each condition being based on what properties the message might have?
          case 'SOCKET_RECEIVED_MESSAGE': {
            const { message } = broadcastedData
            // Since the broadcasted messages don't have types, we'll make types to make this easier to read
            // NOTE: Since this is using an "else if" flow, each conditional is NOT guaranteed to be correct on its own
            const CALL_END = message.data?.stage === 'CHECK_END_CALL'
            const INCOMING_ALERT = message.processing_url
            const INCOMING_CHAT_MESSAGE = message.from_user_id && message.to_user_id
            const UPDATE_CHAT_TRANSCRIPTION =
              message.data?.transcript &&
              message.user === getState().realtimeUserAlerts?.listeningToUsername
            const RAISED_HAND = message?.event === 'raised_hand'

            // If call end event comes through, end the chat and update activity log
            if (CALL_END) {
              if (window.location?.pathname === '/realtime_coaching/activity-log') {
                dispatch(fetchActivityLogUsers({ alertedUserId: user_id }))
              }
              dispatch(closeAlert({ username: message?.user }))
              dispatch({ type: END_CHAT, username: message?.user })
            }
            // Show alert when message comes in and play notification sound if audible is enabled
            else if (INCOMING_ALERT) {
              const state = getState()
              const { listeningToUsername } = state.realtimeUserAlerts
              const { listeningToAgent } = state.commandCenter
              const isInLiveListen = Boolean(listeningToUsername || listeningToAgent)

              if (message.audible && !isInLiveListen) {
                new Audio(RTCNotifySound).play()
              }
              dispatch({ type: LOAD_ALERTS, alert: message })
            }
            // If manager or agent sends a message
            else if (INCOMING_CHAT_MESSAGE) {
              dispatch({
                type: ADD_MESSAGE_TO_CHAT,
                agentUsername: message.agent_username,
                message: message.message,
                isMessageFromManager: false,
                date: moment().format('LT'),
              })
            }
            // Add transcription
            else if (UPDATE_CHAT_TRANSCRIPTION) {
              const formattedTranscription = {
                text: message.data?.transcript || '',
                side: message.data?.side === 'me' ? 0 : 1,
                time: new Date(),
              }
              dispatch({ type: UPDATE_ALERT_TRANSCRIPTION, transcription: formattedTranscription })
            } else if (RAISED_HAND) {
              dispatch({
                type: LOAD_ALERTS,
                alert: {
                  name: 'Raised Hand',
                  type: 'negative',
                  processing_url: 'raised_hand',
                  call_id: message.call_id,
                  alert_id: 'raised_hand',
                  user_id: message.agent_id,
                  manager_id: message.manager_id,
                  username: message.agent_username,
                  manager_username: message.manager_username,
                  manager_first_name: message.manager_first_name,
                  manager_last_name: message.manager_last_name,
                  user_first_name: message.agent_first_name,
                  user_last_name: message.agent_last_name,
                },
              })
            }
            break
          }

          default:
            break
        }
      })

      worker.post({
        token,
        username,
        type: actionType,
        url: `${apiService.rtc_websocket}/ws/realtime_coaching`,
      })
      break

    case REFRESH_USERS_ALERTS:
      worker.post({ type: actionType })
      break

    case SEND_CHAT_TO_DESKTOP:
      worker.post({ type: actionType, chatData: actionData })
      break

    case CLOSE_RTC_SOCKET:
    case LOGOUT_USER:
      if (worker.sharedWorker) {
        worker.post({ type: CLOSE_RTC_SOCKET })
      }

      if (broadcast.broadcastChannel) {
        broadcast.close()
      }

      break

    default:
      break
  }

  return next(action)
}
