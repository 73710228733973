import React from 'react'
import { isFunction, truncate } from 'lodash'
import pluralize from 'pluralize'

import { Pill } from '@/components/pills/Pill'

import { FilterPillAction } from './FilterPillAction'

import './FilterPill.scss'

export const FilterPill = ({
  accessor,
  label,
  handleRemoveFilter,
  format,
  value = '',
  isClearable = true,
  loading,
  showAllOnEmpty = false,
  showAllOnEmptyLabel = null,
  icon,
  showLabel = true,
  ...props
}) => {
  const displayValue = isFunction(format) ? format(accessor) : value
  const hasSelection = !!displayValue
  const isGhosted = !hasSelection && !showAllOnEmpty

  return (
    <Pill className="filter-pill-button" transparent ghost={isGhosted} {...props}>
      {icon || (
        <FilterPillAction
          showAllOnEmpty={showAllOnEmpty}
          hasSelection={hasSelection}
          handleRemoveFilter={handleRemoveFilter}
          loading={loading}
          isClearable={isClearable}
          showLabel={showLabel}
        />
      )}
      {showLabel && (
        <strong>
          {label}
          {(hasSelection || showAllOnEmpty) && ':'}
        </strong>
      )}
      {showAllOnEmpty && !hasSelection
        ? showAllOnEmptyLabel || `All ${pluralize(label)}`
        : hasSelection && <span>{truncate(displayValue, { length: 25 })}</span>}
    </Pill>
  )
}
