import cloneDeep from 'lodash/cloneDeep'

export const cloudAccessSchema = {
  name: 'access',
  type: 'multiSelect',
  condenseOptions: false,
  showFullOptions: true,
  label: 'Balto Cloud Access',
  options: [
    { label: 'Balto Cloud Access', value: 'cloud_access' },
    { label: 'Playbook Management', value: 'edit_config' },
    { label: 'User Management', value: 'edit_users' },
    { label: 'Leaderboard Management', value: 'edit_leaderboard' },
    { label: 'QA Access', value: 'edit_qa' },
    { label: 'QA Copilot Access', value: 'edit_qa_copilot' },
    { label: 'Real-Time Coaching Access', value: 'realtime_coaching_access' },
    { label: 'Midcall Playbook Switching', value: 'midcall_playbook_switching_enabled' },
    { label: 'Hierarchy Manager', value: 'hierarchy_manager' },
    { label: 'Prompt Editor', value: 'prompt_editor' },
  ],
}

export const buildSchemaForUserForm = (
  configs,
  qaEnabled,
  qaCopilotEnabled,
  realtimeCoachingAccessEnabled,
  tagCategories,
  tags,
  orgDesktopEnterpriseEnabled,
  flags,
  isBaltoAdmin,
  isHierarchyManager
) => {
  const newCloudAccessSchema = cloneDeep(cloudAccessSchema)
  const configOptions = configs.map((config) => ({
    value: config.cid,
    label: config.name,
  }))

  const userDetailsSchema = [
    { name: 'first_name', type: 'text', label: 'First Name', required: true },
    { name: 'last_name', type: 'text', label: 'Last Name', required: true },
    { name: 'username', type: 'text', label: 'Email', required: true },
    {
      name: 'config_cid',
      type: 'select',
      label: 'Playbook',
      required: true,
      options: configOptions,
    },
    {
      name: 'deleted',
      type: 'select',
      label: 'Agent Status',
      required: true,
      options: [
        { value: false, label: 'Active' },
        { value: true, label: 'Deleted' },
      ],
    },
  ]

  if (orgDesktopEnterpriseEnabled) {
    const enterpriseSchema = {
      name: 'desktop_enterprise_enabled',
      type: 'select',
      label: 'Desktop Mode',
      options: [
        { value: false, label: 'Balto Classic' },
        { value: true, label: 'Balto Enterprise' },
      ],
    }
    userDetailsSchema.push(enterpriseSchema)
  }

  if (flags?.cloudTopAgentToggle2022) {
    const topAgentSchema = {
      name: 'is_top_agent',
      label: 'Enable Content Customization',
      type: 'checkbox',
      toggle: true,
    }
    userDetailsSchema.push(topAgentSchema)
  }

  const tagSchema = tagCategories.map((category) => {
    return {
      name: category.name.toLowerCase(),
      type: 'select',
      label: category.name,
      options: tags
        .filter((tag) => tag.tag_category_id === category.id)
        .map((tag) => ({
          value: tag.id,
          label: tag.name,
        })),
    }
  })

  if (isBaltoAdmin) {
    const screenCaptureSchema = {
      name: 'screen_capture_enabled',
      type: 'select',
      label: 'Screen Capture',
      nullable: true,
      defaultValue: null,
      options: [
        {
          value: null,
          label: 'Organization Default',
        },
        {
          value: true,
          label: 'True',
        },
        {
          value: false,
          label: 'False',
        },
      ],
    }
    userDetailsSchema.push(screenCaptureSchema)
  }

  const uncategorizedTagsSchema =
    tags.length > 0
      ? [
          {
            name: 'uncategorizedTags',
            type: 'multiSelect',
            condenseOptions: false,
            label: 'Uncategorized Tags',
            options: tags
              .filter((tag) => tag.tag_category_id == null)
              .map((tag) => ({
                value: tag.id,
                label: tag.name,
              })),
          },
        ]
      : []

  if (!qaEnabled) {
    newCloudAccessSchema.options = newCloudAccessSchema.options.filter(
      (option) => option.value !== 'edit_qa'
    )
  }

  if (!qaCopilotEnabled) {
    newCloudAccessSchema.options = newCloudAccessSchema.options.filter(
      (option) => option.value !== 'edit_qa_copilot'
    )
  }

  if (!isBaltoAdmin) {
    newCloudAccessSchema.options = newCloudAccessSchema.options.filter(
      (option) => option.value !== 'prompt_editor'
    )
  }

  if (!realtimeCoachingAccessEnabled) {
    newCloudAccessSchema.options = newCloudAccessSchema.options.filter(
      (option) => option.value !== 'realtime_coaching_access'
    )
  }

  if (!isHierarchyManager && !isBaltoAdmin) {
    newCloudAccessSchema.options = newCloudAccessSchema.options.filter(
      (option) => option.value !== 'hierarchy_manager'
    )
  }

  const voipSchema = [
    {
      name: 'code',
      type: 'text',
      label: 'VoIP User ID',
    },
  ]

  return [
    ...userDetailsSchema,
    ...[newCloudAccessSchema],
    ...tagSchema,
    ...uncategorizedTagsSchema,
    ...voipSchema,
  ]
}

export const buildExistingValuesForUserForm = (user, tagCategories) => {
  const existingUserValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    is_top_agent: user.is_top_agent,
    username: user.username,
    config_cid: user.config_cid,
    deleted: user.deleted,
    code: user.code || '',
    desktop_enterprise_enabled: user.desktop_enterprise_enabled,
    screen_capture_enabled: user.screen_capture_enabled,
  }

  const existingTagValues = user.tags
    .filter((tag) => tag.tag_category_id != null)
    .map((tag) => {
      const tagCategory = tagCategories.find((category) => category.id === tag.tag_category_id)
      const existingTag = {}
      existingTag[tagCategory.name.toLowerCase()] = tag.id

      return existingTag
    })

  const existingUncategorizedTags = {
    uncategorizedTags: user.tags
      .filter((tag) => tag.tag_category_id == null)
      .map((tag) => ({ value: tag.id, label: tag.name })),
  }

  const existingCloudAccess = {
    access: cloudAccessSchema.options.filter((option) => {
      if (option.value === 'cloud_access') {
        return user.password ? option : null
      }
      return user[option.value] ? option : null
    }),
  }

  let existingValues = {
    ...existingUserValues,
    ...existingUncategorizedTags,
    ...existingCloudAccess,
  }

  existingValues = Object.assign({}, existingValues, ...existingTagValues)

  return existingValues
}
