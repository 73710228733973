import React, { useEffect } from 'react'
import { fetchFeatureFeedback } from '@/reducers/featureFeedback/featureFeedback.actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Widget from '@/components/widget/Widget'
import { FeedbackTable } from './FeedbackTable'

const FeatureFeedbackPage = () => {
  const dispatch = useDispatch()
  const { loading, data } = useSelector((state) => state.featureFeedback)

  useEffect(() => {
    dispatch(fetchFeatureFeedback())
  }, [])

  const columns = [
    {
      accessor: 'username',
      label: 'User',
      noWrap: true,
    },
    {
      accessor: 'organization_name',
      label: 'Organization',
      noWrap: true,
    },
    {
      accessor: 'created_at',
      label: 'Date',
      noWrap: true,
      format: (date) => moment.utc(date).local().format('MMM Do, h:mm A'),
    },
    {
      accessor: 'feature',
      label: 'Feature',
      noWrap: true,
    },
    {
      accessor: 'sentiment',
      label: 'Sentiment',
      noWrap: true,
      format: (sentiment) => (sentiment ? 'Positive' : 'Negative'),
    },
    {
      accessor: 'category',
      label: 'Category',
      noWrap: true,
    },
    {
      accessor: 'comment',
      label: 'Comment',
      noWrap: true,
    },
    {
      accessor: 'call_id',
      label: 'Call ID',
      noWrap: true,
    },
    {
      accessor: 'additional_info',
      label: 'Additional Info',
      noWrap: true,
    },
  ]

  return (
    <div>
      <Widget
        widgetId="feedback-widget"
        label="Feature Feedback"
        subtitle="Feedback received in last 30 days"
        showCsvDownload
        displayBackground={false}
      >
        <FeedbackTable loading={loading.feedback} columns={columns} rows={data.feedback} />
      </Widget>
    </div>
  )
}

export default FeatureFeedbackPage
