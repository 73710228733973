import React from 'react'
import { useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { isEmpty, startCase } from 'lodash'

import {
  COACHING_TASK_TYPE,
  QA_DISPUTE_TASK_TYPE,
  COMPLIANCE_TASK_TYPE,
  QA_MANUAL_SCORE_TASK_TYPE,
  READY_FOR_REVIEW,
} from '@/reducers/tasks/tasks.constants'

import { Metadata } from './Metadata'
import { TaskComments } from '../components/TaskComments'
import { CallEvidence } from '../components/CallEvidence'
import { DisputeDrawer } from './DisputeDrawer'
import { ManualScoreDrawer } from './ManualScoreDrawer'
import { TaskDrawerFooter } from './TaskDrawerFooter'

import './TaskDrawer.scss'

export const TaskDrawer = ({
  commentsEnabled = false,
  handleClose,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionButtonText,
  secondaryActionButtonText,
}) => {
  const { task, loading } = useSelector((state) => state.tasks)
  const agent = `${task?.agent_first_name} ${task?.agent_last_name}`
  const createdBy = task?.created_by
    ? `${task?.created_by_first_name} ${task?.created_by_last_name}`
    : null
  const taskIsOpen = task?.status === READY_FOR_REVIEW

  const primaryAction = () => handlePrimaryAction(task.id, handleClose)
  const secondaryAction = taskIsOpen ? () => handleSecondaryAction(task.id, handleClose) : null

  const getTaskDrawerContent = (taskType) => {
    switch (taskType) {
      case QA_DISPUTE_TASK_TYPE:
        return (
          <DisputeDrawer
            taskIsOpen={taskIsOpen}
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
            handleClose={handleClose}
            agent={agent}
            task={task}
          />
        )
      case QA_MANUAL_SCORE_TASK_TYPE:
        return (
          <ManualScoreDrawer
            taskIsOpen={taskIsOpen}
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
            handleClose={handleClose}
            agent={agent}
            task={task}
          />
        )
      default:
        return (
          <TaskDrawerFooter
            taskIsOpen={taskIsOpen}
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
            handleClose={handleClose}
            primaryActionButtonText={primaryActionButtonText}
            secondaryActionButtonText={secondaryActionButtonText}
          />
        )
    }
  }

  const getSubheader = (taskType) => {
    switch (taskType) {
      case COACHING_TASK_TYPE:
        return 'Coaching Opportunity'
      case QA_DISPUTE_TASK_TYPE:
      case QA_MANUAL_SCORE_TASK_TYPE:
        return 'Call & Quick Links'
      case COMPLIANCE_TASK_TYPE:
        return 'Exception Reason'
      default:
        return null
    }
  }

  if (isEmpty(task)) {
    return null
  }

  if (loading.task) {
    return (
      <div className="task-drawer">
        <Loader active />
      </div>
    )
  }

  return (
    <div className="task-drawer" data-testid="task-drawer">
      <h2>{task.name}</h2>
      <Metadata
        agent={agent}
        createdAt={task.created_at}
        createdBy={createdBy}
        isAiGenerated={task.ai_generated}
        type={task.type}
        status={task.status}
      />
      <div className="task-drawer__content">
        {task.description && (
          <>
            <h3>Description</h3>
            <p>{task.description}</p>
          </>
        )}
        <h3>{getSubheader(task.type)}</h3>
        {!isEmpty(task.evidence) && <CallEvidence evidence={task.evidence} />}
        {getTaskDrawerContent(task.type)}
      </div>
      {commentsEnabled && (
        <div className="task-drawer__comments">
          <h3>{startCase(task.type)} Notes</h3>
          <TaskComments
            taskType={task.type}
            taskId={task.id}
            commentType={`${startCase(task.type)} Note`}
            commentProperty="note_text"
          />
        </div>
      )}
    </div>
  )
}
