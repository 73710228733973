import React from 'react'
import classNames from 'classnames'
import { Popup } from 'semantic-ui-react'
import { startsWith } from 'lodash'
import { IconPlayerPlay } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { convertAgentNameToInitials, formatDuration } from '@/utils/helpers'

export const ConversationBlock = ({
  side,
  data,
  showHypothesis,
  highlighted = false,
  searchQuery = '',
  hardSelectedEvent,
  metadata,
}) => {
  const initials = convertAgentNameToInitials(metadata.agent_name)

  const highlightText = (text) => {
    try {
      if (!searchQuery) return text

      const escapedQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(`(${escapedQuery})`, 'gi')

      return text.split(regex).map((part, i) => {
        const key = `${part.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${i}`
        return regex.test(part) ? (
          <mark key={key} className="transcript-highlight">
            {part}
          </mark>
        ) : (
          part
        )
      })
    } catch (error) {
      return text
    }
  }

  const firstTimestamp = data[0].timestamp
  const blockStartTime = formatDuration(firstTimestamp)
  const formattedTimestamp = startsWith(blockStartTime, '00:')
    ? blockStartTime.slice(3)
    : blockStartTime
  // older call transcripts may not have hypothesis available
  const hasHypothesis = Boolean(data.find((snippet) => snippet.hyp?.length > 0))
  const fullText = data.map((snippet) => snippet.text).join(' ')
  const highlightedParts = highlightText(fullText)

  const conversation = data.map((snippet, index) => {
    const { timestamp: snippetTimestamp, text: snippetText } = snippet
    const matchesTimestamp = hardSelectedEvent?.timestamp === snippetTimestamp

    return (
      <span
        // eslint-disable-next-line react/no-array-index-key
        key={`transcript-${snippetTimestamp}-${snippetText}-${index}`}
        data-timestamp={`transcript-${snippetTimestamp}`}
      >
        {index === 0 ? highlightedParts : null}{' '}
        {matchesTimestamp && (
          <Popup
            inverted
            position="top center"
            trigger={
              <span
                data-testid="transcript-event-trigger"
                id="transcript-event-marker"
                className="transcript-event-trigger"
              />
            }
          >
            <em>{hardSelectedEvent.displayText}</em> event triggered
          </Popup>
        )}
      </span>
    )
  })

  const hypothesis = data.map((snippet) => {
    return (
      <span
        key={`hypothesis-${snippet.timestamp}`}
        data-timestamp={`hypothesis-${snippet.timestamp}`}
        className={classNames({ 'is-diff': snippet.is_diff })}
      >
        {snippet.hyp}{' '}
      </span>
    )
  })

  return (
    <div className="transcript-block" key={firstTimestamp}>
      <div className="transcript-side-container">
        {(side === 1 || side === 0) && (
          <div
            className={classNames('transcript-side', { customer: side === 1, agent: side === 0 })}
          >
            <div>
              {side === 1 ? (
                <Pill small circular emphasized />
              ) : (
                <Pill small circular brand>
                  {initials}
                </Pill>
              )}
            </div>
            <div>{side === 1 ? 'Customer' : metadata.agent_name}</div>
            <div className="transcript-start-time">
              <div>{formattedTimestamp}</div>
              <IconPlayerPlay />
            </div>
          </div>
        )}
        <p className={classNames('transcript-conversation', { highlighted })}>{conversation}</p>
        {showHypothesis && hasHypothesis && (
          <>
            <p className="hypothesis-label">HYPOTHESIS</p>
            <p className="transcript-hypothesis">{hypothesis}</p>
          </>
        )}
      </div>
    </div>
  )
}
