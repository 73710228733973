import React from 'react'
import { Form, FormField, Radio } from 'semantic-ui-react'

export const ScoreForm = ({ baseDataTestId, isDisabled, score, setScore }) => {
  return (
    <Form>
      <div className="flex-space-between mb">
        <FormField>
          <Radio
            label="Pass"
            name="radioGroup"
            value="pass"
            checked={score === 1}
            className="dispute-radio pass"
            data-testid={`${baseDataTestId}-radio-pass`}
            onChange={() => setScore(1)}
            disabled={isDisabled}
          />
        </FormField>
        <FormField>
          <Radio
            label="Fail"
            name="radioGroup"
            value="fail"
            className="dispute-radio fail"
            data-testid={`${baseDataTestId}-radio-fail`}
            checked={score === 0}
            onChange={() => setScore(0)}
            disabled={isDisabled}
          />
        </FormField>
        <FormField>
          <Radio
            label="N/A"
            name="radioGroup"
            value="n/a"
            className="dispute-radio"
            data-testid={`${baseDataTestId}-radio-na`}
            checked={score === -1}
            onChange={() => setScore(-1)}
            disabled={isDisabled}
          />
        </FormField>
      </div>
    </Form>
  )
}
