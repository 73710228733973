import React, { Fragment, useEffect, useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { startCase } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { UserInitial } from '@/components/UserInitial'
import { ListeningStatus } from '@/components/ListeningStatus'
import {
  attemptListeningToCall,
  stopListeningToCall,
} from '@/reducers/commandCenter/commandCenter.redux'
import { IconHeadphones } from '@tabler/icons-react'

import { fetchAgentWithCallStatus } from '@/reducers/commandCenter/commandCenter.actions'
import { stopListeningToAlert } from '@/reducers/realtime/realtime.actions'
import { ExternalLink } from '@/components/ExternalLink'
import { formatTime } from '@/utils/helpers'
import { TimeElapsed } from './TimeElapsed'
import { SoundWaveAnimation } from './SoundWaveAnimation'

export const AgentCallStatusEntry = ({ agent, index }) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const { listeningToAgent } = useSelector((state) => state.commandCenter)
  const [callIdsRetrieved, setCallIdsRetrieved] = useState([])
  const agentFullName = `${agent.agent_first_name} ${agent.agent_last_name}`
  const { username: currentUser } = useSelector((state) => state.currentUser)
  const userHadCalls = agent.call_data?.call_status !== 'no_calls_today'
  const isActivelyListening =
    agent.call_data?.call_processing_url && listeningToAgent === agent.agent_username

  // this useEffect handles the case where you are listening to an agent, and the listen is stopped
  // from the BE. This will then clean up the listening state in the FE
  useEffect(() => {
    if (listeningToAgent === agent.agent_username && !agent.call_data?.call_processing_url) {
      dispatch(stopListeningToCall())
    }
  }, [agent.call_data?.call_processing_url, listeningToAgent])

  useEffect(() => {
    if (userHadCalls && expanded && !callIdsRetrieved.includes(agent.call_data?.call_id)) {
      dispatch(fetchAgentWithCallStatus(agent.agent_id))
      setCallIdsRetrieved([...callIdsRetrieved, agent.call_data.call_id])
    }
  }, [expanded])

  const agentDetailsMap = [
    { label: 'Status', value: startCase(agent.agent_status) },
    { label: 'Name', value: `${agent.agent_first_name} ${agent.agent_last_name}` },
    { label: 'ID', value: agent.agent_username },
  ]

  const call_end_time = agent.call_data?.call_end_time
    ? formatTime(agent.call_data.call_end_time, 'h:mm A')
    : '--'

  const callDetailsMap = [
    {
      label: 'Status',
      value: startCase(agent.call_data?.call_status),
    },
    {
      label: 'Date',
      value: formatTime(agent.call_data?.call_start_time, 'MMMM Do, YYYY'),
    },
    {
      label: 'Start Time',
      value: formatTime(agent.call_data?.call_start_time, 'h:mm A'),
    },
    {
      label: 'End Time',
      value: call_end_time,
    },
    {
      label: 'Playbook',
      value: agent.call_data?.playbook_name,
      link: `/playbooks/${agent.call_data?.playbook_id}/checklist`,
    },
    {
      label: 'Call Details',
      value: 'Explore Call',
      link: `/call-explorer/${agent.call_data?.call_id}`,
    },
    {
      label: 'Voip Customer ID',
      value: agent.call_data?.voip_customer_id || '--',
    },
    {
      label: 'Voip Campaign Name',
      value: agent.call_data?.voip_campaign_name || '--',
    },
  ]

  const detailsSections = [{ label: 'Agent Details', value: agentDetailsMap }]

  if (userHadCalls) {
    detailsSections.push({ label: 'Call Details', value: callDetailsMap })
  }

  return (
    <div
      className={classNames('agent-call-status-entry', {
        'actively-listening': isActivelyListening,
      })}
    >
      <div className="agent-call-status-entry__list" onClick={() => setExpanded((prev) => !prev)}>
        <div className="agent-call-status-entry__agent">
          <Popup
            inverted
            content={`${agentFullName} is ${agent.agent_status}`}
            trigger={
              <div className="relative">
                <UserInitial userFullName={agentFullName} index={index} />
                <ListeningStatus status={agent.agent_status} absolute />
              </div>
            }
          />
          <h2>{agentFullName}</h2>
        </div>
        <div>
          <TimeElapsed agent={agent} />
        </div>
        <div className="agent-call-status-entry__actions">
          {agent.call_data?.call_processing_url && listeningToAgent !== agent.agent_username && (
            <Button
              icon
              basic
              compact
              className="svg-button headphones"
              data-testid="cc-listen-icon"
              onClick={(event) => {
                event.stopPropagation()
                dispatch(stopListeningToCall())
                dispatch(stopListeningToAlert())
                dispatch(
                  attemptListeningToCall({
                    managerUsername: currentUser,
                    agentUsername: agent.agent_username,
                    processingUrl: agent.call_data.call_processing_url,
                    callId: agent.call_data.call_id,
                  })
                )
              }}
            >
              <IconHeadphones />
            </Button>
          )}
          {isActivelyListening && (
            <div className="command-center-listening-pill">
              <SoundWaveAnimation />
              Listening...
              <Button
                data-testid="cc-listen-stop-button"
                className="command-center-stop-listening__button"
                onClick={(event) => {
                  dispatch(stopListeningToCall())
                  event.stopPropagation()
                }}
              >
                Stop
              </Button>
            </div>
          )}
          {!agent.call_data?.call_processing_url && (
            <Popup
              inverted
              content={`${agentFullName} is not currently on a call`}
              trigger={
                <div>
                  <Button
                    icon
                    basic
                    compact
                    className="svg-button headphones"
                    disabled
                    data-testid="cc-listen-icon-disabled"
                  >
                    <IconHeadphones />
                  </Button>
                </div>
              }
            />
          )}
        </div>
      </div>
      {expanded && (
        <div className="agent-call-status-entry__details">
          <div className="details-container">
            {detailsSections.map((section) => (
              <div key={section.label}>
                <h3>{section.label}</h3>
                <div className="details-grid" data-testid="details-grid">
                  {section.value.map((detail) => (
                    <Fragment key={detail.label}>
                      <div>
                        <strong>{detail.label}:</strong>
                      </div>
                      {detail.link ? (
                        <ExternalLink
                          url={detail.link}
                          label={detail.value}
                          id={agent.call_data?.playbook_id}
                          wrap
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                        />
                      ) : (
                        <div>{detail?.value}</div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
