import React, { useContext } from 'react'
import classNames from 'classnames'

import WidgetContext, { WidgetProvider } from './widget.context'
import WidgetControls from './WidgetControls'

import './Widget.css'

// This wrapper is necessary in order to take advantage of the context/provider
const WidgetChildren = ({ children }) => {
  const { isMinimized } = useContext(WidgetContext)

  return <>{!isMinimized && children}</>
}

const Widget = ({
  widgetId,
  label,
  subtitle,
  children,
  displayBackground = true,
  tableOnly = false,
  percentViewDefault = true,
  displayControls = true,
  ...props
}) => {
  return (
    <WidgetProvider
      widgetId={widgetId}
      tableOnly={tableOnly}
      percentViewDefault={percentViewDefault}
    >
      <section className={classNames('widget', { blank: !displayBackground })} data-testid="widget">
        {displayControls && (
          <WidgetControls
            allowMinimization={Boolean(widgetId)}
            label={label}
            subtitle={subtitle}
            {...props}
          />
        )}
        <WidgetChildren>{children} </WidgetChildren>
      </section>
    </WidgetProvider>
  )
}

export default Widget
