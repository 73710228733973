import { fetchingAPI, apiService } from '@/api'
import { setOrganizationTags } from './organizationTags.redux'

export const fetchOrganizationTags =
  ({ organizationId, setLoading }) =>
  async (dispatch) => {
    try {
      if (setLoading) setLoading(true)
      const tags = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags`,
        'GET',
        dispatch
      )
      dispatch(setOrganizationTags(tags))
    } catch (err) {
      console.error('fetchOrganizationTags failed', err)
    } finally {
      if (setLoading) setLoading(false)
    }
  }
