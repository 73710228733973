import React from 'react'
import { Button } from 'semantic-ui-react'
import { IconChevronRight } from '@tabler/icons-react'
import { kebabCase } from 'lodash'
import classNames from 'classnames'

import './Accordion.scss'

export const Accordion = ({
  expanded = true,
  handleToggle,
  title,
  id,
  children,
  actions,
  large,
}) => {
  const defaultId = id || kebabCase(title)

  return (
    <div className="accordion" data-testid={`accordion-${defaultId}`}>
      <header>
        <div className="flex-align-center gap">
          <Button
            basic
            icon
            className="svg-button borderless"
            data-testid={`accordion-button-${defaultId}`}
            onClick={() => handleToggle(defaultId)}
          >
            <IconChevronRight
              className={classNames('icon-accordion', { 'icon-accordion--expanded': expanded })}
            />
            <h3 className={classNames({ large })}>{title}</h3>
          </Button>
        </div>
        <div className="flex-align-center small-gap">{actions}</div>
      </header>
      {expanded && <section data-testid={`content-${defaultId}`}>{children}</section>}
    </div>
  )
}
