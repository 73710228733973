import React, { useContext } from 'react'
import moment from 'moment'
import { Popup, Button } from 'semantic-ui-react'
import classNames from 'classnames'
import uuid from 'uuid/v4'
import { IconX } from '@tabler/icons-react'

import { CopyButton } from '../buttons/CopyButton'
import { heatmapCallbackRef } from '../helpers/heatmapCallbackRef'
import WidgetContext from './widget.context'
import { ExternalLink } from '../ExternalLink'

const WidgetDetail = ({ data }) => {
  const [occurredRef] = heatmapCallbackRef()
  const { handleChangeItemDetail } = useContext(WidgetContext)

  const { triggerTimes, snippets, voipCallId, item, userFullName, date, call_id } = data

  const formattedSnippets = []
  const formattedDate = moment.utc(date).local().format('MMM Do, h:mm A')

  snippets.forEach((snippet, idx) => {
    const triggerTime = triggerTimes[idx]
    const timeElapsed = moment.utc(date).add(triggerTime, 'seconds').local().format('h:mm:ss A')

    const occurredText = (
      <div style={{ color: 'gray', fontSize: '10px', paddingRight: '3px' }}>
        Occurred at {timeElapsed}
      </div>
    )

    // group one speaker's blocks of text together
    const groupedSnippets = []
    // building groupedSnippets into format:
    // [{ side: 0, text: [{ side: 0, time: , text: 'hello' }, { side: 0, time: , text: 'thanks for' }, { side: 0, time: , text: 'calling' }] },
    // { side: 1, text: [{ { side: 1, time: , text: 'hi there' }}] },
    // { side: 0, occurred: true, text: [{ side: 0, time: , text: 'i am a' }, { side: 0, time: , text: 'licensed insurance agent', occurred: true }] }]
    snippet.forEach((snippetItem) => {
      const snippetsLength = groupedSnippets.length
      const mostRecentBlock = snippetsLength && groupedSnippets[snippetsLength - 1]

      const formattedSnippet = { ...snippetItem }
      const occurred = snippetItem.time === triggerTime

      // mark line where item occurred so can place "occurred" div
      if (occurred) formattedSnippet.occurred = true

      if (mostRecentBlock.side === snippetItem.side) {
        mostRecentBlock.text.push(formattedSnippet)
        // mark block in which item occurred so can place scrollTo ref on the block's speaker (1st snippet only)
        if (occurred && idx === 0) mostRecentBlock.occurred = true
      } else {
        const starterBlock = { side: snippetItem.side, text: [formattedSnippet] }
        if (occurred && idx === 0) starterBlock.occurred = true
        groupedSnippets.push(starterBlock)
      }
    })

    // combine all grouped snippets to create full, formatted snippet including both conversation sides
    const detailSnippet = groupedSnippets.map((snippetItem) => {
      const agentSide = snippetItem.side === 0
      // some snippets may have no text (if they only have transcript hypotheses), we shouldn't render these unless item occurred
      const snippetText = snippetItem.text.map((textBlock) => textBlock.text).join()
      const showSnippet = snippetText || snippetItem.occurred

      return (
        showSnippet && (
          <div
            key={`${snippetItem.side}.${snippetItem.text[0].time}`}
            className={classNames({
              'me-side-detail': agentSide,
              'them-side-detail': !agentSide,
            })}
          >
            <div ref={snippetItem.occurred ? occurredRef : null} className="transcript-side-role">
              {agentSide ? 'Agent' : 'Customer'}
            </div>
            <div>
              {snippetItem.text.map((item) => {
                if (!item.occurred) {
                  return `${item.text} `
                }

                return (
                  <div
                    key={`${item.text}.${item.time}`}
                    className={classNames({
                      'item-occurred': item.occurred,
                    })}
                  >
                    {item.text}
                    {item.occurred && occurredText}
                  </div>
                )
              })}
            </div>
          </div>
        )
      )
    })
    if (snippets.length > idx + 1) {
      detailSnippet.push(<hr className="widget__detail-snippet-divider" key={uuid()} />)
    }

    formattedSnippets.push(detailSnippet)
  })

  return (
    <div className="widget__detail" data-testid="widget-detail">
      <div className="widget__detail-container">
        <div style={{ padding: '1rem' }}>
          <div className="widget__detail-header">
            <h4>{userFullName}</h4>
            <Button
              data-testid="change-detail-button"
              basic
              compact
              icon
              className="svg-button"
              onClick={() => handleChangeItemDetail({})}
            >
              <IconX />
            </Button>
          </div>
          <p>{formattedDate}</p>
          <div>
            <ExternalLink to={`/call-explorer/${call_id}`} label="Explore Call" target="_blank" />
          </div>
          {voipCallId && (
            <div className="flex-align-center flex-space-between">
              <div>
                <strong>VoIP ID:</strong>
                <input readOnly id="copyVoipCallId" value={voipCallId} data-testid="voip-call-id" />
              </div>
              <CopyButton content={voipCallId} />
            </div>
          )}
        </div>
        <div className="widget__detail-item" data-testid="widget-detail-item">
          <Popup
            inverted
            content={item}
            trigger={<div className="text-overflow">&quot;{item}&quot;</div>}
          />
        </div>
      </div>
      <div className="widget__detail-snippets">
        {formattedSnippets.map((formattedSnippet) => {
          return <div key={uuid()}>{formattedSnippet}</div>
        })}
      </div>
    </div>
  )
}

export default WidgetDetail
