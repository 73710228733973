import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { MediaPlayer } from './MediaPlayer'
import { VideoPlayerProvider } from './VideoPlayerContext'
import { AudioPlayerProvider } from './AudioPlayerContext'
import { TranscriptProvider } from '../transcript/TranscriptContext'

export const MediaDrawer = () => {
  const { mediaPlayer } = useSelector((state) => state.media)
  const { isPlaying } = mediaPlayer
  const [isHidden, setIsHidden] = useState(!isPlaying)

  const handleShowPlayer = () => {
    setIsHidden(false)
  }

  const handleHidePlayer = () => {
    setIsHidden(true)
  }

  return (
    <div
      data-testid="media-drawer"
      className={classNames('bottom-drawer media-drawer', { hidden: isHidden })}
    >
      <VideoPlayerProvider>
        <AudioPlayerProvider>
          <TranscriptProvider>
            <MediaPlayer
              handleShowPlayer={handleShowPlayer}
              handleHidePlayer={handleHidePlayer}
              hideAudioTrack
              hideVolume
            />
          </TranscriptProvider>
        </AudioPlayerProvider>
      </VideoPlayerProvider>
    </div>
  )
}
