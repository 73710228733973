import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isNil } from 'lodash'

import {
  toggleMediaPlayerPlaying,
  updateMediaPlayer,
  resetMediaPlayer,
  updateSettings,
} from '@/reducers/media/media.redux'
import { audioErrorSwitch } from '@/utils/helpers'
import { SKIP_MODIFIER } from '@/utils/constants'
import { Transcript } from '@/components/transcript/Transcript'
import { useTranscript } from '@/components/transcript/TranscriptContext'
import { POSTPROCESSED_TRANSCRIPT } from '@/components/transcript/transcript.constants'

import VideoPlayer from './VideoPlayer'
import { AudioPlayer } from './AudioPlayer'
import { useAudioPlayer } from './AudioPlayerContext'
import { useVideoPlayer } from './VideoPlayerContext'

import './MediaPlayer.scss'

export const MediaPlayer = ({
  isAudioAvailable = true,
  isTranscriptAvailable = false,
  transcriptMetadata = {},
  transcriptEnableAutoScroll = false,
  showTranscriptSelector = false,
  transcriptConnected = false,
  hideAudioTrack,
  hidePlaybackRate,
  hideVolume,
  handleShowPlayer,
  handleHidePlayer,
}) => {
  const dispatch = useDispatch()
  const {
    audioRef,
    jumpAudioTo,
    changeAudioPlaybackRate,
    changeAudioVolume,
    playAudio,
    pauseAudio,
    addAudioEvent,
    removeAudioEvent,
  } = useAudioPlayer()
  const {
    changeVideoPlaybackRate,
    jumpVideoTo,
    playVideo,
    pauseVideo,
    addVideoEvent,
    removeVideoEvent,
    toggleVideoVisibility,
    isVideoVisible,
  } = useVideoPlayer()
  const {
    allowAutoScroll,
    transcriptBlocksFirstTimes,
    transcriptType,
    transcriptBlockRef,
    transcriptScrollerRef,
    closestTranscriptIndexRef,
    transcriptToDisplay,
    isTranscriptVisible,
  } = useTranscript()
  const isTranscriptActive =
    isTranscriptAvailable && isTranscriptVisible && !isEmpty(transcriptToDisplay)
  const { loading, mediaPlayer, settings } = useSelector((state) => state.media)
  const {
    progress,
    duration,
    audioUrl,
    audioError,
    isPlaying,
    videoUrls,
    videoError,
    hardSelectedEvent,
    skipSilence: isSkipSilenceEnabled,
  } = mediaPlayer
  const { playbackRate, volume } = settings
  const trackProgressRef = useRef()
  const isDisabled = !audioUrl || audioError
  const isVideoAvailable = !isEmpty(videoUrls) && !videoError

  const setIsPlaying = (value) => {
    if (isPlaying !== value) {
      dispatch(updateMediaPlayer({ isPlaying: value }))
    }
  }

  const setProgress = (value) => {
    dispatch(updateMediaPlayer({ progress: value }))

    if (isAudioAvailable) jumpAudioTo(value)
    if (isVideoAvailable) jumpVideoTo(value)
  }

  const setDuration = (value) => {
    dispatch(updateMediaPlayer({ duration: value }))
  }

  const setVolume = (value) => {
    dispatch(updateSettings({ volume: value }))
    changeAudioVolume(value)
  }

  const setPlaybackRateChange = (value) => {
    dispatch(updateSettings({ playbackRate: value }))

    if (isAudioAvailable) changeAudioPlaybackRate(value)
    if (isVideoAvailable) changeVideoPlaybackRate(value)
  }

  const setErrorState = (value) => {
    audioErrorSwitch(audioRef.current.error.code, audioRef.current.error.message)
    dispatch(updateMediaPlayer({ audioError: value }))
  }

  const togglePlayState = () => {
    dispatch(toggleMediaPlayerPlaying())
  }

  const stopProgressTracking = () => {
    clearInterval(trackProgressRef.current)
    trackProgressRef.current = null
  }
  const skipSilence = (currentTime) => {
    if (isSkipSilenceEnabled && !isEmpty(transcriptToDisplay)) {
      const currentSilence = transcriptToDisplay.filter((obj) => {
        return (
          obj.type === 'silence' &&
          currentTime >= obj.timestamp &&
          currentTime <= obj.timestamp + obj.duration
        )
      })
      if (!isEmpty(currentSilence)) {
        return currentSilence[0].timestamp + currentSilence[0].duration
      }
    }
    return null
  }

  const startProgressTracking = () => {
    // Clear any timers already running
    stopProgressTracking()

    trackProgressRef.current = setInterval(() => {
      if (audioRef.current) {
        const skipTime = skipSilence(audioRef.current.currentTime)
        if (!isNil(skipTime)) audioRef.current.currentTime = skipTime
        if (audioRef.current.ended) {
          setProgress(duration)
          setIsPlaying(false)
        } else {
          dispatch(updateMediaPlayer({ progress: audioRef.current.currentTime }))
        }
      }
    }, [50])
  }

  const play = () => {
    if (isAudioAvailable) playAudio()
    if (isVideoAvailable) playVideo()

    startProgressTracking()
  }

  const pause = () => {
    if (isAudioAvailable) pauseAudio()
    if (isVideoAvailable) pauseVideo()

    stopProgressTracking()
  }

  const handleScrub = (event) => {
    const newTime = event.target.valueAsNumber

    setProgress(newTime)
  }

  const handleForwardSkip = () => {
    const newTime = progress + SKIP_MODIFIER
    const canScrubForward = newTime < duration

    if (canScrubForward) {
      setProgress(newTime)
    } else {
      setProgress(duration)
    }
  }

  const handleBackSkip = () => {
    const newTime = progress - SKIP_MODIFIER
    const canScrubBack = newTime > 0

    if (canScrubBack) {
      setProgress(newTime)
    } else {
      setProgress(0)
    }
  }

  const handlePlaybackRateChange = (event, option) => {
    const updatedPlaybackRate = parseFloat(option.value)

    localStorage.setItem('audioPlaybackRate', updatedPlaybackRate)
    setPlaybackRateChange(updatedPlaybackRate)
  }

  const handleVolumeChange = (value) => {
    setVolume(value)
  }

  const handleTimestampClick = (timestamp) => {
    dispatch(updateMediaPlayer({ isPlaying: true, hardSelectedEvent: { timestamp } }))
  }

  const handleCloseDrawer = () => {
    if (handleHidePlayer) handleHidePlayer()
    dispatch(resetMediaPlayer())
    stopProgressTracking()
  }

  useEffect(() => {
    // Show player, play, and set duration if playing
    if (isPlaying && duration !== 0 && audioUrl) {
      if (handleShowPlayer) handleShowPlayer()
      if (progress === duration) {
        // Set the progress to the beginning if you try to play after the audio has ended.
        // This ensures it doesn't just start for a few milliseconds, go to the beginning, then pause.
        setProgress(0)
      }
      play()
    }

    // Pause if audio is loaded but not playing
    if (!isPlaying) {
      pause()
    }
  }, [isPlaying, duration, isSkipSilenceEnabled])

  useEffect(() => {
    const timestamp = hardSelectedEvent?.timestamp

    if (timestamp && !isDisabled) {
      setProgress(timestamp)
    }

    // if an event is selected, scroll the container to show the trigger
    // and surrounding transcript context. we check if the ref is available since it isn't if we have an audio error
    if (hardSelectedEvent && transcriptScrollerRef.current) {
      // Copy pasted from onTimeUpdate
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndexRef !== closest) {
        closestTranscriptIndexRef.current = closest
      }
    }
  }, [hardSelectedEvent])

  useEffect(() => {
    const durationListener = (event) => {
      setDuration(event.target.duration)
    }
    const errorListener = () => {
      setErrorState(true)
    }

    addAudioEvent('loadedmetadata', durationListener)
    addAudioEvent('error', errorListener)

    if (!isAudioAvailable && isVideoAvailable) {
      addVideoEvent('loadedmetadata', durationListener)
    }

    const storedPlaybackRate = localStorage.getItem('audioPlaybackRate')
    if (storedPlaybackRate) {
      setPlaybackRateChange(parseFloat(storedPlaybackRate))
    }

    if (progress !== 0) {
      setProgress(progress)
    }

    return () => {
      removeAudioEvent('loadedmetadata', durationListener)
      removeAudioEvent('error', errorListener)

      if (!isAudioAvailable && isVideoAvailable) {
        removeVideoEvent('loadedmetadata', durationListener)
      }

      stopProgressTracking()
    }
  }, [audioUrl])

  const videoOnPlaying = () => setIsPlaying(true)
  const videoOnPause = () => setIsPlaying(false)
  const videoOnSeeked = (event) => {
    const { currentTime } = event.target
    dispatch(updateMediaPlayer({ progress: currentTime }))

    if (isAudioAvailable) jumpAudioTo(currentTime)
    // Don't jump to video because the built-in fullsceen controls already do it.
  }

  // doing this because we need access to the audio player ref in the transcriptViewer component
  // if I use just one ref (in transcriptviewer and pass to here) and don't create audioRef here,
  // it breaks all the tests because I can't pass a real ref to this component in tests
  useEffect(() => {
    transcriptScrollerRef.current = audioRef.current
  }, [audioRef.current])

  useEffect(() => {
    const onTimeUpdate = () => {
      if (transcriptType !== POSTPROCESSED_TRANSCRIPT) {
        closestTranscriptIndexRef.current = 0
        return
      }
      // if this is slow for long calls we could implement a recursive binary search. Only risk is while it may be faster
      // stack size could grow large which would be problematic for people on potato comps. We'll have to see which problem is more problematic
      // speed or stack size
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndexRef !== closest) {
        closestTranscriptIndexRef.current = closest
      }
    }

    if (transcriptScrollerRef.current !== null) {
      transcriptScrollerRef.current.addEventListener('timeupdate', onTimeUpdate)
    }

    return () => {
      if (transcriptScrollerRef.current !== null) {
        transcriptScrollerRef.current.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
  }, [transcriptType])

  useEffect(() => {
    if (
      isTranscriptActive &&
      transcriptBlockRef.current !== null &&
      closestTranscriptIndexRef.current !== null &&
      transcriptEnableAutoScroll &&
      allowAutoScroll
    ) {
      if (transcriptBlockRef.current.childNodes[closestTranscriptIndexRef.current]) {
        const transcriptContainer = transcriptBlockRef.current
        transcriptContainer.scrollTo({
          top:
            transcriptBlockRef.current.childNodes[closestTranscriptIndexRef.current].offsetTop -
            transcriptContainer.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }, [closestTranscriptIndexRef.current, transcriptBlockRef.current])

  return (
    <>
      {isVideoAvailable && (
        <div className="video-player-container">
          <VideoPlayer
            isPlaying={isPlaying}
            progress={progress}
            playbackRate={playbackRate}
            sources={videoUrls}
            onPlaying={videoOnPlaying}
            onPause={videoOnPause}
            onSeeked={videoOnSeeked}
          />
        </div>
      )}
      <div className="audio-player-container">
        <AudioPlayer
          loading={loading.mediaPlayerByCallId}
          isPlaying={isPlaying}
          duration={duration}
          progress={progress}
          playbackRate={playbackRate}
          volume={volume}
          audioUrl={audioUrl}
          isDisabled={isDisabled}
          handleScrub={handleScrub}
          handleBackSkip={handleBackSkip}
          handleForwardSkip={handleForwardSkip}
          handleVolumeChange={handleVolumeChange}
          handlePlaybackRateChange={handlePlaybackRateChange}
          handleCloseDrawer={handleCloseDrawer}
          togglePlayState={togglePlayState}
          hideAudioTrack={hideAudioTrack}
          hidePlaybackRate={hidePlaybackRate}
          hideVolume={hideVolume}
          hideCloseButton={!handleHidePlayer}
          isVideoAvailable={isVideoAvailable}
          toggleVideoVisibility={toggleVideoVisibility}
          isVideoVisible={isVideoVisible}
          isTranscriptAvailable={isTranscriptAvailable}
          skipSilence={isSkipSilenceEnabled}
          handleSkipSilenceChange={(skip) => dispatch(updateMediaPlayer({ skipSilence: skip }))}
        />
      </div>
      {isTranscriptActive && (
        <div className="transcript-container">
          <Transcript
            metadata={transcriptMetadata}
            showTranscriptSelector={showTranscriptSelector}
            enableAutoScroll={transcriptEnableAutoScroll}
            connected={transcriptConnected}
            handleTimestampClick={handleTimestampClick}
            hardSelectedEvent={hardSelectedEvent}
          />
        </div>
      )}
    </>
  )
}
