import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { submitMassPlaybookUpdate } from '@/reducers/playbooks/playbooks.actions'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { FormModal } from '@/components/layout/modals/FormModal'
import { massPlaybookUpdateProgressStatus, sections } from '@/views/Playbooks/playbook.helpers'
import { MassPlaybookUpdateWarningBanner } from '../modules/checklist/ChecklistBulkEdit'

export const PlaybookFormActions = ({ isDisabled, handleCancel, dirty, values }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: config_id, entryId: content_id } = useParams()

  const { massPlaybookUpdate, playbooks } = useSelector((state) => state.playbooks)

  const { isEnabled, progress, matches, selection, newPlaybookVersion } = massPlaybookUpdate || {}
  const massPlabookUpdateOn = isEnabled && !isEmpty(selection)

  const [showConfirmPublish, setShowConfirmPublish] = useState(false)

  const handleClose = () => {
    if (newPlaybookVersion) {
      history.push(`/playbooks/${newPlaybookVersion}/${sections.CHECKLIST}`)
    } else {
      handleCancel()
    }
  }

  const handleSubmitMassPlaybookUpdate = () =>
    dispatch(
      submitMassPlaybookUpdate(
        playbooks,
        matches,
        selection,
        config_id,
        content_id,
        values,
        setShowConfirmPublish
      )
    )

  return (
    <>
      <div className="playbook-form-actions">
        <div className="flex-align-center small-gap" style={{ marginLeft: 'auto' }}>
          <Button
            secondary
            type="button"
            data-testid="playbook-cancel-button"
            onClick={handleCancel}
            disabled={progress !== massPlaybookUpdateProgressStatus.INITIAL}
          >
            Cancel
          </Button>
          {massPlabookUpdateOn ? (
            progress === massPlaybookUpdateProgressStatus.INITIAL ? (
              <Button
                primary
                type="button"
                data-testid="playbook-publish-button"
                disabled={isDisabled}
                onClick={() => setShowConfirmPublish(true)}
              >
                Publish
              </Button>
            ) : (
              <Button
                secondary
                type="button"
                data-testid="playbook-publish-close-button"
                disabled={progress === massPlaybookUpdateProgressStatus.IN_PROGRESS}
                onClick={handleClose}
              >
                Close
              </Button>
            )
          ) : (
            <Button
              primary
              type="submit"
              data-testid="playbook-submit-button"
              disabled={isDisabled}
            >
              Update
            </Button>
          )}
        </div>
        {dirty && progress !== massPlaybookUpdateProgressStatus.DONE && (
          <ErrorMessage content="You have unsaved changes on this page!" />
        )}
      </div>
      {showConfirmPublish && (
        <FormModal
          title="Edit in Bulk Across Playbooks (Alpha)"
          onSave={handleSubmitMassPlaybookUpdate}
          onClose={() => setShowConfirmPublish(false)}
          closeButtonLabel="Cancel"
          submitButtonLabel="Publish"
          show={showConfirmPublish}
          size="tiny"
        >
          <MassPlaybookUpdateWarningBanner isConfirmation />
          <div data-testid="confirm-delete-modal" className="organization__active-modal-content">
            {`Publish current and ${selection.length} selected ${pluralize('checklist', selection.length)}?`}
          </div>
        </FormModal>
      )}
    </>
  )
}
