import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ProtectedRoute } from './routing/ProtectedRoute'
import { publicRoutes } from './routing/public.routes'
import { protectedRoutes } from './routing/protected.routes'
import FourOhFour from './views/Auth/FourOhFour'

import { fullstoryScript } from './utils/constants'

const App = () => {
  const { currentOrganization } = useSelector((state) => state)
  const user = JSON.parse(localStorage.getItem('User'))

  // fullstory
  useEffect(() => {
    if (currentOrganization.id && !currentOrganization.unredacted_enabled) {
      const script = document.createElement('script')
      script.textContent = fullstoryScript
      script.setAttribute('data-testid', 'fullstory-script-tag')
      script.async = true
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }

    return undefined
  }, [currentOrganization.id])

  useEffect(() => {
    // set user identity for FullStory
    if (window.FS && user) {
      window.FS('setIdentity', {
        uid: user.user_id,
        properties: {
          user: {
            email: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
          },
          organization: {
            name: user.organization_name,
            id: user.organizationid,
          },
        },
      })
    }
  }, [window.FS])

  return (
    <Switch>
      {publicRoutes.map(({ exact = true, path, Component }) => (
        <Route exact={exact} key={path} path={path} component={Component} />
      ))}
      {protectedRoutes.map(({ exact = true, path, Component, ...props }) => (
        <ProtectedRoute exact={exact} key={path} path={path} component={Component} {...props} />
      ))}
      <Route path="*" component={FourOhFour} />
    </Switch>
  )
}

export default App
