import Papa from 'papaparse'

export const headersWhiteList = [
  'first_name',
  'last_name',
  'username',
  'playbook',
  'voip_user_id',
  'balto_cloud_access',
  'playbook_edit_access',
  'user_management_access',
  'user_status',
  'uncategorized_tags',
  'edit_qa',
  'edit_leaderboard',
  'realtime_coaching_access',
  'midcall_playbook_switching_enabled',
  'desktop_enterprise_enabled',
  'edit_qa_copilot',
  'hierarchy_manager',
  'screen_capture_enabled',
  'desired_username',
]

export const toTitleCase = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export default (rawCSVData, tagCategories) => {
  const headersWhiteListTransformed = headersWhiteList.concat(
    tagCategories.map((tagCategory) => tagCategory.name)
  )
  const parsedCSVData = Papa.parse(rawCSVData, { transform: (value) => value.trim() })
  const [headers] = parsedCSVData.data
  const CSVRows = parsedCSVData.data.slice(1)
  const tagCategoryNames = tagCategories.map((tagCategory) => tagCategory.name)
  const filteredHeaders = headers.filter((header) => {
    if (headersWhiteListTransformed.includes(header)) {
      return true
    }
    if (header.slice(0, -1) === 'balto_tag_' && !Number.isNaN(header.slice(-1))) {
      return true
    }
    return false
  })

  let tags = []
  const tagsResult = []
  const configNames = []

  const newUsers = CSVRows.map((row) =>
    row
      .map((cell, index) => {
        if (filteredHeaders.includes(headers[index])) {
          // make list of tags and config names found in the csv,
          // if they are not '' or null because of papaparse
          if (headers[index] === 'playbook' && !configNames.includes(cell) && cell) {
            configNames.push(cell.trim())
          }
          if (headers[index] === 'uncategorized_tags') {
            tags = tags.concat(
              cell
                .trim()
                .split(',')
                .map((tag) => ({ tag: tag.trim(), category_id: null }))
            )
          }
          // tags from tag categories.
          if (tagCategoryNames.includes(headers[index])) {
            tags.push({
              tag: cell.trim(),
              category_id: tagCategories[tagCategoryNames.indexOf(headers[index])].id,
            })
          }

          tags = tags.filter((item) => item.tag && item.tag !== '')

          for (const item of tags) {
            if (tagsResult.filter((tagItem) => tagItem.tag === item.tag).length === 0) {
              tagsResult.push(item)
            }
          }

          return { [headers[index]]: cell }
        }
        return null
      })
      .filter((user) => user !== null)
      .reduce(
        // I guess this eslint disable was already here
        // eslint-disable-next-line no-return-assign
        (newUser, property) =>
          (
            // eslint-disable-next-line no-param-reassign, no-sequences
            (newUser[[Object.keys(property)]] = property[[Object.keys(property)]]), newUser
          ),
        {}
      )
  )

  return {
    tags: tagsResult,
    configNames,
    users: newUsers,
    headers: filteredHeaders,
  }
}
