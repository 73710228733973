import React, { useState } from 'react'
import { Input, Button } from 'semantic-ui-react'
import { IconCheck, IconX } from '@tabler/icons-react'

import { EditButton } from '@/components/buttons/EditButton'
import { AudioPlayerButton } from '@/components/media/components/AudioPlayerButton'

export const EditPlaylistCallAlias = ({
  call,
  handleEditAlias,
  generateCallExplorerLink,
  pauseAudio,
}) => {
  const [editing, setEditing] = useState(false)
  const [tempAlias, setTempAlias] = useState('')

  if (editing) {
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault()

          setEditing(false)
          handleEditAlias(tempAlias, call)
        }}
        className="flex-align-center small-gap no-wrap"
        data-testid="edit-playlist-call-alias-form"
      >
        <Input
          data-testid="alias-input"
          type="text"
          value={tempAlias}
          maxLength={100}
          onChange={(event) => setTempAlias(event.target.value)}
          placeholder="Enter alias..."
          autoFocus
        />
        <Button
          icon
          secondary
          type="button"
          className="svg-button"
          onClick={() => {
            setTempAlias('')
            setEditing(false)
          }}
        >
          <IconX />
        </Button>
        <Button data-testid="alias-submit-button" icon primary type="submit" className="svg-button">
          <IconCheck />
        </Button>
      </form>
    )
  }

  return (
    <div className="flex-align-center medium-gap no-wrap hidden-button-outer">
      <AudioPlayerButton call={call} />
      <a
        data-testid="playlist-call-link"
        href={generateCallExplorerLink(call.call_id)}
        onClick={pauseAudio}
        className="table-link"
      >
        {call.alias || call.call_id}
      </a>
      <EditButton className="hidden-button" onEdit={() => setEditing(true)} />
    </div>
  )
}
