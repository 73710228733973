import { apiService, fetchingAPI } from '@/api'

import * as redux from './media.redux'

export const fetchVideoForCallId = async (callId, dispatch) => {
  try {
    const { video_addresses: videoUrls, video_file_status: videoStatus } = await fetchingAPI(
      `${apiService.reporting}/api/calls/${callId}/screen_capture`,
      'GET',
      dispatch
    )

    return { videoUrls, videoStatus }
  } catch {
    return null
  }
}

export const fetchAudioForCallId = async (callId, dispatch) => {
  try {
    const {
      audio_address: audioUrl,
      audio_file_status: audioStatus,
      expiration_seconds: audioUrlExpiration,
    } = await fetchingAPI(`${apiService.reporting}/api/calls/${callId}/audio`, 'GET', dispatch)

    return { audioUrl, audioStatus, audioUrlExpiration }
  } catch {
    return null
  }
}

export const fetchMediaForCallId = (callId, shouldFetchAudio = true, shouldFetchVideo = true) => {
  return async (dispatch) => {
    dispatch(redux.resetMediaPlayer())
    // set the ID here so it shows the loading state on the right one
    dispatch(redux.setLoading({ mediaPlayerByCallId: callId }))

    if (shouldFetchAudio) {
      const audioResponse = await fetchAudioForCallId(callId, dispatch)

      if (audioResponse?.audioStatus === 'available') {
        dispatch(redux.updateMediaPlayer({ ...audioResponse, callId, isPlaying: true }))
      }
    }

    if (shouldFetchVideo) {
      // should have a check here to see if video is available before trying to fetch
      const videoResponse = await fetchVideoForCallId(callId, dispatch)

      if (videoResponse?.videoStatus === 'available') {
        dispatch(redux.updateMediaPlayer({ ...videoResponse, callId }))
      }
    }

    dispatch(redux.setLoading({ mediaPlayerByCallId: null }))
  }
}
