import React from 'react'

import Instructions from '@/components/forms/VoipCampaignCSVUploadForms/Instructions'
import MultiStepForm from '@/views/Organizations/MultiStepForm'
import Preview from '@/components/forms/VoipCampaignCSVUploadForms/Preview'
import Form from '../../../../components/Modal'
import Receipt from '../../../../components/forms/VoipCampaignCSVUploadForms/Receipt'

export const BulkVoipCampaignUploadButton = () => {
  return (
    <div style={{ margin: '.5rem' }}>
      <Form
        buttonLabel="Bulk VoIP Campaign Upload"
        buttonId="bulk-voip-campaign-upload"
        header="Bulk VoIP Campaign Upload"
        fullScreen
        form={<MultiStepForm steps={[<Instructions />, <Preview />, <Receipt />]} />}
      />
    </div>
  )
}
