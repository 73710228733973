import React from 'react'
import { Popup, Dropdown } from 'semantic-ui-react'
import { IconCheck } from '@tabler/icons-react'

export const PLAYBACK_RATE_OPTIONS = [
  { key: 0.5, text: '0.5x', value: 0.5 },
  { key: 0.75, text: '0.75x', value: 0.75 },
  { key: 1, text: '1x', value: 1 },
  { key: 1.25, text: '1.25x', value: 1.25 },
  { key: 1.5, text: '1.5x', value: 1.5 },
  { key: 2, text: '2x', value: 2 },
]

export const PlaybackRateDropdown = ({
  isDisabled,
  handlePlaybackRateChange,
  playbackRate,
  skipSilence,
  handleSkipSilenceChange,
}) => {
  return (
    <Popup
      inverted
      content="Change Playback Rate"
      trigger={
        <Dropdown
          floating
          direction="right"
          icon="angle down"
          button
          className="icon secondary svg-button compact"
          header="Playback Rate"
          data-testid="playback-speed-dropdown"
          clearable={false}
          value={playbackRate}
          disabled={isDisabled}
          text={`${playbackRate}x`}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              value={skipSilence}
              active={skipSilence}
              key="skip-silence"
              onClick={() => {
                handleSkipSilenceChange(!skipSilence)
              }}
            >
              <div className="flex-space-between medium-gap">
                <span>Skip Silences</span>
                {skipSilence && <IconCheck className="status-brand" />}
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            {PLAYBACK_RATE_OPTIONS.map((option) => {
              return (
                <Dropdown.Item
                  value={option.value}
                  key={option.key}
                  active={playbackRate === option.value}
                  onClick={(event, option) => {
                    handlePlaybackRateChange(event, option)
                  }}
                >
                  <div className="flex-space-between medium-gap">
                    <span>{option.text}</span>
                    {playbackRate === option.value && <IconCheck className="status-brand" />}
                  </div>
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      }
    />
  )
}
