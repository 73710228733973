import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconConfetti } from '@tabler/icons-react'
import { Segment } from 'semantic-ui-react'

import { Banner } from '@/components/banners/Banner'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Pill } from '@/components/pills/Pill'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'

import { getOrganizationOptions } from '@/utils/helpers'
import { setFilter } from '@/reducers/analytics/analytics.redux'

import { capitalize } from 'lodash'

import { fetchOrganizations } from '@/reducers/organizations/organizations.actions'
import { fetchOrganization } from '@/reducers/organizations/organization.actions'
import { fetchPromptsByOrg } from '@/reducers/analytics/analytics.actions'

export const PromptsPage = () => {
  const dispatch = useDispatch()
  const { organizationid: userOrgId } = useSelector((state) => state.currentUser)
  const { organizations } = useSelector((state) => state)
  const organization = useSelector((state) => state.organization)
  const { prompts } = useSelector((state) => state.analytics.data)
  const organizationId = useSelector((state) => state.analytics.filters.organization)
  const isBaltoAdmin = userOrgId === 1
  const formattedRows = useMemo(
    () =>
      prompts.map((row, index) => ({
        ...row,
        expandedContent: <div>{row.description || 'No description available'}</div>,
        id: index,
      })),
    [prompts]
  )

  const handleOrgSelect = (_accessor, selectedOrg) => {
    dispatch(setFilter({ organization: selectedOrg }))
  }

  const fetchPromptData = (orgId) => {
    dispatch(fetchPromptsByOrg(orgId))
    dispatch(fetchOrganization({ organizationId: orgId }))
  }

  const organizationOptions = useMemo(() => getOrganizationOptions(organizations), [organizations])

  useEffect(() => {
    if (isBaltoAdmin) {
      dispatch(fetchOrganizations())
      dispatch(setFilter({ organization: userOrgId }))
    } else {
      fetchPromptData(userOrgId)
    }
  }, [])

  useEffect(() => {
    if (organizationId) {
      fetchPromptData(organizationId)
    }
  }, [organizationId])

  return (
    <div>
      <h1>AI Usage</h1>
      {isBaltoAdmin && (
        <FilterTypeSelect
          schema={{
            accessor: 'organizationId',
            label: 'Organization',
            options: organizationOptions,
          }}
          options={organizationOptions}
          value={organizationId}
          updateSearch={handleOrgSelect}
          isClearable={false}
        />
      )}
      <Banner
        data-testid="prompt-allowance-banner"
        informative
        shrunk
        className="mb"
        icon={<IconConfetti />}
      >
        <p className="bold">
          You have {organization.prompt_allowance || 10} AI Usage Credits available
        </p>
        <p>
          Need more? You can increase your limit at any time. Contact your Client Success Manager to
          upgrade.
        </p>
      </Banner>

      <Segment className="not-padded">
        <AdvancedTable
          rows={formattedRows}
          columns={[
            {
              label: 'Criterion Name',
              accessor: 'name',
            },
            {
              label: 'Link',
              accessor: 'link',
              format: (links) => (
                <div className="flex-column">
                  {links.map((link) => (
                    <a href={link.link} target="_blank" rel="noreferrer">
                      {link.label}
                    </a>
                  ))}
                </div>
              ),
            },
            {
              label: 'Feature',
              accessor: 'type',
              format: (type) => {
                return (
                  <div className="flex-column">
                    {type.map((_type) => (
                      <Pill xs>{capitalize(_type)}</Pill>
                    ))}
                  </div>
                )
              },
            },
          ]}
          searchFilters={['name', 'type', 'link']}
          pagination
          noDataText="No prompts available"
          expandable
        />
      </Segment>
    </div>
  )
}
