import React, { useEffect, useState } from 'react'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { updateMassPermissions } from '@/reducers/organizations/organizationUsers.actions'
import { useDispatch } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { cloudAccessSchema } from '../../organization.schema'

export const MassPermissionsButton = ({ orgUsers, organizationId, updateUsers, toggleLoading }) => {
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!showModal) {
      setSelectedPermissions([])
      setSelectedUsers([])
    }
  }, [showModal])

  const handleMassPermissionsSubmit = () => {
    dispatch(
      updateMassPermissions({
        selectedPermissions,
        selectedUsers,
        organizationId,
        updateUsers,
        toggleLoading,
      })
    )
    setShowConfirmation(false)
    setShowModal(false)
  }

  const mapOrgUsers = () =>
    orgUsers.map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user,
    }))

  const handleMultiPermissionSelect = (value) => {
    setSelectedPermissions(value)
  }

  const handleMultiUsersSelect = (value) => {
    setSelectedUsers(value)
  }

  return (
    <div style={{ margin: '.5rem' }}>
      <Button
        id="mass-permissions"
        data-testid="mass-permissions-button"
        onClick={() => setShowModal(true)}
        primary
      >
        Mass Permissions
      </Button>

      <BasicModal
        title="Apply Permissions to All Users"
        onClose={() => setShowModal(false)}
        show={showModal}
        canCloseOnDimmerClick
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setShowConfirmation(true)} primary data-testid="initial-apply">
              Apply
            </Button>
          </div>
        }
      >
        <div>
          <Form>
            <Form.Field>
              <label>Select Permissions</label>
              <MultiSelect
                condenseOptions={false}
                showFullOptions
                placeholderPill="Select Permissions..."
                options={cloudAccessSchema.options}
                aria-labelledby="mass-permissions-select"
                value={selectedPermissions}
                onChange={handleMultiPermissionSelect}
              />
            </Form.Field>
            <Form.Field>
              <label>Select Users to Exclude</label>
              <MultiSelect
                condenseOptions={false}
                showFullOptions
                placeholderPill="Select Users to Exclude..."
                options={mapOrgUsers()}
                aria-labelledby="users-select"
                value={selectedUsers}
                onChange={handleMultiUsersSelect}
              />
            </Form.Field>
          </Form>
        </div>

        {showConfirmation && (
          <BasicModal
            title="Apply Permissions to All Users"
            show={showConfirmation}
            canCloseOnDimmerClick
            onClose={() => setShowConfirmation(false)}
            footer={
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={handleMassPermissionsSubmit} primary data-testid="confirm-apply">
                  Apply
                </Button>
                <Button onClick={() => setShowConfirmation(false)} color="red">
                  Cancel
                </Button>
              </div>
            }
          >
            <p>
              This will apply the selected permissions to all included users. Click
              &apos;Apply&apos; to proceed or &apos;Cancel&apos; to discard changes.
            </p>
          </BasicModal>
        )}
      </BasicModal>
    </div>
  )
}
