export const formatTaskStatus = (taskStatus) => {
  if (taskStatus === 'ready_for_review') {
    return 'Not Reviewed'
  }
  if (taskStatus === 'completed') {
    return 'Reviewed'
  }
  if (taskStatus === 'expired') {
    return 'Expired'
  }
  if (taskStatus === 'ignored') {
    return 'Dismissed'
  }

  return 'Invalid Status'
}

export const formatTaskType = (taskType, isAiGenerated) => {
  if (taskType === 'coaching') {
    if (isAiGenerated) {
      return 'AI'
    }
    return 'Manual'
  }
  if (taskType === 'compliance') {
    if (isAiGenerated) {
      return 'AI'
    }
    return 'Manual Escalation'
  }

  if (taskType === 'qa_dispute') {
    return 'Dispute'
  }

  if (taskType === 'qa_manual_score') {
    return 'Pending Evaluation'
  }

  return 'Invalid Type'
}
