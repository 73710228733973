import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'

import { exportCsv } from '@/components/helpers/exportToCsv'
import { parseToCsv } from '@/components/helpers/parseToCsv'
import { getUpdatedPostcallTranscript } from '@/views/Calls/CallExplorer.helpers'

export const DownloadDropdown = ({
  isAudioAvailable,
  audioError,
  isVideoAvailable,
  screenCaptureError,
}) => {
  const {
    callExplorer: { postcall_transcript: postcallTranscript, callId, audioUrl, screenCaptureUrls },
  } = useSelector((state) => state.callSearch)
  const showDownloadAudioOption = isAudioAvailable && !audioError
  const showDownloadTranscriptOption = !isEmpty(postcallTranscript)
  const showDownloadScreenCaptureOption = isVideoAvailable && !screenCaptureError
  // most orgs don't have screen capture, so we don't condition the download all button on that
  const showDownloadAllOption = showDownloadAudioOption && showDownloadTranscriptOption
  const showNothing =
    !showDownloadAudioOption && !showDownloadTranscriptOption && !showDownloadScreenCaptureOption

  const handleDownloadAudio = () => {
    window.open(audioUrl)
  }

  const handleDownloadScreenCapture = () => {
    for (const url of screenCaptureUrls) {
      window.open(url)
    }
  }

  const handleDownloadTranscript = () => {
    const headers = [
      { accessor: 'side', label: 'Side' },
      { accessor: 'data', label: 'Text' },
      { accessor: 'timestamp', label: 'Timestamp' },
    ]

    const updatedTranscript = getUpdatedPostcallTranscript(postcallTranscript)
    const csvString = parseToCsv({ data: updatedTranscript, headers })
    exportCsv(csvString, null, callId, 'Balto Transcript for Call ID')
  }

  if (showNothing) {
    return null
  }

  return (
    <Dropdown
      icon="ellipsis vertical"
      button
      className="icon secondary svg-button compact"
      direction="right"
      floating
      data-testid="download-audio-transcript-dropdown"
    >
      <Dropdown.Menu>
        {showDownloadAllOption && (
          <Dropdown.Item
            onClick={() => {
              handleDownloadAudio()
              handleDownloadTranscript()
              if (showDownloadScreenCaptureOption) {
                handleDownloadScreenCapture()
              }
            }}
          >
            Download All Assets
          </Dropdown.Item>
        )}
        {showDownloadAudioOption && (
          <Dropdown.Item onClick={handleDownloadAudio}>Download Audio</Dropdown.Item>
        )}
        {audioError && <Dropdown.Item disabled>Audio File Unavailable</Dropdown.Item>}
        {showDownloadTranscriptOption && (
          <Dropdown.Item onClick={handleDownloadTranscript}>Download Transcript</Dropdown.Item>
        )}
        {showDownloadScreenCaptureOption && (
          <Dropdown.Item onClick={handleDownloadScreenCapture}>
            Download {pluralize('Screen Capture', screenCaptureUrls.length, false)}
          </Dropdown.Item>
        )}
        {screenCaptureError && <Dropdown.Item disabled>Screen Capture Unavailable</Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  )
}
