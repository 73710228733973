import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { snakeCase, isEmpty } from 'lodash'

import { fetchTrendsObjectives } from '@/reducers/userSettings/userSettings.actions'
import { Drawer } from '@/components/drawer/Drawer'

import { InsightsModule } from './components/InsightsModule'
import { InsightsDrawer } from './components/InsightsDrawer'
import { InsightsDashboardWidgets } from './components/InsightsDashboardWidgets'
import { pieData, lineData, drillDownPieData, drillDownLineData } from './exampleData'

import './Insights.scss'

export const InsightsPage = () => {
  const dispatch = useDispatch()
  const { objectives } = useSelector((state) => state.userSettings.widgets)
  const { organizationid } = useSelector((state) => state.currentUser)
  const showCustomDashboardWidgets = !isEmpty(objectives)
  const [pieDrillDownOption, setPieDrillDownOption] = useState(null)
  const [lineDrillDownOption, setLineDrillDownOption] = useState(null)
  const isBaltoAdmin = organizationid === 1

  useEffect(() => {
    dispatch(fetchTrendsObjectives())
  }, [])

  const handlePieDrillDown = (event) => {
    const subData = drillDownPieData.find((data) => {
      return data.dataGroupId === event.data.groupId
    })

    if (!subData) {
      return
    }

    const drilldownOption = {
      ...pieData,
      dataGroupId: subData.dataGroupId,
      label: subData.name,
      labelColor: subData.color,
      series: {
        ...pieData.series,
        silent: true,
        name: subData.name,
        dataGroupId: subData.dataGroupId,
        data: subData.data,
      },
    }

    setPieDrillDownOption(drilldownOption)
  }

  const handleLineDrillDown = (event) => {
    const subData = drillDownLineData.find((data) => {
      return data.dataGroupId === snakeCase(event.seriesName)
    })

    if (!subData) {
      return
    }

    const drilldownOption = {
      ...lineData,
      dataGroupId: subData.dataGroupId,
      label: subData.label,
      labelColor: subData.color,
      series: subData.data,
    }

    setLineDrillDownOption(drilldownOption)
  }

  return (
    <>
      <header className="page-header" data-testid="insights-page">
        <h1>Insights</h1>

        <div className="flex medium-gap">
          <Drawer
            title="Insights Widgets"
            trigger={(handleOpen) => (
              <Button primary onClick={handleOpen}>
                Add Insight
              </Button>
            )}
          >
            <InsightsDrawer objectives={objectives} />
          </Drawer>
        </div>
      </header>

      {showCustomDashboardWidgets && <InsightsDashboardWidgets objectives={objectives} />}

      {isBaltoAdmin && (
        <>
          <InsightsModule
            option={pieData}
            drillDownOption={pieDrillDownOption}
            handleGoBack={() => setPieDrillDownOption(null)}
            handleDrillDown={handlePieDrillDown}
            showTable
          />
          <InsightsModule
            option={lineData}
            drillDownOption={lineDrillDownOption}
            handleGoBack={() => setLineDrillDownOption(null)}
            handleDrillDown={handleLineDrillDown}
          />
        </>
      )}
    </>
  )
}

export default InsightsPage
