import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { Button, Form, Loader, Segment } from 'semantic-ui-react'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { IconFlask } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import {
  Select,
  MultiSelect,
  NumberField,
  TextArea,
  TextField,
  RadioGroup,
  Checkbox,
  Dropdown,
} from '@/components/forms/formik'
import {
  fetchAgentsByOrg,
  fetchTagsByOrg,
  fetchPlaybooksByOrg,
} from '@/reducers/qa-copilot/qa-copilot.actions'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import {
  createPromptTest,
  deletePromptTest,
  getPromptTestResults,
} from '@/reducers/prompts/prompts.actions'

export const QACopilotSettingsForm = ({
  values,
  handleDeleteScorecardConfig,
  isBaltoAdmin,
  isPromptEditor,
  isCreate,
  setFieldValue,
}) => {
  const dispatch = useDispatch()
  const {
    loading: { promptTests: promptTestsLoading },
    prompts,
    modelOptions,
  } = useSelector((state) => state.prompts)
  const organizations = useSelector((state) => state.organizations)
  const { multi_call_scoring_enabled: multiCallScoringEnabled } = useSelector(
    (state) => state.currentOrganization
  )
  const { data, loading } = useSelector((state) => state.qaCopilot)
  const {
    loading: { modelOptions: modelOptionsLoading },
  } = useSelector((state) => state.prompts)
  const [copilotTestResponse, setCopilotTestResponse] = useState(null)
  const isPromptTestingDisabled = !values.prompt_id
  const isPromptPairEmpty = isEmpty(values.call_id) || isEmpty(values.expected_response)
  const prompt = prompts.find((prompt) => values.prompt_id && prompt.id === values.prompt_id)
  const promptTests = prompt?.prompt_tests || []

  const fetchAllFilters = () => {
    dispatch(fetchAgentsByOrg(values.organization_id))
    dispatch(fetchTagsByOrg(values.organization_id))
    dispatch(fetchPlaybooksByOrg(values.organization_id))
  }

  const handleSavePromptTest = async () => {
    dispatch(
      createPromptTest(prompts, prompt, {
        inputs: { call_id: values.call_id },
        expected_response: values.expected_response,
        prompt_uuid: prompt.prompt_uuid,
      })
    )

    setFieldValue('call_id', '')
    setFieldValue('expected_response', '')
  }

  const handleDeletePromptTest = (promptTestId) => {
    dispatch(deletePromptTest(prompts, prompt, promptTestId))
  }

  const handleTestCopilotPrompt = async () => {
    const response = await dispatch(
      getPromptTestResults(values.prompt_id, {
        user_prompt: values.user_prompt,
        model: values.model,
        feature: 'qa_copilot_test',
        system_prompt: values.system_prompt,
        first_x_minutes: values.first_x_minutes || null,
        last_x_minutes: values.last_x_minutes || null,
        side: values.side,
        max_response_tokens: values.max_response_tokens,
        scores_to_fallback: values.scores_to_fallback?.map((score) => score.value),
      })
    )

    setCopilotTestResponse(response)
  }

  useEffect(() => {
    // fetch the updated data when the dropdown changes
    if (isCreate && isBaltoAdmin && values.organization_id) {
      fetchAllFilters()
    }
  }, [values.organization_id])

  useEffect(() => {
    // fetch the defaults on load
    if (values.organization_id) {
      fetchAllFilters()
    }
  }, [])
  return (
    <Form>
      {isBaltoAdmin && (
        <div data-testid="organization-dropdown">
          <Field
            required
            label="Organization"
            name="organization_id"
            component={Select}
            options={organizations.map((org) => ({ value: org.id, label: org.name }))}
            isClearable={false}
            disabled={!isCreate}
          />
          {!isCreate && (
            <p className="muted-text">
              Clone scorecard to change the organization on an existing scorecard.
            </p>
          )}
        </div>
      )}
      <h3>Scoring Threshold</h3>
      <p className="muted-text">
        Set performance bands for which calls are exceeding expectations, meeting expectations, or
        needing improvements.
      </p>
      <div className="mulitple-field-container horizontal">
        <Field required name="exceeds_threshold" label="Exceeds (%)" component={NumberField} />
        <Field required name="meets_threshold" label="Meets (%)" component={NumberField} />
        <Field required name="improvement_threshold" label="Below (%)" component={NumberField} />
      </div>
      {multiCallScoringEnabled && (
        <>
          <h3>Multi Call Scoring</h3>
          <Field data-testid="multicall-toggle" name="multicall" toggle component={Checkbox} />
        </>
      )}
      {isPromptEditor && (
        <>
          <h3 data-testid="eligibility-prompt-header">Eligibility Prompt</h3>
          <p className="muted-text">
            The following reasoning will be used to determine if a call is eligible for QA scoring.
          </p>
          <Field name="user_prompt" component={TextArea} rows={10} />
          <div className="copilot-prompt__testing-headline">
            <IconFlask className="icon-svg" />
            <h3>Testing</h3>
          </div>
          <Segment>
            <div className="copilot-prompt__additional-details">
              <div>
                <div>Call IDs</div>
                <div className="flex-align-center small-gap">
                  <Field
                    name="call_id"
                    component={TextField}
                    placeholder="Enter a CallID"
                    className="call-id-field"
                  />
                  <div>.....</div>
                  <Field
                    name="expected_response"
                    component={TextField}
                    placeholder="Select an expected response"
                  />
                  <ConditionalTooltip
                    condition={isPromptPairEmpty || isPromptTestingDisabled}
                    content={
                      isPromptPairEmpty
                        ? 'You must add a call id and an expected response'
                        : 'You must save the scorecard with an eligibility prompt before setting up prompt testing'
                    }
                    triggerProps={{ style: { display: 'inline-flex' } }}
                  >
                    <Button
                      type="button"
                      color="black"
                      className="no-wrap"
                      onClick={handleSavePromptTest}
                      disabled={isPromptTestingDisabled || isPromptPairEmpty}
                    >
                      Add Pair
                    </Button>
                  </ConditionalTooltip>
                </div>
              </div>
              <div>
                {promptTests.map((promptTest) => (
                  <div key={promptTest.id} className="prompt-test flex-align-center small-gap">
                    <Field
                      name={`call_id_${promptTest.inputs.call_id}`}
                      component={TextField}
                      placeholder={promptTest.inputs.call_id}
                      disabled
                    />
                    <div>.....</div>
                    <Field
                      name={`expected_response_${promptTest.id}`}
                      component={TextField}
                      disabled
                      placeholder={promptTest.expected_response}
                    />

                    <Button
                      type="button"
                      negative
                      color="red"
                      onClick={() => handleDeletePromptTest(promptTest.id)}
                      style={{ marginTop: '0.5rem' }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>

              <ConditionalTooltip
                condition={isEmpty(promptTests)}
                content="Create a test first"
                triggerProps={{ style: { display: 'inline-flex' } }}
              >
                <Button
                  secondary
                  onClick={handleTestCopilotPrompt}
                  style={{ marginTop: '0.5rem' }}
                  disabled={isEmpty(promptTests)}
                  type="button"
                >
                  Run Test
                </Button>
              </ConditionalTooltip>
              <div>
                {promptTestsLoading ? (
                  <div className="empty-copilot-response">
                    <Loader active inline />
                  </div>
                ) : (
                  copilotTestResponse && (
                    <div className="copilot-response-holder">
                      {copilotTestResponse.map((response) => (
                        <div className="copilot-response" key={response.prompt_test_id}>
                          <p>
                            <strong>Inputs: </strong> <span>{JSON.stringify(response.inputs)}</span>
                          </p>
                          <p>
                            <strong>Expected Response: </strong>{' '}
                            <span>{response.expected_response}</span>
                          </p>
                          <p>
                            <strong>Actual Response: </strong>{' '}
                            <span>{response.actual_response}</span>
                          </p>
                          <p>
                            <strong>Tokens Used: </strong> <span>{response.tokens_used}</span>
                          </p>
                          {response.fallback_used !== null && (
                            <p>
                              <strong>Fallback Used: </strong>{' '}
                              <span>{response.fallback_used ? 'True' : 'False'}</span>
                            </p>
                          )}
                          <p>
                            <strong>Model Used: </strong> <span>{response.executed_model}</span>
                          </p>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </Segment>
          <div className="copilot-prompt__testing-headline">
            <IconFlask className="icon-svg" />
            <h3>Eligibility Prompt Settings</h3>
          </div>
          <Segment>
            <div className="copilot-prompt__additional-details">
              <div className="flex-align-center small-gap additional-details-holder">
                <div className="label-style">Expected Response</div>
                <Field
                  name="should_eligibility_respond_yes"
                  component={RadioGroup}
                  options={[
                    { value: 'true', text: 'Yes' },
                    { value: 'false', text: 'No' },
                  ]}
                />
              </div>
            </div>
            <div className="copilot-prompt__additional-details">
              <div className="flex-align-center small-gap additional-details-holder">
                <div className="label-style">
                  Scores to run fallback model (will run 4o-mini first)
                </div>
                <Field
                  name="scores_to_fallback"
                  component={MultiSelect}
                  condenseOptions={false}
                  showFullOptions
                  placeholder="Any of Yes/No/NA"
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                    { value: 'n/a', label: 'N/A' },
                  ]}
                />
              </div>
            </div>
            <div className="copilot-prompt__additional-details">
              <div className="flex-align-center small-gap additional-details-holder">
                <div className="label-style">Use Specific Timeframes</div>
                <div className="flex-align-center small-gap">
                  <Field
                    placeholder="Minutes at the start"
                    name="first_x_minutes"
                    component={NumberField}
                  />
                  <Field
                    placeholder="Minutes at the end"
                    name="last_x_minutes"
                    component={NumberField}
                  />
                </div>
              </div>
            </div>
            <div className="copilot-prompt__additional-details">
              <div className="flex-align-center small-gap additional-details-holder">
                <div className="label-style">Side to include in transcript</div>
                <Field
                  name="side"
                  component={Select}
                  options={[
                    { value: 'both', label: 'Both' },
                    { value: 'agent', label: 'Agent Only' },
                    { value: 'customer', label: 'Customer Only' },
                  ]}
                />
              </div>
            </div>
            <div className="copilot-prompt__additional-details">
              <div className="flex-align-center small-gap additional-details-holder">
                <div className="label-style">AI Model</div>
                <Field
                  name="model"
                  component={Select}
                  options={modelOptions}
                  loading={modelOptionsLoading}
                />
              </div>
            </div>
          </Segment>

          <h3>Shell Prompt</h3>
          <p className="muted-text">
            This prompt will be placed at the beginning of all criteria prompts and should be used
            to give context around the organization and why/how the prompts are being used. Can be
            overwritten by criteria level shell prompts.
          </p>
          <Field name="shell_prompt" component={TextArea} rows={10} />
        </>
      )}

      <h3>Scoring Goal {!isBaltoAdmin && ' (Read Only)'}</h3>
      <p className="muted-text">
        Determine the number of calls and the frequency of evaluations needed to fulfill your
        scoring objectives. What is the target quantity of calls you aim to score?
      </p>

      <div className="mulitple-field-container horizontal">
        <Field name="required_score_count" component={NumberField} disabled={!isBaltoAdmin} />
        <span>calls per month</span>
      </div>

      <h2>Call Eligibility Settings</h2>
      <h3>Qualifying Calls</h3>
      <p className="muted-text">
        Calls that meet the criteria specified in the query below will qualify for scoring. Note
        that a single call may be assessed by several scorecards concurrently.
      </p>
      <div className="mulitple-field-container">
        <div className="non-repeater-grid">
          <div className="label-style">Call Length</div>
          <div className="muted-text">is at least</div>
          <div className="flex-align-center gap" style={{ flex: 1 }}>
            <Field
              name="min_duration_in_minutes"
              component={NumberField}
              placeholder="Set minimum call duration in minutes"
            />
            <div className="muted-text">{pluralize('minute', values.min_duration_in_minutes)}</div>
          </div>
        </div>
        <Pill small emphasized>
          AND
        </Pill>
        <div className="non-repeater-grid">
          <div className="label-style">Agents</div>
          <Field
            name="users_inclusive"
            options={[
              { value: 'true', text: 'Includes' },
              { value: 'false', text: 'Excludes' },
            ]}
            component={Dropdown}
          />
          <Field
            name="users"
            options={data.agents}
            component={MultiSelect}
            loading={loading.agents}
            condenseOptions={false}
            showFullOptions
            placeholder="Select agents (optional)"
            style={{ flex: 1 }}
          />
        </div>
        <Pill small emphasized>
          AND
        </Pill>
        <div className="non-repeater-grid">
          <div className="label-style">Tags</div>
          <Field
            name="tags_inclusive"
            options={[
              { value: 'true', text: 'Includes' },
              { value: 'false', text: 'Excludes' },
            ]}
            component={Dropdown}
          />
          <Field
            name="tags"
            options={data.tags}
            component={MultiSelect}
            loading={loading.tags}
            condenseOptions={false}
            showFullOptions
            placeholder="Select tags (optional)"
            style={{ flex: 1 }}
          />
        </div>
        <Pill small emphasized>
          AND
        </Pill>
        <div className="non-repeater-grid">
          <div className="label-style">Playbook</div>
          <div className="muted-text">matches</div>
          <Field
            name="playbooks"
            options={data.playbooks}
            component={MultiSelect}
            loading={loading.playbooks}
            condenseOptions={false}
            showFullOptions
            placeholder="Select Playbooks (optional)"
            style={{ flex: 1 }}
          />
        </div>
      </div>
      <h2>Danger Zone</h2>
      <div className="flex-align-center flex-space-between gap">
        <div>
          <p className="label-style">Delete this Scorecard</p>
          <p className="muted-text">
            Please be aware this action is irreversible. Removing the scorecard will also
            permanently erase all associated evaluations.
          </p>
        </div>
        <Button type="button" color="red" onClick={handleDeleteScorecardConfig} className="no-wrap">
          Delete Scorecard
        </Button>
      </div>
    </Form>
  )
}
