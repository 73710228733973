import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { PlaybookForm } from './components/PlaybookForm'
import RowEditorForm from './components/RowEditorForm'
import { TestPromptForm } from './components/TestPromptForm'
import { buildPrompt } from './helpers'

export const PromptForm = ({ handleSubmit, values, setFieldValue, arrayHelpers }) => {
  const { rtnPrompt } = useSelector((state) => state.playbook)
  useEffect(() => {
    if (!isEmpty(values.prompt_fields)) {
      setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
    }
  }, [values.prompt_fields.length])

  let errorPrompts = []

  if (values.prompt_fields) {
    errorPrompts = values.prompt_fields.filter((field) => field.value.length > 1099)
  }

  return (
    <Form className="rtn-edit-form" onSubmit={handleSubmit}>
      <PlaybookForm setFieldValue={setFieldValue} values={values} />
      {rtnPrompt?.user_prompt && (
        <>
          <RowEditorForm
            setFieldValue={setFieldValue}
            values={values}
            arrayHelpers={arrayHelpers}
          />
          <TestPromptForm values={values} setFieldValue={setFieldValue} />
        </>
      )}

      <div className="flex-end small-gap save-button-container">
        <Button
          type="submit"
          primary
          data-testid="save-prompt-btn"
          disabled={!isEmpty(errorPrompts)}
        >
          Save New Prompt
        </Button>
      </div>
    </Form>
  )
}
