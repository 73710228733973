import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Loader } from 'semantic-ui-react'

import { fetchInitialFilters, fetchOrganizationData } from '@/reducers/tasks/tasks.actions'
import { INBOX_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { createSavedFilter } from '@/reducers/savedFilters/savedFilters.actions'
import { setFiltersLoaded } from '@/reducers/tasks/tasks.redux'

import { TaskOnboardingModal } from './modals/TaskOnboardingModal'
import { Inbox } from './Inbox'

export const InboxRedirect = ({ inboxType, title }) => {
  const dispatch = useDispatch()
  const [isOnboarding, setIsOnboarding] = useState(false)
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const { filtersLoaded } = useSelector((state) => state.tasks)
  const { savedFilterList } = useSelector((state) => state.savedFilters)

  const handleSubmitOnboarding = async (selectedAgents = []) => {
    dispatch(setFiltersLoaded(false))

    const newFilter = {
      name: 'My Agents',
      isDefault: true,
      agents: selectedAgents,
      tags: [],
    }

    await dispatch(createSavedFilter(newFilter, INBOX_FILTER_TYPE))
    await dispatch(fetchInitialFilters(INBOX_FILTER_TYPE))
  }

  useEffect(() => {
    if (!filtersLoaded) {
      dispatch(fetchInitialFilters(INBOX_FILTER_TYPE))
      dispatch(fetchOrganizationData(currentUserOrgId))
    }
  }, [])

  useEffect(() => {
    const shouldShowOnboardingModal = filtersLoaded && isEmpty(savedFilterList[INBOX_FILTER_TYPE])

    setIsOnboarding(shouldShowOnboardingModal)
  }, [filtersLoaded, savedFilterList])

  const determineRedirect = () => {
    if (!filtersLoaded) {
      return (
        <div className="empty">
          <Loader inline active />
        </div>
      )
    }

    if (isOnboarding) {
      return (
        <TaskOnboardingModal
          inboxType={inboxType}
          handleSubmitOnboarding={handleSubmitOnboarding}
        />
      )
    }

    return <Inbox inboxType={inboxType} title={title} />
  }

  return <div>{determineRedirect()}</div>
}
