import React, { createContext, useRef, useContext } from 'react'

const AudioPlayerContext = createContext()

export const AudioPlayerProvider = ({ children }) => {
  const audioRef = useRef(null)

  const changeAudioPlaybackRate = (speed) => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed
    }
  }

  const jumpAudioTo = (time) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time
    }
  }

  const playAudio = () => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play()
    }
  }

  const pauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
    }
  }

  const changeAudioVolume = (volume) => {
    if (audioRef.current) {
      audioRef.current.volume = volume
    }
  }

  const addAudioEvent = (eventType, callback) => {
    if (audioRef.current) {
      audioRef.current.addEventListener(eventType, callback)
    }
  }

  const removeAudioEvent = (eventType, callback) => {
    if (audioRef.current) {
      audioRef.current.addEventListener(eventType, callback)
    }
  }

  return (
    <AudioPlayerContext.Provider
      value={{
        audioRef,
        playAudio,
        pauseAudio,
        jumpAudioTo,
        changeAudioPlaybackRate,
        changeAudioVolume,
        addAudioEvent,
        removeAudioEvent,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  )
}

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext)
}
