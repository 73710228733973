const rtcHTTPProtocol = process.env.REALTIME_COACHING_HTTP_PROTOCOL || 'http://'
const rtcWSProtocol = process.env.REALTIME_COACHING_WS_PROTOCOL || 'ws://'
const rtcDomain = process.env.REALTIME_COACHING_DOMAIN || 'localhost:8101'
const RTC_SERVICE_URL = `${rtcHTTPProtocol}${rtcDomain}`
const RTC_WEBSOCKET_URL = `${rtcWSProtocol}${rtcDomain}`

/**
 * apiService is an object where each property contains the full domain for outbound services.
 */

export const apiService = {
  scorecard: process.env.SCORECARD_SERVICE_URL,
  leaderboard: process.env.LEADERBOARD_SERVICE_URL,
  web: process.env.WEB_SERVICE_URL,
  remoteHealth: process.env.REMOTE_HEALTH_URL,
  insights: process.env.INSIGHTS_SERVICE_URL,
  reporting: process.env.REPORTING_SERVICE_URL,
  rtc: RTC_SERVICE_URL,
  rtc_websocket: RTC_WEBSOCKET_URL,
  playbookWarning: process.env.PLAYBOOK_WARNING_SERVICE_URL,
  sayableContent: process.env.SAYABLE_CONTENT_SERVICE_URL,
  summary: process.env.CALL_SUMMARY_SERVICE_URL,
  slpInternal: process.env.SLP_INTERNAL_SERVICE_URL,
  workflow: process.env.WORKFLOW_SERVICE_URL,
}
