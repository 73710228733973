import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'
import { formatDuration } from '@/utils/helpers'
import {
  HARD_SELECTED_EVENT_ID,
  removeCallExplorerParams,
  SECTION_NAMES,
  updateCallExplorerParams,
} from './helpers'

const DECK_SECTION = 'deck'

export const DeckItemEventBlock = ({
  dataTestId,
  timestamp,
  item,
  showTimestamp,
  isHardSelectedEvent,
  isSoftSelectedEvent,
  onClick,
}) => {
  return (
    <div
      id={isHardSelectedEvent ? HARD_SELECTED_EVENT_ID : ''}
      className={classNames('deck-item-event-block', {
        'hard-selected': isHardSelectedEvent,
        'soft-selected': isSoftSelectedEvent,
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div
        className={classNames('deck-item-start-time', { 'hide-time': !showTimestamp })}
        data-testid="deck-item-timestamp"
      >
        {formatDuration(timestamp)}
      </div>
      <div className="deck-item-event-content-wrapper">
        <div
          className={classNames('deck-item-event-content', {
            selected: isHardSelectedEvent || isSoftSelectedEvent,
          })}
        >
          {item}
        </div>
      </div>
    </div>
  )
}

export const EventBlock = ({ event, highlighted }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const {
    callExplorer: { hardSelectedEvent, softSelectedEvents },
  } = useSelector((state) => state.callSearch)
  const { id, timestamp, section, category, item, subEvents, question, answer } = event

  // Determine if this is a deck item
  // and we need to display deck list item events
  const isDeckSection = section === DECK_SECTION
  const hasSubEvents = isDeckSection && subEvents?.length > 0

  const isHardSelectedEvent = hardSelectedEvent?.id === id && id
  const isSoftSelectedEvent = (softSelectedEvents && softSelectedEvents.includes(id)) || highlighted

  const updateURL = (eventId, timestamp) => {
    updateCallExplorerParams(location, history, { eventId, timestamp })
  }

  const removeEventFromURL = () => {
    removeCallExplorerParams(location, history, ['eventId'])
  }

  const onMainEventClick = () => {
    if (isHardSelectedEvent) {
      removeEventFromURL()
    } else {
      updateURL(id, timestamp)
    }
    dispatch(
      setHardSelectedEvent({
        id,
        timestamp,
        displayText: item,
        section,
      })
    )
  }

  const onSubEventClick = (subEventId, subEventTimestamp, subEventItem) => {
    if (hardSelectedEvent?.id === subEventId) {
      removeEventFromURL()
    } else {
      updateURL(subEventId, subEventTimestamp)
    }
    dispatch(
      setHardSelectedEvent({
        id: subEventId,
        timestamp: subEventTimestamp,
        displayText: subEventItem,
        section,
      })
    )
  }

  return (
    <div className="events-block">
      <div
        id={isHardSelectedEvent ? HARD_SELECTED_EVENT_ID : ''}
        className={classNames('events-main-container', {
          'soft-selected': isSoftSelectedEvent,
          'hard-selected': isHardSelectedEvent,
        })}
        onClick={onMainEventClick}
        data-testid="main-event-container"
      >
        <div className="event-start-time">{formatDuration(timestamp)}</div>
        <div className="event-detail-container">
          <div className="event-section-category">
            <span
              className={classNames(
                'section-category-label',
                `event-section-${section || 'QACopilot'}`
              )}
            >
              {SECTION_NAMES[section] || 'QA Copilot'}
            </span>{' '}
            {Boolean(category || event.scorecard_name) && (
              <span className="section-category-label event-category">
                {category || event.scorecard_name}
              </span>
            )}
          </div>
          <span className="event-item">{item || event.criteria_name}</span>
          {Boolean(question) && <span className="event-item">{question}</span>}
          {Boolean(answer) && <span className="event-item">{answer}</span>}
        </div>
      </div>
      {hasSubEvents && (
        <div className="deck-list-items-container" data-testid="deck-list-items-container">
          {subEvents.map((subevent) => {
            const {
              id: subEventId,
              timestamp: subEventTimestamp,
              item: subEventItem,
              isNoResponse,
            } = subevent
            const isHardSelectedSubEvent = hardSelectedEvent?.id === subEventId
            const isSoftSelectedSubEvent =
              softSelectedEvents && softSelectedEvents.includes(subEventId)

            return (
              <DeckItemEventBlock
                key={subEventId}
                dataTestId={`decklist-event-${subEventId}`}
                timestamp={subEventTimestamp}
                item={subEventItem}
                showTimestamp={!isNoResponse}
                onClick={() => onSubEventClick(subEventId, subEventTimestamp, subEventItem)}
                isHardSelectedEvent={isHardSelectedSubEvent}
                isSoftSelectedEvent={isSoftSelectedSubEvent}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
