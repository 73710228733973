import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { fetchScorecardsByOrg } from '@/reducers/scorecards/scorecards.actions'
import { Button } from 'semantic-ui-react'
import { scoreCopilotScorecard } from '@/reducers/scorecards/scores.actions'

export const ScoreForScorecard = ({
  callId,
  handleDeselectAll,
  organizationId,
  showScoreScorecard = true,
  compact = false,
  buttonProps = {},
  ...props
}) => {
  const [newScoreScorecardModalOpen, setScoreScorecardModalOpen] = useState(false)
  const [selectedScorecardUuid, setSelectedScorecardUuid] = useState(null)
  const { data, loading } = useSelector((state) => state.scorecards)
  const dispatch = useDispatch()

  const scorecardOptions = (data.scorecardConfigs || [])
    .filter((scorecard) => scorecard?.active === true && scorecard?.deleted === false)
    .map((scorecard) => ({
      key: scorecard?.uuid,
      label: scorecard?.name,
      value: scorecard?.uuid,
    }))

  const selectedScorecard = data.scorecardConfigs.find(
    (scorecard) => scorecard.uuid === selectedScorecardUuid
  )

  const handleCloseScoreScorecardModal = async () => {
    setScoreScorecardModalOpen(false)
    setSelectedScorecardUuid(null)
  }

  const handleOpenScoreScorecardModal = async (scorecardUuid) => {
    setSelectedScorecardUuid(scorecardUuid)
    setScoreScorecardModalOpen(true)
  }

  const handleSubmitScorecard = async () => {
    dispatch(scoreCopilotScorecard(organizationId, [callId], selectedScorecard.id))
    await handleCloseScoreScorecardModal()
  }

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchScorecardsByOrg(organizationId, 'copilot'))
    }
  }, [])

  return (
    <>
      <div className="flex-align-center medium-gap">
        {showScoreScorecard && (
          <SearchableDropdown
            buttonProps={{
              content: 'Score Call',
              className: classNames('secondary'),
              ...buttonProps,
            }}
            compact={compact}
            inputProps={{ placeholder: 'Search...' }}
            options={scorecardOptions}
            loading={loading.scorecard}
            menuPosition="right"
            handleSelect={handleOpenScoreScorecardModal}
            dataTestId="playlist"
            {...props}
          />
        )}
      </div>

      {newScoreScorecardModalOpen && (
        <BasicModal
          data-testid="score-scorecard-modal"
          title={`Rescore Call Using: "${selectedScorecard?.name}" Scorecard`}
          onClose={handleCloseScoreScorecardModal}
          show={newScoreScorecardModalOpen}
          size="tiny"
        >
          <div>
            <p>
              Resubmitting this call bypasses eligibility settings and audio quality checks, which
              may lead to inaccurate scores. Existing scores will be replaced, and this action
              counts toward your Copilot quota.
            </p>
            <p>Are you sure you want to continue?</p>
            <div className="modal-footer">
              <Button
                data-testid="score-scorecard-cancel-button"
                icon
                disabled={loading.scorecardConfigs}
                loading={loading.scorecardConfigs}
                secondary
                className="svg-button"
                compact={compact}
                onClick={handleCloseScoreScorecardModal}
                {...props}
              >
                No, Cancel
              </Button>
              <Button
                data-testid="score-scorecard-button"
                icon
                disabled={loading.scorecardConfigs}
                loading={loading.scorecardConfigs}
                primary
                className="svg-button"
                compact={compact}
                onClick={handleSubmitScorecard}
                {...props}
              >
                Yes, Proceed
              </Button>
            </div>
          </div>
        </BasicModal>
      )}
    </>
  )
}
