import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Form } from 'semantic-ui-react'
import { UNCATEGORIZED_TAGS } from '../../utils/constants'
import Tag from './Tag'

const TagColumn = ({
  column,
  index,
  tags,
  edit_users,
  deleteCategory,
  updateCategory,
  deleteTag,
  setHoveredTagId,
  hoveredTagId,
}) => {
  const [editing, setEditing] = useState(false)
  const [title, setTitle] = useState(column.title)

  const freezeColumn = useMemo(
    () => column.title === UNCATEGORIZED_TAGS || !edit_users,
    [column.title, edit_users]
  )

  const windowSize = column.title === UNCATEGORIZED_TAGS ? '' : '300px'

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const EditAndDeleteIcons = () => (
    <div
      style={{
        display: 'inline-flex',
        float: 'right',
      }}
    >
      <i
        onClick={() => setEditing((prev) => !prev)}
        data-testid="edit_category_toggle"
        className="icon pencil black tag-edit-icon"
        role="presentation"
        key={`${column.id}-edit`}
      />
      <i
        onClick={() => deleteCategory(column)}
        data-testid="delete_category"
        className="icon trash black tag-edit-icon"
        role="presentation"
        key={`${column.id}-delete`}
      />
    </div>
  )

  return (
    <Draggable draggableId={column.id.toString()} index={index} isDragDisabled={freezeColumn}>
      {(provided) => (
        <div
          className="column-container"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div style={{ minWidth: windowSize, maxWidth: windowSize }}>
            <div className="border-bottom">
              {editing ? (
                <>
                  <div className="column-form-box">
                    <Form
                      className="flex-space-between small-gap"
                      onSubmit={() => {
                        updateCategory(column, title)
                        setEditing(false)
                      }}
                    >
                      <Form.Input
                        data-testid="edit_category_text_box"
                        placeholder={title}
                        value={title}
                        onChange={handleTitleChange}
                      />
                      <div>
                        <Button secondary data-testid="edit_category_button">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                  {edit_users && <EditAndDeleteIcons />}
                </>
              ) : (
                <h3 className="column-title" {...provided.dragHandleProps}>
                  {column.title}
                </h3>
              )}
              {column.title !== UNCATEGORIZED_TAGS && !editing && edit_users && (
                <EditAndDeleteIcons />
              )}
            </div>
            <Droppable droppableId={column.id.toString()} direction="horizontal" type="task">
              {(provided, snapshot) => (
                <div
                  className={classNames('column-tag-list', {
                    'dragging-over': snapshot.isDraggingOver,
                  })}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tags
                    .filter((tag) => !!tag?.id)
                    .map((tag, index) => (
                      <Tag
                        key={tag.id}
                        tag={tag}
                        index={index}
                        deleteTag={deleteTag}
                        setHoveredTagId={setHoveredTagId}
                        hoveredTagId={hoveredTagId}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default TagColumn
