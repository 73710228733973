import React, { useEffect, useState, useCallback } from 'react'
import { Form, Button, Loader } from 'semantic-ui-react'

// This form breaks the mold because we don't want a plaintext
// passphrase floating around in redux
const PasswordForm = ({ user, updateUser, modalClose, generatePassphrase, reportPassphrase }) => {
  const [passphrase, setPassphrase] = useState('')
  const [loading, setLoading] = useState(true)

  const updatePassphrase = useCallback((newPassphrase) => {
    setPassphrase(newPassphrase)
    setLoading(false)
  }, [])

  const deletePassphrase = (e) => {
    user.password = null
    updateUser(user)
    modalClose(e)
  }

  const handleGeneratePassphrase = () => {
    setLoading(true)
    generatePassphrase(updatePassphrase)
  }

  const submitPassphrase = () => {
    user.password = passphrase
    updateUser(user)
    modalClose()
  }

  const submitBadPassphrase = () => {
    reportPassphrase(passphrase)
  }

  useEffect(() => {
    generatePassphrase(updatePassphrase)
  }, [generatePassphrase, updatePassphrase])

  return (
    <Form>
      <div className="ui center aligned basic segment">
        {loading ? (
          <div className="ui center aligned basic segment">
            <Loader active size="large" inline="centered" />
          </div>
        ) : (
          <div className="ui basic segment">
            <h2 data-testid="passphrase">{passphrase}</h2>
          </div>
        )}
        <br />
        <Form.Field className="ui clearing">
          <Button.Group className="gap">
            <Button
              data-testid="save-new-password-button"
              onClick={submitPassphrase}
              primary
              content="Save New Password"
              icon="save"
              labelPosition="left"
            />
            {user.password && (
              <Button
                onClick={deletePassphrase}
                content="Remove BaltoCloud Access"
                icon="trash"
                size="small"
                color="red"
                labelPosition="right"
              />
            )}
          </Button.Group>
          <br />
          <br />
          <a
            onClick={handleGeneratePassphrase}
            role="presentation"
            data-testid="generate-passphrase-link"
          >
            Don&apos;t like this phrase? Generate a new one.
          </a>
          <br />
          <br />
          <a onClick={submitBadPassphrase}>This password is horrifying - I want to report it!</a>
        </Form.Field>
      </div>
    </Form>
  )
}

export default PasswordForm
