import React from 'react'
import { Button } from 'semantic-ui-react'

export const TaskDrawerFooter = ({
  taskIsOpen,
  handleClose,
  primaryActionButtonText,
  primaryButtonDisabled,
  handlePrimaryAction,
  secondaryActionButtonText,
  handleSecondaryAction,
}) => {
  return (
    <div className="drawer-footer">
      <div className="flex-align-center flex-space-between medium-gap">
        <div>
          {taskIsOpen && (
            <Button basic onClick={handleClose} className="no-wrap">
              Save for Later
            </Button>
          )}
        </div>
        <div className="flex-align-center medium-gap">
          {handleSecondaryAction && (
            <Button
              className="no-wrap"
              type="button"
              secondary
              onClick={handleSecondaryAction}
              data-testid="drawer-secondary-action"
            >
              {secondaryActionButtonText}
            </Button>
          )}
          {handlePrimaryAction && (
            <Button
              className="no-wrap"
              type="button"
              primary
              onClick={handlePrimaryAction}
              data-testid="drawer-primary-action"
              disabled={primaryButtonDisabled}
            >
              {primaryActionButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
