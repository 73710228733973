import React from 'react'
import { Popup, Button } from 'semantic-ui-react'
import { IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react'
import classNames from 'classnames'

import './PlayPauseButton.scss'

export const PlayPauseButton = ({ isDisabled, togglePlayState, isPlaying, autoFocus }) => {
  return (
    <Popup
      inverted
      content={isDisabled ? 'Cannot Play Audio' : isPlaying ? 'Pause' : 'Play'}
      trigger={
        <div>
          <Button
            autoFocus={autoFocus}
            primary
            circular
            type="button"
            onClick={togglePlayState}
            data-testid="play-pause-button"
            aria-label={isPlaying ? 'Pause' : 'Play'}
            className={classNames('play-pause-button', { disabled: isDisabled })}
            disabled={isDisabled}
          >
            {isPlaying ? <IconPlayerPause /> : <IconPlayerPlay />}
          </Button>
        </div>
      }
    />
  )
}
