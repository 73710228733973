import React, { useEffect, useContext } from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Segment } from 'semantic-ui-react'
import WidgetContext from '@/components/widget/widget.context'
import moment from 'moment'

export const FeedbackTable = ({ columns, rows, loading }) => {
  const { handleChangeCsvData } = useContext(WidgetContext)

  useEffect(() => {
    handleChangeCsvData({
      headers: columns,
      data: rows,
      filters: {
        startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: moment.utc().format('YYYY-MM-DD'),
      },
      fileName: 'Feature Feedback',
    })
  }, [columns, rows])

  return (
    <Segment className="not-padded">
      <AdvancedTable borderless loading={loading} columns={columns} rows={rows} />
    </Segment>
  )
}
