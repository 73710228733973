import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconChevronDown, IconWorld } from '@tabler/icons-react'
import { trim, startCase } from 'lodash'
import { toast } from 'react-toastify'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import {
  createPlaybook,
  fetchPlaybooks,
  updatePlaybookLanguage,
  updatePlaybookOrganization,
} from '@/reducers/playbooks/playbooks.actions'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { formatTime, getOrganizationOptions } from '@/utils/helpers'
import { truncatedColumn } from '@/components/helpers/tableColumnHelpers'
import { TableActionsDropdown } from '@/components/tables/TableActionsDropdown'
import { fetchingAPI, apiService } from '@/api'

import { PlaybookDeleteForm } from './components/PlaybookDeleteForm'

const PlaybooksPageComponent = ({ flags }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationid: organizationId, edit_config: canEditPlaybooks } = useSelector(
    (state) => state.currentUser
  )
  const organizations = useSelector((state) => state.organizations)
  const { playbooks, loading } = useSelector((state) => state.playbooks)
  const insightEvents = useSelector((state) => state.insightEvents)
  const isBaltoAdmin = organizationId === 1

  const getPlaybookDeleteOptions = (cid) => {
    return playbooks
      .filter((playbook) => playbook.cid !== cid)
      .filter((playbook) => {
        if (!isBaltoAdmin) {
          return playbook.organization_id === organizationId
        }
        return true
      })
      .map((playbook) => ({ value: playbook.cid, label: playbook.name }))
  }

  const handleExportPlaybook = async (playbook) => {
    try {
      const response = await fetchingAPI(
        `${apiService.web}/api/configs/${playbook.id}`,
        'GET',
        dispatch
      )

      const prettyJson = JSON.stringify(response, null, 2)

      const blob = new Blob([prettyJson], { type: 'application/json' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `${playbook.name}.json`
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (err) {
      toast.error('Failed to export playbook')
      console.error(err)
    }
  }

  const handleCreatePlaybook = (formValues) => {
    const { name, config_cid, organization_id } = formValues
    const playbook = { name: trim(name), organization_id }

    dispatch(createPlaybook(playbook, config_cid, insightEvents))
    dispatch(closeModal())
  }

  const handleUpdateConfigLanguage = (language, cid) => {
    dispatch(updatePlaybookLanguage(language, cid))
  }

  const handleUpdatePlaybookOrganization = (formValues, cid) => {
    const { organization_id: organizationId } = formValues

    dispatch(updatePlaybookOrganization(organizationId, cid))
    dispatch(closeModal())
  }

  const redirectToEdit = (e, row) => {
    history.push(`/playbooks/${row.id}/checklist`)
  }

  const columns = [
    {
      accessor: 'name',
      label: 'Name',
      isSearchable: true,
      clickableRowLink: {
        prefix: 'playbooks',
        accessor: 'id',
        suffix: '/checklist',
      },
      format: (playbook) => truncatedColumn(playbook),
    },
    {
      accessor: 'created_on',
      label: 'Last Updated',
      format: (value) => formatTime(value),
    },
  ]

  if (flags.allSpanishPlaybookToggle2022 || flags.frenchEnabled) {
    const languageColumn = {
      accessor: 'language',
      label: 'Language',
      notClickable: true,
      format: (value, row) => {
        const languageOptions = [
          {
            label: 'English',
            onClick: () => handleUpdateConfigLanguage('english', row.cid),
          },
        ]

        if (flags.allSpanishPlaybookToggle2022) {
          languageOptions.push({
            label: 'Spanish',
            onClick: () => handleUpdateConfigLanguage('spanish', row.cid),
          })
        }

        if (flags.frenchEnabled) {
          languageOptions.push({
            label: 'French',
            onClick: () => handleUpdateConfigLanguage('french', row.cid),
          })
        }

        return (
          <div className="flex-align-center">
            <TableActionsDropdown
              triggerLabel={startCase(value)}
              buttonProps={{ compact: true, basic: false, secondary: true }}
              actions={languageOptions}
              icon={<IconChevronDown />}
            />
          </div>
        )
      },
    }
    columns.splice(1, 0, languageColumn)
  }

  if (isBaltoAdmin) {
    columns.push({
      accessor: 'organization_name',
      label: 'Organization',
      isSearchable: true,
    })
  }

  const actions = [
    {
      trigger: (row) => {
        const handleOpenUpdatePlaybookOrgModal = (row) => {
          dispatch(openModal(`playbooks/update-playbook-organization-${row.id}`))
        }

        const handleOpenDeletePlaybookModal = (row) => {
          dispatch(openModal(`playbooks/${row.cid}/deletePlaybook`))
        }

        const handleOpenExportPlaybookModal = (row) => {
          handleExportPlaybook(row)
        }

        const playbookActions = [
          {
            label: `${canEditPlaybooks ? 'Edit' : 'View'} Playbook`,
            onClick: () => redirectToEdit(null, row),
            testId: `${canEditPlaybooks ? 'edit' : 'view'}-playbook-action`,
          },
        ]

        if (isBaltoAdmin) {
          playbookActions.push({
            label: 'Update Playbook Organization',
            onClick: () => handleOpenUpdatePlaybookOrgModal(row),
            testId: 'update-org-playbook-action',
          })
        }

        if (isBaltoAdmin || canEditPlaybooks) {
          playbookActions.push({
            label: 'Export Playbook',
            onClick: () => handleOpenExportPlaybookModal(row),
            testId: 'export-playbook-action',
          })
          playbookActions.push({
            label: 'Delete Playbook',
            onClick: () => handleOpenDeletePlaybookModal(row),
            testId: 'delete-playbook-action',
            isDanger: true,
          })
        }

        return <TableActionsDropdown actions={playbookActions} />
      },
    },
  ]

  if (isBaltoAdmin) {
    actions.push({
      label: 'Update Playbook Organization',
      trigger: (row) => (
        <ButtonAndFormModal
          popup
          icon={<IconWorld />}
          modalTitle="Update Playbook Organization"
          modalId={`playbooks/update-playbook-organization-${row.id}`}
          modalProps={{ size: 'tiny' }}
          buttonProps={{ basic: true, compact: true }}
          showButton={false}
          form={
            <AbstractForm
              existingValues={{ organization_id: row.organization_id }}
              schema={[
                {
                  name: 'organization_id',
                  type: 'select',
                  label: 'Organization',
                  options: getOrganizationOptions(organizations),
                  required: true,
                },
              ]}
              onSubmit={(formValues) => handleUpdatePlaybookOrganization(formValues, row.cid)}
              buttonLabel="Save"
              onClose={() => dispatch(closeModal())}
              isModal
            />
          }
        />
      ),
    })
  }

  if (isBaltoAdmin || canEditPlaybooks) {
    actions.push({
      trigger: (row) => (
        <PlaybookDeleteForm
          playbookCid={row.cid}
          playbookOptions={getPlaybookDeleteOptions(row.cid)}
          showButton={false}
        />
      ),
    })
  }

  const rows = playbooks.map((playbook) => ({
    ...playbook,
    created_on: new Date(playbook.config_created).toISOString(),
  }))

  useEffect(() => {
    dispatch(fetchPlaybooks())
  }, [])

  return (
    <>
      <header className="page-header" data-testid="playbooks-page">
        <h1>Playbooks</h1>

        {(isBaltoAdmin || canEditPlaybooks) && (
          <ButtonAndFormModal
            buttonLabel="Create Playbook"
            modalTitle="Create Playbook"
            modalId="playbooks/create"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ primary: true }}
            form={
              <AbstractForm
                schema={[
                  { name: 'name', type: 'text', label: 'Name', required: true },
                  {
                    name: 'config_cid',
                    type: 'select',
                    label: 'Starting Playbook (optional)',
                    options: playbooks.map((val) => ({ value: val.cid, label: val.name })),
                  },
                ]}
                onSubmit={handleCreatePlaybook}
                buttonLabel="Create"
                onClose={() => dispatch(closeModal())}
                isModal
              />
            }
          />
        )}
      </header>

      <Segment className="not-padded">
        <AdvancedTable
          defaultOrderBy="name"
          loading={loading}
          columns={columns}
          rows={rows}
          actions={actions}
          rowAction={redirectToEdit}
          stickyAction
          pagination
        />
      </Segment>
    </>
  )
}

const PlaybooksPage = withLDConsumer()(PlaybooksPageComponent)

export default PlaybooksPage
