import produce from 'immer'

import * as FILTER_TYPES from './savedFilters.constants'

// Action Types
export const SET_FILTER_LIST = 'savedFilters/setFilterList'
export const SET_LOADING = 'savedFilters/setLoading'
export const SET_DATA = 'savedFilters/setData'

// Action Creators
export const setFilterList = (payload) => ({ type: SET_FILTER_LIST, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })

export const filterDataset = {
  agents: [],
  tags: [],
  playbooks: [],
  dispositions: [],
  deckCategories: [],
  postcallCategories: [],
  scorecardConfigs: [],
}

const filterTypes = Object.keys(FILTER_TYPES).reduce((filterList, key) => {
  filterList[FILTER_TYPES[key]] = []

  return filterList
}, {})

const filterLoadingTypes = Object.keys(FILTER_TYPES).reduce((loadingList, key) => {
  loadingList[FILTER_TYPES[key]] = false

  return loadingList
}, {})

// State
export const initialState = {
  savedFilterList: filterTypes,
  loading: { ...filterLoadingTypes, ...filterDataset },
  data: { ...filterDataset },
}

// Reducer
export default function savedFiltersRedux(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_FILTER_LIST:
        newState.savedFilterList = { ...state.savedFilterList, ...payload }
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_DATA:
        newState.data = { ...state.data, ...payload }
        break
      default:
        break
    }
  })
}
