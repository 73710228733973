import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { IconCircleCheckFilled, IconCircleDashedCheck } from '@tabler/icons-react'
import { Button } from 'semantic-ui-react'

import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { fetchPlaylistsByCallId, patchPlaylistCall } from '@/reducers/playlists/playlists.actions'

export const MarkAsReviewed = ({ buttonProps = {}, compact, ...props }) => {
  const dispatch = useDispatch()
  const { id: callId } = useParams()
  const { search } = useLocation()
  const { playlistUuid } = queryString.parse(search)
  const { playlistsByCallId, loading } = useSelector((state) => state.playlists)

  const playlistOptions = playlistsByCallId.map((playlist) => ({
    key: playlist.uuid,
    label: playlist.name,
    value: playlist.uuid,
    selected: playlist.is_flagged,
  }))

  const toggleReview = async (playlistUuid, isFlagged) => {
    const selectedPlaylist = playlistsByCallId.find((playlist) => playlist.uuid === playlistUuid)

    await dispatch(
      patchPlaylistCall(playlistUuid, selectedPlaylist.organization_id, callId, {
        is_flagged: !isFlagged,
      })
    )
    dispatch(fetchPlaylistsByCallId(callId))
  }

  const handleToggleReviewed = async (playlistUuid) => {
    const row = playlistOptions.find((playlist) => playlist.key === playlistUuid)

    await toggleReview(playlistUuid, row.selected)
  }

  const renderButton = () => {
    const selectedPlaylist = playlistOptions.find((playlist) => playlist.key === playlistUuid)

    return (
      <Button
        compact={compact}
        icon
        secondary
        data-testid="mark-as-reviewed-button"
        className="svg-button"
        type="button"
        onClick={async () => {
          await toggleReview(playlistUuid, selectedPlaylist.selected)
        }}
        {...buttonProps}
      >
        {selectedPlaylist?.selected ? (
          <IconCircleCheckFilled data-testid="reviewed-icon" className="status-success" />
        ) : (
          <IconCircleDashedCheck data-testid="not-reviewed-icon" className="status-muted" />
        )}
        {selectedPlaylist?.selected ? 'Reviewed' : 'Review'}
      </Button>
    )
  }

  const isDisabled = isEmpty(playlistOptions)

  useEffect(() => {
    dispatch(fetchPlaylistsByCallId(callId))
  }, [callId])

  return playlistUuid ? (
    renderButton()
  ) : (
    <ConditionalTooltip
      tooltipProps={{ position: 'bottom left' }}
      condition={isDisabled}
      content="Add call to a playlist to mark as reviewed"
    >
      <SearchableDropdown
        data-testid="mark-as-reviewed-dropdown"
        buttonProps={{
          content: 'Review',
          className: 'secondary',
          disabled: isDisabled,
          ...buttonProps,
        }}
        compact={compact}
        additionalIcon={<IconCircleDashedCheck className="status-muted" />}
        inputProps={{ placeholder: 'Search Playlists...' }}
        options={playlistOptions}
        loading={loading.playlistByCallId}
        menuPosition="right"
        handleSelect={handleToggleReviewed}
        {...props}
      />
    </ConditionalTooltip>
  )
}
