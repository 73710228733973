import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Table } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { fetchCallSummaryByCallId } from '@/reducers/callSearch/callSearch.actions'
import { ExternalLink } from '@/components/ExternalLink'
import { CopyButton } from '@/components/buttons/CopyButton'
import { formatDuration } from '@/utils/helpers'

export const TechnicalDetails = ({ metadata = {}, callId }) => {
  const dispatch = useDispatch()
  const { qa_enabled, qa_copilot_enabled } = useSelector((state) => state.currentOrganization)
  const {
    organization_name: organizationName,
    voip_call_id: voipCallID,
    agent_name: agentName,
    call_start_time: callStartTime,
    call_end_time: callEndTime,
    config_name: configName,
    call_duration: callDuration = 0,
    is_win: isWin,
    username,
    call_uuid: callUUID,
    voip_customer_id: voipCustomerId,
    voip_campaign_name: voipCampaignName,
    disposition,
    qa_eligibility,
    config_id: configId,
  } = metadata

  const dateTimeFormat = 'MMMM d, yyyy h:mm aa'

  const formattedMetadata = [
    { label: 'Organization', value: organizationName },
    { label: 'VOIP ID', value: voipCallID, isCopiable: true },
    { label: 'Agent', value: agentName },
    { label: 'Call Duration', value: formatDuration(callDuration) },
    { label: 'Start Time', value: callStartTime ? format(callStartTime, dateTimeFormat) : null },
    { label: 'End Time', value: callEndTime ? format(callEndTime, dateTimeFormat) : null },
    {
      label: 'Playbook',
      value: configName,
      component: (
        <ExternalLink
          id="playbook-link"
          to={`/playbooks/${configId}/checklist`}
          label={configName}
        />
      ),
    },
    { label: 'Username', value: username, isCopiable: true },
    {
      label: 'Win',
      value: !!isWin,
      component: (
        <Pill small success={isWin} critical={!isWin}>
          {isWin ? 'Yes' : 'No'}
        </Pill>
      ),
    },
    { label: 'Call UUID', value: callUUID, isCopiable: true },
    { label: 'VOIP Customer ID', value: voipCustomerId, isCopiable: true },
    { label: 'VOIP Campaign Name', value: voipCampaignName, isCopiable: true },
    { label: 'Disposition', value: disposition },
  ]

  if (qa_enabled || qa_copilot_enabled) {
    const displayValue = !isEmpty(qa_eligibility)
      ? qa_eligibility
          .map((item) => `${item.config_name}: ${item.reason.replaceAll(/_/g, ' ')}`)
          .join('\n')
      : 'Eligible'

    formattedMetadata.push({
      label: 'QA Eligibility',
      value: displayValue,
    })
  }

  useEffect(() => {
    dispatch(fetchCallSummaryByCallId(callId))
  }, [])

  return (
    <div className="call-metadata-container">
      <h2>Technical Details</h2>

      <Table compact basic celled className="call-metadata-grid">
        <Table.Body>
          {formattedMetadata.map((item) => {
            return (
              <Table.Row key={item.label} className="call-metadata-grid-row">
                <Table.Cell>
                  <strong>{item.label}</strong>
                </Table.Cell>
                <Table.Cell>
                  <div className="call-metadata-value-container">
                    <span className="muted-text pre-wrap" data-testid={item.label}>
                      {item.component || item.value || '--'}
                    </span>
                    {item.isCopiable && item.value && (
                      <CopyButton content={item.value} showPopup showLabel={false} iconOnly />
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}
