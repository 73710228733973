import React from 'react'
import { IconArrowUpRight, IconSparkles } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'

export const JumpToMomentPill = ({ onClick, formattedTime, dataTestId }) => {
  return (
    <Pill
      className="clickable"
      dataTestId={`jump-to-call-event-pill-${dataTestId}`}
      onClick={onClick}
      magical
      small
      icon={<IconSparkles />}
    >
      Jump to moment:
      <div className="event-time">{formattedTime}</div>
      <IconArrowUpRight />
    </Pill>
  )
}
